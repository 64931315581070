import React, { useRef, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import lectureActions from 'redux/lecture/lectureActions'
import FontAwesome from 'components/FontAwesome'
import { Link } from 'react-router-dom'
import Player from 'components/lecture/Player'
import { memoize } from 'lodash'
import Button from 'components/elements/Button'
import Comments from 'components/lecture/CommentContainer'
import Loading from 'components/Loading'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import LectureBody from 'components/lecture/LectureBody'
import LectureNavigation from 'components/lecture/LectureNavigation'

const styles = {
  container: {
    background: theme.colors.backgroundGray,
  },
  grayRow: {
    background: theme.colors.backgroundGray,
    width: '100vw',
    padding: '0 20px',
  },
  header: {
    maxWidth: theme.sizes.containerWidth,
    margin: '0 auto',
    padding: '20px 0',
  },
  headerTitle: {
    ...theme.type.notoSansJp,
    color: theme.colors.nuBlu,
    fontWeight: 'bold',
    fontSize: 24,
    [theme.mobileOnly()]: {
      fontWeight: '500',
      fontSize: 15,
    },
  },
  backToChaper: {
    maxWidth: 1200,
    margin: '0 auto',
    fontSize: 17,
    display: 'flex',
    color: theme.colors.textDarkGray,
    '& a': {
      color: theme.colors.textDarkGray,
      '&:hover': {
        color: theme.colors.nuBlu,
      },
    },
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  breadCrumb: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  experiencingIssuesLink: {
    cursor: 'pointer',
    color: '#cc9f39', // todo this is temporary remove this
  },
  playerContainer: {
    padding: '80px 0 46px 0', // the other bottom 54px comes from the watched lecture feature
    background: theme.colors.backgroundGray,
    [theme.mobileOnly()]: {
      padding: 0,
    },
  },
  locked: {
    maxWidth: theme.sizes.containerWidth,
    minHeight: '40vh',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px auto',
    padding: 20,
  },
  lock: {
    fontSize: 50,
    marginBottom: 10,
  },
  subscribeButton: {
    marginTop: 10,
  },
  navigation: {
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    padding: '10px 0',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.textGray,
    },
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  navNext: {
    marginLeft: 'auto',
  },
  mobileNavigation: {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 100,
    [theme.mobileOnly()]: {
      display: 'flex',
    },
  },
  mobileNavIcon: {
    padding: '10px 20px',
    color: theme.colors.borderGray,
  },
  mobileNavIconPlaceholder: {
    width: 52,
    height: 40,
  },
  mobileNavPlaylist: {
    ...theme.components.title,
    textAlign: 'center',
  },
  mobileNavLecture: {
    textAlign: 'center',
  },
  sectionTitle: {
    ...theme.components.title,
    marginBottom: 20,
    fontSize: 15,
  },
  desktopNav: {
    display: 'none',
    padding: '0 5px',
    [theme.desktopOnly()]: {
      display: 'block',
    },
  },
  bodyContainer: {
    display: 'flex',
    maxWidth: 1500,
    margin: '0 auto',
    background: '#fff',
  },
  mainBody: {
    width: 'calc(100% - 400px)',
    borderRight: `1px solid ${theme.colors.divider}`,
    [theme.max(1150)]: {
      width: '100%',
    },
  },
  navigationBody: {
    width: 400,
    [theme.max(1150)]: {
      display: 'none',
    },
  },
  divider: {
    background: theme.colors.divider,
    width: '100%',
    height: 1,
  },
  hideMobile: {
    ...theme.components.hideMobile,
  },
  watchedContainer: {
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0',
    height: 54,
    [theme.mobileOnly()]: {
      padding: '10px 10px 0 0',
      background: '#fff',
    },
  },
  watched: {
    ...theme.resetButton,
    cursor: 'pointer',
    border: '1px solid #ccc',
    padding: '5px 10px',
    width: 158,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      color: 'green',
      marginRight: 5,
    },
  },
}

const Lecture = props => {
  const {
    match: {
      params: {
        playlist: urlPlaylist,
        slug,
      },
    },
    classes: css,
  } = props

  const dispatch = useDispatch()

  const lecture = useSelector(state => state.lecture)
  const user = useSelector(state => state.user)
  const membership = useSelector(state => state.membership)

  const [isWatched, setIsWatched] = useState(null)
  const loginCheckerDelay = useRef(null)
  const seekFn = useRef(null)

  // player component passes in a function to let other components seek to video time
  const setSeekFn = fn => {
    seekFn.current = fn
  }
  const seekToTime = time => {
    seekFn.current(time)
  }

  useEffect(() => {
    dispatch(lectureActions.loadBySlug(slug, urlPlaylist || null))
  }, [slug, user.id])


  useEffect(() => {
    if (!lecture.loading && user.id > 0) {
      axios.get(`/lectures/watched/${lecture.id}`)
        .then(res => {
          setIsWatched(res.data)
        })
    }
    setIsWatched(null)
  }, [lecture.loading, user.id, lecture.id])

  const isLocked = !membership.plan && lecture.player === 'vimeo'

  const clearLoginCheckDelay = () => {
    if (loginCheckerDelay.current) {
      clearTimeout(loginCheckerDelay.current)
      loginCheckerDelay.current = null
    }
  }

  const checkLogin = () => {
    clearLoginCheckDelay()
    axios.get('/users/check-login')
      .then(() => {
        clearLoginCheckDelay()
        loginCheckerDelay.current = setTimeout(checkLogin, 1000 * 30)
      })
      .catch(err => {
        clearLoginCheckDelay()
        if (err && err.response && err.response.status === 401) {
          // dispatch(modalsActions.isOpen('duplicateLogin'))
          // dispatch(userActions.logout())
        } else {
          // loginCheckerDelay.current = setTimeout(checkLogin, 1000 * 30)
        }
      })
  }

  useEffect(() => {
    if (lecture.vimeoId && user.id > 0) {
      // checkLogin()
    } else {
      clearLoginCheckDelay()
    }
    return () => {
      clearLoginCheckDelay()
    }
  }, [lecture.vimeoId, user.id])

  const getNavigation = memoize((currentLecture, direction) => {
    const lectureIndex = currentLecture.playlist
      .findIndex(playlistLecture => playlistLecture.slug === currentLecture.slug)

    return currentLecture.playlist[lectureIndex + direction] || null
  }, (...args) => JSON.stringify(args))

  const onMarkWatched = (newIsWatched = null) => {
    if (
      // user must be logged in
      user.id === 0
      // the player marks watched lectures as watched. No action required in that case.
      || (newIsWatched !== null && isWatched === newIsWatched)
    ) return
    axios.post(`/lectures/watched/${lecture.id}`, {
      isWatched: newIsWatched !== null ? newIsWatched : !isWatched,
    }).catch(console.error)
    setIsWatched(!isWatched)
  }

  const goToNextLecture = () => {
    const nextLecture = getNavigation(lecture, 1)
    const playlistSlug = lecture.categories[lecture.categories.length - 1].slug
    window.location = `/lecture/${playlistSlug}/${nextLecture.slug}`
  }

  const renderBreadCrumb = () => {
    const subjectSlug = lecture.categories[0].slug
    const chapterSlug = lecture.categories[1].slug

    return lecture.categories.map((category, index) => (
      <div
        className={css.breadCrumb}
        key={`${subjectSlug}${chapterSlug}${category.name}`}
      >
        <Link to={`/subject${lecture.level === 'college' ? '' : `/${lecture.level}`}/${subjectSlug}${index > 0 ? `/${chapterSlug}` : ''}`}>
          Back to&nbsp;
          {category.name}
        </Link>
      </div>
    ))
  }

  const renderMobileNavigation = () => {
    const prevLecture = getNavigation(lecture, -1)
    const nextLecture = getNavigation(lecture, 1)
    const playlistSlug = lecture.categories[lecture.categories.length - 1].slug

    return (
      <div className={css.mobileNavigation}>
        {
          prevLecture
            ? (
              <Link to={`/lecture/${playlistSlug}/${prevLecture.slug}`}>
                <FontAwesome
                  className={css.mobileNavIcon}
                  name="faChevronLeft"
                />
              </Link>
            )
            : <div className={css.mobileNavIconPlaceholder} />
        }
        <div className={css.mobileNavBody}>
          <div className={css.mobileNavPlaylist}>
            {lecture.categories[2].name}
          </div>
          <div className={css.mobileNavLecture}>
            { lecture.name }
          </div>
        </div>
        {
          nextLecture
            ? (
              <Link to={`/lecture/${playlistSlug}/${nextLecture.slug}`}>
                <FontAwesome
                  className={css.mobileNavIcon}
                  name="faChevronRight"
                />
              </Link>
            )
            : <div className={css.mobileNavIconPlaceholder} />
        }
      </div>
    )
  }

  const renderNavigation = currentLecture => {
    const prevLecture = getNavigation(currentLecture, -1)
    const nextLecture = getNavigation(currentLecture, 1)
    const playlistSlug = currentLecture.categories[currentLecture.categories.length - 1].slug

    return (
      <div className={css.navigation}>
        {
          prevLecture
            ? (
              <Link
                to={`/lecture/${playlistSlug}/${prevLecture.slug}`}
              >
                <FontAwesome
                  name="faChevronLeft"
                  className={css.breadCrumbArrow}
                />
                <div className={css.desktopNav}>
                  {prevLecture.name}
                </div>
              </Link>
            )
            : (
              <div>
                Beginning of playlist
              </div>
            )
        }
        {
          nextLecture
            ? (
              <Link
                to={`/lecture/${playlistSlug}/${nextLecture.slug}`}
                className={css.navNext}
              >
                <div className={css.desktopNav}>
                  {nextLecture.name}
                </div>
                <FontAwesome
                  name="faChevronRight"
                  className={css.breadCrumbArrow}
                />
              </Link>
            )
            : (
              <div className={css.navNext}>
                End of Playlist
              </div>
            )
        }
      </div>
    )
  }

  const renderLocked = () => (
    <div className={css.locked}>
      <FontAwesome
        name="faLock"
        className={css.lock}
      />
      <div className={css.lockText}>
        Subscribe to access our premium lectures.
      </div>
      <Button
        label="Subscribe"
        type="primary"
        link="/pricing"
        className={css.subscribeButton}
      />
    </div>
  )

  const renderWatched = () => {
    if (isWatched === null) return null
    return (
      <button
        type="button"
        className={css.watched}
        onClick={() => onMarkWatched()}
      >
        {
          isWatched && (<FontAwesome name="faCheckCircle" />)
        }
        {isWatched ? 'Watched' : 'Mark as Watched'}
      </button>
    )
  }

  if (lecture.loading) { return <Loading fill /> }

  return (
    <div className={css.container}>
      <Helmet>
        <title>
          {`AK Lectures - ${lecture.name}`}
        </title>
        <meta
          name="description"
          content={lecture.description ? lecture.description.substring(0, 160).split(' ').slice(0, -1).join(' ') : ''}
        />
      </Helmet>
      <PageHeader
        subject={lecture.categories[0].name}
        subjectLink={`/subject${lecture.level === 'college' ? '' : `/${lecture.level}`}/${lecture.categories[0].slug}/${lecture.categories[1].slug}`}
        label={`${lecture.categories[2].name} playlist:`}
        name={lecture.name}
        handleNextClick={goToNextLecture}
        showNextButton={!!getNavigation(lecture, 1)}
        buttonText="NEXT LECTURE"
      />
      {renderMobileNavigation()}
      <div className={css.playerContainer}>
        <div className={css.backToChaper}>
          {renderBreadCrumb()[1]}
        </div>
        {renderNavigation(lecture)}
        {
          !isLocked
            ? (
              <Player
                lecture={lecture}
                onMarkWatched={onMarkWatched}
                setSeekFn={setSeekFn}
              />
            )
            : renderLocked()
        }
        {renderNavigation(lecture)}
        <div className={css.watchedContainer}>
          {renderWatched()}
        </div>
      </div>
      <div className={css.bodyContainer}>
        <div className={css.mainBody}>
          <LectureBody
            description={lecture.description}
            references={lecture.references}
            corrections={lecture.corrections}
            lectureTitle={lecture.name}
            lecture={lecture}
            isSubscribed={!isLocked}
            seekToTime={seekToTime}
          />
          <div className={css.divider} />
          <Comments lecture={lecture} />
        </div>
        <div className={css.navigationBody}>
          <LectureNavigation
            playlist={lecture.playlist}
            nextPlaylist={lecture.nextPlaylist}
            currentPlaylistSlug={lecture.categories[lecture.categories.length - 1].slug}
            currentLectureSlug={lecture.slug}
          />
        </div>
      </div>
    </div>
  )
}

Lecture.propTypes = {
  match: propTypes.shape({
    params: propTypes.object.isRequired,
  }).isRequired,
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(Lecture)
