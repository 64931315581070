import React, { useState } from 'react'
import Modal from 'components/admin/Modal'
import { createUseStyles } from 'react-jss'
import Button from 'components/elements/Button'
import modalsActions from 'redux/modals/modalsActions'
import categoryActions from 'redux/category/categoryActions'
import categoriesActions from 'redux/categories/categoriesActions'
import lectureActions from 'redux/lecture/lectureActions'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'components/Loading'
import Lectures from './categoryModal/Lectures'
import CategoryForm from './categoryModal/CategoryForm'
import LectureTabs from './categoryModal/LectureTabs'

const useStyles = createUseStyles({
  body: {
    display: 'flex',
  },
  rightSection: {
    width: 'calc(100% - 440px)',
  },
})

const ModalCategory = () => {
  const css = useStyles()

  const lecture = useSelector(state => state.lecture)
  const category = useSelector(state => state.category)
  const modals = useSelector(state => state.modals)

  const dispatch = useDispatch()

  const [categoryEdit, setCategoryEdit] = useState({})
  const [lectureEdit, setLectureEdit] = useState({})

  const handleCategoryFieldChange = (field, value) => {
    setCategoryEdit(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleLectureFieldChange = (field, value) => {
    setLectureEdit(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleLectureSelect = lectureId => {
    setLectureEdit({})
    dispatch(lectureActions.load(lectureId))
    dispatch(modalsActions.isOpen('adminLecture'))
  }

  const handleSave = async () => {
    if (modals.adminCategory && Object.keys(categoryEdit.length > 0)) {
      await dispatch(categoryActions.save({
        parents: category.parents,
        ...categoryEdit,
        id: category.id,
      }))
      dispatch(modalsActions.close())
      if (category.parents) {
        dispatch(categoriesActions.load())
      }
    } else if (modals.adminLecture && Object.keys(lectureEdit.length > 0)) {
      await dispatch(lectureActions.save({
        Categories: lecture.Categories,
        ...lectureEdit,
        id: lecture.id,
      }))
      dispatch(modalsActions.isOpen('adminCategory'))
      if (category.parents) {
        dispatch(categoryActions.load(category.id))
      }
      setLectureEdit({})
    }
  }

  const handleUpdateLectureOrder = newOrder => {
    dispatch(categoryActions.updateLectureOrder(category.id, newOrder.map(lecture => lecture.id)))
  }

  const renderCategoryForm = () => (
    <CategoryForm
      category={category}
      categoryEdit={categoryEdit}
      handleValueChange={handleCategoryFieldChange}
    />
  )

  const renderLectureForm = () => (
    <LectureTabs
      lecture={lecture}
      lectureEdit={lectureEdit}
      handleValueChange={handleLectureFieldChange}
    />
  )

  const renderBody = () => (
    <div className={css.body}>
      {
        category.lectures
        && (
          <Lectures
            handleSelect={handleLectureSelect}
            lectures={category.lectures}
            selectedLectureId={lecture.id}
            handleUpdateLectureOrder={handleUpdateLectureOrder}
          />
        )
      }
      <div className={css.rightSection}>
        {
          modals.adminLecture ? renderLectureForm() : renderCategoryForm()
        }
      </div>
    </div>
  )

  const renderButtons = () => (
    <Button
      label="save"
      type="primary"
      onClick={handleSave}
    />
  )

  return (
    <Modal
      isOpen
      title={category.name || (category.id === 0 ? 'New Category' : 'Loading')}
      buttons={renderButtons()}
      modalClass={css.modal}
    >
      {category.loading ? <Loading fill /> : renderBody()}
    </Modal>
  )
}

export default ModalCategory
