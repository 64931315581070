import React, { PureComponent } from 'react'
import LoginModal from 'components/modals/Login'
import RegisterModal from 'components/modals/Register'
import SearchModal from 'components/modals/Search'
import SubjectsModal from 'components/modals/Subjects'
import ForgotPasswordModal from 'components/modals/ForgotPassword'
import CancelMembershipModal from 'components/modals/CancelMembership'
import DuplicateLoginModal from 'components/modals/DuplicateLogin'
import CorrectionsModal from 'components/modals/Corrections'
import BackupLecturesModal from 'components/modals/BackupLectures'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import modalsActions from 'redux/modals/modalsActions'
import { memoize } from 'lodash'

class Modals extends PureComponent {
  static propTypes = {
    modalsActions: propTypes.shape({
      isOpen: propTypes.func.isRequired,
    }).isRequired,
    modals: propTypes.shape({
      login: propTypes.bool.isRequired,
    }).isRequired,
  }

  handleClose = memoize(modal => () => {
    const { modalsActions: { isOpen } } = this.props
    isOpen([modal], false)
  })

  render() {
    const {
      modals: {
        login,
        register,
        search,
        subjectsMedical,
        subjectsScience,
        forgotPassword,
        cancelMembership,
        duplicateLogin,
        buffaloRegister,
        corrections,
        backupLectures,
      },
    } = this.props

    return (
      <>
        { login && <LoginModal handleClose={this.handleClose('login')} /> }
        { register && <RegisterModal handleClose={this.handleClose('register')} /> }
        { search && <SearchModal handleClose={this.handleClose('search')} /> }
        {
          buffaloRegister
            && (
              <RegisterModal
                handleClose={this.handleClose('buffaloRegister')}
                type="buffalo"
              />
            )
        }
        {
          subjectsMedical
            && (
              <SubjectsModal
                handleClose={this.handleClose('subjectsMedical')}
                type="medical"
              />
            )
        }
        {
          subjectsScience
            && (
              <SubjectsModal
                handleClose={this.handleClose('subjectsScience')}
                type="science"
              />
            )
        }
        { forgotPassword && <ForgotPasswordModal handleClose={this.handleClose('forgotPassword')} /> }
        { cancelMembership && <CancelMembershipModal handleClose={this.handleClose('cancelMembership')} /> }
        { duplicateLogin && <DuplicateLoginModal handleClose={this.handleClose('duplicateLogin')} /> }
        { corrections && <CorrectionsModal handleClose={this.handleClose('corrections')} /> }
        { backupLectures && <BackupLecturesModal handleClose={this.handleClose('backupLectures')} /> }
      </>
    )
  }
}

const mapStateToProps = ({
  modals,
}) => ({
  modals,
})

const mapDispatchToProps = dispatch => ({
  modalsActions: bindActionCreators(modalsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Modals)
