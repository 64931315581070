import React from 'react'
import { createUseStyles } from 'react-jss'
import cssVars from 'css/variables'
import PropTypes from 'prop-types'
import Input from 'components/elements/Input'
import { useDispatch, useSelector } from 'react-redux'
import Row from 'components/Row'
import categoryActions from 'redux/category/categoryActions'
import FontAwesome from 'components/FontAwesome'
import CategoryPicker from '../CategoryPicker'

const useStyles = createUseStyles({
  input: {
    marginRight: cssVars.padding / 2,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  categoryRow: {
    width: '100%',
    background: cssVars.altColor1,
    borderBottom: cssVars.border,
    padding: cssVars.padding / 2,
    paddingLeft: cssVars.padding / 4,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    '& .categoryArrow': {
      margin: '0 5px',
      color: cssVars.darkGray,
    },
  },
  delete: {
    color: cssVars.red,
    fontSize: 12,
    cursor: 'pointer',
  },
})

const CategoryForm = props => {
  const {
    category,
    categoryEdit,
    handleValueChange,
  } = props

  const css = useStyles()

  const categories = useSelector(state => state.categories)
  const dispatch = useDispatch()

  const handleGoToParent = parentId => {
    dispatch(categoryActions.load(parentId))
  }

  const getParentChain = parentId => {
    let parentCategory = categories.categories[parentId]
    const parentCategories = [parentCategory]

    while (parentCategory.parents.length > 0) {
      if (!categories.categories[parentCategory.parents[0]]) {
        break
      }
      parentCategory = categories.categories[parentCategory.parents[0]]
      parentCategories.push(parentCategory)
    }
    return parentCategories
  }

  const deleteCategory = () => {
    if (window.confirm('Delete this category?')) {
      dispatch(categoryActions.remove(category.id))
    }
  }

  const handleCategorySelect = categoryId => {
    handleValueChange('parents', [Number(categoryId)])
  }

  const renderParents = parentChain => {
    if (parentChain.length < 2) {
      return null
    }
    return (
      <div className={css.categoryRow}>
        {
          parentChain
            .reduce((all, parentCategory) => {
              all.push(
                <div
                  key={parentCategory.id}
                  onClick={() => handleGoToParent(parentCategory.id)}
                >
                  {parentCategory.name}
                </div>,
              )
              all.push(
                <FontAwesome
                  key={`${parentCategory.id}-arrow`}
                  className="categoryArrow"
                  name="faChevronRight"
                />,
              )
              return all
            }, [])
            .slice(0, -3)
            .reverse()
        }
      </div>
    )
  }

  // const renderChildren = () => categories.categories[category.id].children.map(parentId => (
  //   <div
  //     key={parentId}
  //     className={css.categoryRow}
  //     onClick={handleGoToParent(parentId)}
  //   >
  //     {categories.categories[parentId].name}
  //   </div>
  // ))

  const parentChain = getParentChain(category.parents[0])
  const hasParents = parentChain.length > 1

  const parentCategoryId = categoryEdit.parents
    ? categoryEdit.parents[0]
    : category.parents
      ? category.parents[0]
      : 1

  return (
    <>
      <Row>
        <Input
          name="name"
          className={css.input}
          value={categoryEdit.name || category.name}
          onChange={handleValueChange}
          fullWidth
        />
        <Input
          name="slug"
          className={css.input}
          value={categoryEdit.slug || category.slug}
          onChange={handleValueChange}
          fullWidth
        />
      </Row>
      <Row>
        <Input
          name="description"
          className={css.input}
          value={categoryEdit.description || category.description}
          onChange={handleValueChange}
          fullWidth
          multiline
          rows={null}
        />
      </Row>
      {
        hasParents
        && (
          <Row multiline>
            Parent category:
            {renderParents(parentChain)}
          </Row>
        )
      }
      <Row>
        <CategoryPicker
          selectedCategoryId={parentCategoryId}
          handleCategorySelect={handleCategorySelect}
        />
      </Row>
      <Row>
        <div
          className={css.delete}
          onClick={deleteCategory}
        >
          Delete
        </div>
      </Row>
    </>
  )
}

CategoryForm.propTypes = {
  category: PropTypes.object.isRequired,
  categoryEdit: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
}

export default CategoryForm
