import React, { useState, useRef, useEffect } from 'react'
import propTypes from 'prop-types'
import theme from 'css/front'
import FontAwesome from 'components/FontAwesome'
import YouTube from 'react-youtube'
import Vimeo from '@u-wave/react-vimeo'
import { createUseStyles } from 'react-jss'
import lectureTracker from 'helpers/lectureTracker'
import { useSelector } from 'react-redux'
import { throttle } from 'lodash'

const useStyles = createUseStyles({
  playerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '& iframe': {
      width: '100vw',
      maxWidth: 1200,
      height: '100vh',
      maxHeight: 675,
      [theme.max(650)]: {
        height: '40vh',
      },
    },
  },
  player: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'center',
  },
  videoCover: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 50,
  },
  videoCoverPlayButton: {
    width: 100,
    height: 100,
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0 0 20px #ccc',
    color: theme.colors.nuBlu,
    transition: '.2s color',
    paddingLeft: 8,
    '&:hover': {
      color: theme.colors.babyBlu,
    },
    '&:active': {
      color: theme.colors.nuBlu,
    },
  },
})

const youtubeEvents = {
  1: 'playing',
  2: 'paused',
  3: 'buffering',
  0: 'ended',
}

const MARK_WATCHED_AFTER_PERCENT = 0.4

const Player = props => {
  const {
    lecture,
    onMarkWatched,
    setSeekFn,
  } = props

  const css = useStyles()
  const user = useSelector(state => state.user)

  const youtubeInterval = useRef(null)
  const secondsWatched = useRef({})
  const youtubePlayer = useRef(null)
  const vimeoPlayer = useRef(null)
  const markWatchedOnLength = useRef(null)

  const [videoCover, setVideoCover] = useState(false)
  const seekToTime = time => {
    if (lecture.player === 'vimeo') {
      vimeoPlayer.current.setCurrentTime(time)
    } else { // youtube
      youtubePlayer.current.seekTo(Number(time))
    }
  }

  useEffect(() => {
    if (user.id > 0) {
      clearInterval(youtubeInterval.current)
      secondsWatched.current = {}
    } else {
      clearInterval(youtubeInterval.current)
    }
    markWatchedOnLength.current = lecture.length && lecture.length > 0
      ? lecture.length * MARK_WATCHED_AFTER_PERCENT
      : 60

    setSeekFn(seekToTime)
    return () => {
      clearInterval(youtubeInterval.current)
      secondsWatched.current = {}
    }
  }, [lecture.id, user.id])

  const handleYoutubeStateChange = event => {
    if (youtubeEvents[event.data] && user.id > 0) {
      lectureTracker.event(lecture.id, Math.round(event.target.getCurrentTime()), youtubeEvents[event.data])
    }
  }

  const handleVimeoStateChange = action => event => {
    if (!event || user.id === 0) return
    lectureTracker.event(lecture.id, Math.round(event.seconds), action)
  }

  const handleHideCover = () => {
    setVideoCover(false)
  }

  const checkIfWatched = useRef(throttle(() => {
    if (Object.keys(secondsWatched.current).length >= markWatchedOnLength.current) {
      onMarkWatched(true)
      clearInterval(youtubeInterval.current)
    }
  }, 1000))

  const setYoutubeRef = event => {
    youtubePlayer.current = event.target
    youtubeInterval.current = setInterval(() => {
      secondsWatched.current[Math.floor(event.target.getCurrentTime())] = true
      checkIfWatched.current()
    }, 500)
  }

  const setVimeoRef = playerRef => {
    vimeoPlayer.current = playerRef
  }

  const onVimeoProgressUpdate = event => {
    secondsWatched.current[Math.floor(event.seconds)] = true
    checkIfWatched.current()
  }

  const renderExternalPlayer = () => {
    if (lecture.player === 'vimeo') {
      return (
        <Vimeo
          video={lecture.vimeoId}
          onReady={setVimeoRef}
          onPlay={handleVimeoStateChange('playing')}
          onPause={handleVimeoStateChange('paused')}
          onSeeked={handleVimeoStateChange('seeked')}
          onEnd={handleVimeoStateChange('ended')}
          onTimeUpdate={onVimeoProgressUpdate}
        />
      )
    }
    return (
      <YouTube
        videoId={lecture.youtubeId}
        onReady={setYoutubeRef}
        className={css.player}
        onStateChange={handleYoutubeStateChange}
        opts={{
          color: 'white',
          modestbranding: 1,
          vq: 'hd720',
          rel: 0,
          enablejsapi: 1,
        }}
      />
    )
  }

  return (
    <div className={css.playerContainer}>
      {
        videoCover
        && (
          <div
            style={{
              backgroundImage: `url('https://i3.ytimg.com/vi/${lecture.youtubeId}/maxresdefault.jpg')`,
            }}
            className={css.videoCover}
            onClick={handleHideCover}
          >
            <FontAwesome
              name="faPlay"
              className={css.videoCoverPlayButton}
            />
          </div>
        )
      }
      {renderExternalPlayer(lecture)}
    </div>
  )
}

Player.propTypes = {
  lecture: propTypes.object.isRequired,
  onMarkWatched: propTypes.object.isRequired,
  setSeekFn: propTypes.func.isRequired,
}

export default Player
