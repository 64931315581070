export default {
  menuColor: '#666',
  padding: 20,
  halfPadding: '10px',
  altColor1: '#f7f7f7',
  gray: '#f1f1f1',
  darkGray: '#888',
  selected: '#b0cee4',
  brand: '#4a92c5',
  red: '#b83c3c',
  border: '1px solid #f1f1f1',
}
