import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import theme from 'css/front'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import DownloadSnapshot from 'components/lecture/DownloadSnapshot'
import { Link } from 'react-router-dom'

const paddingLeft = 10

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    maxWidth: theme.sizes.containerWidth,
    margin: '60px 95px 0 60px',
    transition: 'maxHeight .2s',
    [theme.mobileOnly()]: {
      margin: '25px 35px 0 35px',
      fontSize: 14,
      lineHeight: '20px',
      overflow: 'hidden',
    },
  },
  divider: {
    background: theme.colors.divider,
    height: 1,
    width: '100%',
    marginBottom: 30,
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  verticalDivider: {
    background: theme.colors.divider,
    height: 20,
    width: 1,
    margin: '0 5px',
  },
  readMore: {
    padding: '5px 0',
    border: 'none',
    color: theme.colors.textDarkGray,
    cursor: 'pointer',
    fontSize: 12,
    marginBottom: 40,
    marginLeft: 10,
    background: 'none',
    ...theme.type.robotoBold,
  },
  sectionHeader: {
    marginBottom: 30,
    paddingLeft,
  },
  titleRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    ...theme.components.title,
    marginRight: 5,
  },
  sectionSubtitle: {
    fontSize: 22,
    color: theme.colors.textDarkGray,
    marginTop: 5,
    [theme.mobileOnly()]: {
      fontSize: 16,
    },
  },
  text: {
    paddingLeft,
    marginBottom: 25,
    overflow: 'hidden',
    maxHeight: 200,
    [theme.mobileOnly()]: {
      maxHeight: 100,
    },
  },
  expandDescription: {
    maxHeight: 'none',
    marginBottom: 65,
  },
  tabs: {
    display: 'flex',
    marginBottom: 15,
    [theme.max(500)]: {
      display: 'none',
    },
  },
  tab: {
    color: theme.colors.textGray,
    background: '#fff',
    border: 'none',
    fontSize: 16,
    cursor: 'pointer',
    outline: 'none',
    padding: '3px 15px',
    borderLeft: `1px solid ${theme.colors.divider}`,
    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 'none',
    },
  },
  selectedTab: {
    color: theme.colors.nuBlu,
  },
  mobileTabs: {
    display: 'none',
    position: 'relative',
    width: 150,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.divider}`,
    marginBottom: 15,
    [theme.max(500)]: {
      display: 'flex',
    },
    '& select': {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      width: '100%',
      opacity: '0',
    },
  },
  mobileTabsArrowDown: {
    border: `solid ${theme.colors.divider}`,
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: 3,
    transform: 'rotate(45deg)',
    position: 'absolute',
    top: 8,
    right: 8,
  },
  references: {
    display: 'flex',
    flexDirection: 'column',
  },
  referenceSubscribeText: {
    marginBottom: 12,
  },
  reference: {
    marginBottom: 8,
  },
  correction: {
    display: 'flex',
    marginBottom: 5,
  },
  correctionTime: {
    color: theme.colors.babyBlu,
    width: 50,
    cursor: 'pointer',
    flexShrink: 0,
    '&:hover': {
      color: theme.colors.nuBlu,
    },
    '&:active': {
      color: theme.colors.babyBlu,
    },
  },
  downloadSnapshot: {
    display: 'flex',
  },
})

const LectureBody = props => {
  const {
    description,
    lectureTitle,
    lecture,
    isSubscribed,
    references,
    corrections,
    seekToTime,
  } = props

  const css = useStyles()

  const [expandDescription, setExpandDescription] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Description')

  useEffect(() => {
    setExpandDescription(false)
    setSelectedTab('Description')
  }, [description])

  const canDownloadNotes = (isSubscribed || lecture.youtubeId !== null)
  // !!

  const handleReadMoreDescriptionMobile = () => {
    setExpandDescription(true)
  }

  const renderDownloadableSnapshots = () => {
    const labels = ['Download notes', 'Page 2', 'Page 3']
    const names = ['snapshot', 'snapshot2', 'snapshot3']
      .filter(name => lecture.meta.find(metaItem => metaItem.property === name))
    return names.map((name, index) => (
      <div className={css.downloadSnapshot}>
        <div className={css.verticalDivider} />
        <DownloadSnapshot
          label={labels[index]}
          name={name}
          lectureSlug={lecture.slug}
        />
      </div>
    ))
  }

  const renderReference = reference => (reference.link
    ? (
      <a
        rel="noopener noreferrer"
        href={reference.link}
        target="_blank"
        className={css.reference}
        key={reference.id}
      >
        {reference.name}
      </a>
    )
    : (
      <div
        className={css.reference}
      >
        {reference.name}
      </div>
    ))

  const renderReferences = () => (
    <div className={css.references}>
      {
        !isSubscribed
        && (
          <div className={css.referenceSubscribeText}>
            <Link to="/pricing">
              Subscribe
            </Link>
            {' '}
            to view the references.
          </div>
        )
      }
      {references.map(renderReference)}
    </div>
  )

  const formatLectureTime = time => `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`

  const renderCorrections = () => (
    lecture.corrections.map(correction => (
      <div key={correction.id} className={css.correction}>
        <div className={css.correctionTime}>
          {
            correction.type === 'TALK'
            && (
              <div
                onClick={() => seekToTime(correction.lectureTime)}
              >
                {formatLectureTime(correction.lectureTime)}
              </div>
            )
          }
        </div>
        <div className={css.correctionContent}>
          {correction.content}
        </div>
      </div>
    ))
  )

  const renderTab = name => (
    <button
      className={cn(css.tab, { [css.selectedTab]: selectedTab === name })}
      onClick={() => { setSelectedTab(name) }}
      type="button"
    >
      {name}
    </button>
  )

  const tabsToRender = ['Description']
  if (references && references.length) tabsToRender.push('References')

  // the check for corrections is just to make it backwards compatible
  // remove it when the cache is updated and everything is working
  if (corrections && corrections.length > 0) tabsToRender.push('Corrections')

  const renderTabs = () => (
    <div className={css.tabs}>
      {tabsToRender.map(renderTab)}
    </div>
  )

  const renderMobileTab = name => (
    <option value={name} key={name}>
      {name}
    </option>
  )

  const renderMobileTabs = () => (
    <div className={css.mobileTabs}>
      {selectedTab}
      <div className={css.mobileTabsArrowDown} />
      <select onChange={event => { setSelectedTab(event.target.value) }}>
        {tabsToRender.map(renderMobileTab)}
      </select>
    </div>
  )

  return (
    <div
      className={css.container}
    >
      <div className={css.sectionHeader}>
        <div className={css.titleRow}>
          <div className={css.sectionTitle}>About</div>
          { canDownloadNotes && renderDownloadableSnapshots() }
        </div>
        <div className={css.sectionSubtitle}>{lectureTitle}</div>
      </div>
      <div className={css.divider} />
      <div
        className={cn({
          [css.text]: true,
          [css.expandDescription]: expandDescription,
        })}
      >
        {
          tabsToRender.length > 1
          && (
            <>
              {renderTabs()}
              {renderMobileTabs()}
            </>
          )
        }
        {selectedTab === 'Description' && description}
        {selectedTab === 'References' && renderReferences()}
        {selectedTab === 'Corrections' && renderCorrections()}
      </div>
      {
        !expandDescription && (
          <button
            className={css.readMore}
            onClick={handleReadMoreDescriptionMobile}
            type="button"
          >
            + SHOW MORE
          </button>
        )
      }
    </div>
  )
}

LectureBody.propTypes = {
  description: PropTypes.string,
  lectureTitle: PropTypes.string.isRequired,
  lecture: PropTypes.object.isRequired,
  references: PropTypes.array,
  corrections: PropTypes.array,
  isSubscribed: PropTypes.bool.isRequired,
  seekToTime: PropTypes.func.isRequired,
}

LectureBody.defaultProps = {
  description: null,
  corrections: [],
  references: [],
}

export default LectureBody
