import constants from './categoriesConstants'

const initState = {
  loading: true,
  categories: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.LOAD:
      return {
        ...state,
        loading: false,
        categories: action.categories,
      }
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    default:
      return state
  }
}
