import axios from 'axios'
import storage from 'local-storage-fallback'

export default function () {
  // set the login user
  const user = storage.getItem('user')
  if (user) {
    axios.defaults.headers.common['x-auth-token'] = JSON.parse(user).token
  }

  // set the axios base url
  axios.defaults.baseURL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_API
    : process.env.REACT_APP_DEV_API
}
