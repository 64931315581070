import React from 'react'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import cn from 'classnames'
import FormHelperText from '@material-ui/core/FormHelperText'
import theme from 'css/front'

const styles = {
  showingPlaceholder: {
    color: '#ccc',
  },
  fullWidth: {
    width: '100%',
    '& .MuiSelect-root': {
      width: 800,
    },
  },
}

class SelectElement extends React.Component {
  static propTypes = {
    options: propTypes.oneOfType([propTypes.object, propTypes.array]),
    value: propTypes.oneOfType([propTypes.string, propTypes.number]),
    label: propTypes.string,
    name: propTypes.string.isRequired,
    handleChange: propTypes.func.isRequired,
    style: propTypes.object,
    placeholder: propTypes.string,
    classes: propTypes.object.isRequired,
    className: propTypes.string,
    fullWidth: propTypes.bool,
    error: propTypes.string,
    disabledOptions: propTypes.array,
  }

  static defaultProps = {
    error: null,
    disabledOptions: [],
  }

  static defaultProps = {
    options: [],
    value: '',
    label: null,
    placeholder: null,
    style: {},
    className: '',
    fullWidth: false,
  }

  static formatItems(items) {
    if (Array.isArray(items)) {
      return items.reduce((all, item) => {
        all[item] = item
        return all
      }, {})
    }

    return items
  }

  handleChange = e => {
    const { name, handleChange } = this.props
    handleChange(e.target.value, name)
  }

  renderOptions(items) {
    const {
      placeholder,
      disabledOptions,
    } = this.props

    const options = Object
      .keys(items)
      .map(key => (
        <option
          key={key}
          value={key}
          disabled={disabledOptions && disabledOptions.includes(key)}
        >
          {items[key]}
        </option>
      ))
    if (placeholder !== null) {
      options.unshift(
        <option
          key="placeholder"
          value=""
          disabled
        >
          {placeholder}
        </option>,
      )
    }
    return options
  }

  render() {
    const {
      options,
      value,
      label,
      style,
      classes: css,
      className,
      fullWidth,
      error,
    } = this.props

    return (
      <FormControl
        style={{
          minWidth: fullWidth ? '100%' : 'auto',
        }}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          native
          value={value}
          onChange={this.handleChange}
          className={cn({
            [className]: true,
            [css.showingPlaceholder]: value === '',
          })}
          style={style}
        >
          {this.renderOptions(SelectElement.formatItems(options), value)}
        </Select>
        {
          error
            && (
              <FormHelperText
                style={{ color: theme.colors.red }}
              >
                {error}
              </FormHelperText>
            )
        }
      </FormControl>
    )
  }
}

export default injectSheet(styles)(SelectElement)
