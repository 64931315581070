import React, { useState, useEffect, useCallback } from 'react'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import useNotify from 'hooks/useNotify'
import Loading from 'components/Loading'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import moment from 'moment'
import ReactTable from 'react-table'
import { debounce, memoize } from 'lodash'

const styles = {
  container: {
    margin: 20,
  },
  trialInputs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr) 100px',
    gridGap: '20px',
    maxWidth: 800,
  },
}

const Trials = props => {
  const {
    classes: css,
    history,
  } = props

  const getInitNewTrialState = () => ({
    email: '',
    endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
    planName: '',
  })

  const [trials, setTrials] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState({
    term: '',
    input: '',
  })
  const [newTrial, setNewTrial] = useState(getInitNewTrialState())

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)
  
  const loadData = () => axios.get('/trials/admin-get-all')
    .then(trialsRes => {
      setTrials(trialsRes.data.map(trial => ({
        ...trial,
        username: trial.User ? trial.User.username : null,
        userId: trial.User ? trial.User.id : null,
      })))
      setLoading(false)
    })

  useEffect(() => {
    loadData().catch(console.error)
  }, [])

  const handleSearch = useCallback(debounce(val => {
    setSearch(prevState => ({ ...prevState, term: val }))
  }, 400), [])

  const handleDelete = async trialId => {
    setLoading(true)
    await axios.delete(`/trials/admin-delete/${trialId}`)
    await loadData()
  }

  const getTableColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Plan Name',
      accessor: 'planName',
    },
    {
      id: 'endDate',
      Header: 'End Date',
      accessor: row => moment(row.endDate).format('X'),
      sortMethod: (a, b) => a - b,
      Cell: row => moment(row.original.endDate).format('MM/DD/YY'),
    },
    {
      id: 'created',
      Header: 'Created',
      accessor: row => moment(row.createdAt).format('X'),
      sortMethod: (a, b) => a - b,
      Cell: row => moment(row.original.createdAt).format('MM/DD/YY h:mma'),
    },
    {
      id: 'edit',
      Header: 'Actions',
      accessor: 'actions',
      Cell: row => (
        <Button
          label="Delete"
          onClick={() => { handleDelete(row.original.id) }}
        />
      ),
    },
  ]

  const searchTrials = memoize(term => trials.filter(trial => [
    'username',
    'id',
    'bio',
    'email',
    'planName',
  ]
    .map(field => trial[field])
    .join(' ')
    .toLowerCase()
    .search(term.toLowerCase()) > -1))

  const getTrialData = () => {
    if (search.term === '') return trials

    return searchTrials(search.term)
  }

  if (loading) return <Loading fill />

  const handleGoToUser = userId => {
    history.push(`/admin/user/${userId}`)
  }

  const handleUpdateSearch = (attr, val) => {
    setSearch(prevState => ({ ...prevState, input: val }))
    handleSearch(val)
  }

  const handleUpdateNewTrial = (attr, val) => {
    setNewTrial(prevState => ({
      ...prevState,
      [attr]: val,
    }))
  }

  const handleSaveTrial = async () => {
    if (!newTrial.endDate || !newTrial.planName || !newTrial.email) {
      notify('Fill in all of the fields', 'error')
      return
    }

    setLoading(true)
    await axios.post('/trials/admin-create-trial', newTrial)
    await loadData()
    setNewTrial(getInitNewTrialState())
  }

  const renderAddTrial = () => (
    <div className={css.addTrial}>
      <b>Add Trial</b>
      <div className={css.trialInputs}>
        <Input
          name="email"
          value={newTrial.email}
          label="Email"
          onChange={handleUpdateNewTrial}
          type="email"
          fullWidth
        />
        <Input
          name="endDate"
          value={newTrial.endDate}
          label="End date"
          onChange={handleUpdateNewTrial}
          type="date"
          fullWidth
        />
        <Input
          name="planName"
          value={newTrial.planName}
          label="Trial Name"
          onChange={handleUpdateNewTrial}
          fullWidth
        />
        <Button
          label="Save"
          onClick={handleSaveTrial}
        />
      </div>
    </div>
  )

  return (
    <div className={css.container}>
      {renderAddTrial()}
      <div className={css.searchContainer}>
        <Input
          name="search"
          value={search.input}
          label="Search"
          onChange={handleUpdateSearch}
          fullWidth
        />
      </div>
      <ReactTable
        data={getTrialData()}
        columns={getTableColumns()}
        defaultPageSize={50}
        getTdProps={(state, rowInfo, column) => ({
          onClick: () => {
            if (column.Header === 'Username' && rowInfo.original.userId) {
              handleGoToUser(rowInfo.original.userId)
            }
          },
        })}
        defaultSorted={[
          {
            id: 'created',
            desc: true,
          },
        ]}
      />
    </div>
  )
}

Trials.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withRouter(injectSheet(styles)(Trials))
