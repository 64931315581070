import constants from './usersConstants'

const initState = {
  loading: false,
  users: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    case constants.SET_USERS:
      return {
        ...state,
        loading: false,
        users: action.users,
      }
    default:
      return state
  }
}
