import moment from 'moment'
import { throttle } from 'lodash'
import axios from 'axios'

class LectureTracker {
  constructor() {
    this.queue = []

    this.send = () => {
      if (this.queue.length === 0) return
      axios.post('/events', this.queue)
        .catch(e => {
          console.error(e)
        })
      this.queue = []
    }

    this.sendThrottled = throttle(() => {
      this.send()
    }, 2000)
  }

  event(lectureId, time, action) {
    this.queue.push({
      lectureId,
      time,
      action,
      createdAt: moment().format(),
    })
    this.sendThrottled()
  }
}

export default new LectureTracker()
