import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'components/modals/FrontModal'
import { debounce } from 'lodash'
import propTypes from 'prop-types'
import Input from 'components/elements/Input'
import injectSheet from 'react-jss'
import theme from 'css/front'
import axios from 'axios'
import { Link } from 'react-router-dom'

const styles = {
  modal: {
    width: 600,
    height: 400,
    [theme.mobileOnly()]: {
      width: 'calc(90vw - 40px)',
    },
  },
  searchResults: {
    overflowX: 'auto',
    height: 'calc(100% - 54px)',
    marginTop: 20,
  },
  result: {
    padding: 5,
    '& a': {
      color: theme.colors.textGray,
    },
    '&:nth-child(odd)': {
      background: theme.colors.backgroundGray,
    },
  },
  category: {
    fontSize: 12,
  },
  message: {
    textAlign: 'center',
    marginTop: 30,
  },
}

const SearchModal = props => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchState, setSearchState] = useState({
    searchResults: [],
    loadingSearch: false,
  })

  const search = useCallback(debounce(async term => {
    if (term === '') {
      setSearchState({
        searchResults: [],
        loadingSearch: false,
      })
      return
    }
    const results = await axios.get(`/search/${encodeURIComponent(term)}`)
    setSearchState({
      searchResults: results.data,
      loadingSearch: false,
    })
  }, 500, {
    leading: false,
    trailing: true,
  }), [])

  useEffect(() => {
    // reset
    setSearchTerm('')
    setSearchState({
      searchResults: [],
      loadingSearch: false,
    })
  }, [])

  const handleSearch = (name, value) => {
    setSearchTerm(value)
    setSearchState({
      loadingSearch: true,
    })

    search(value)
  }

  const handleClose = () => {
    const {
      handleClose,
    } = props

    handleClose()
  }

  const renderSearchResult = result => {
    const {
      classes: css,
    } = props

    return (
      <div
        className={css.result}
        key={result.slug}
      >
        <Link
          to={`/lecture/${result.Categories[0].slug}/${result.slug}`}
          onClick={handleClose}
        >
          {result.name}
        </Link>
        <div className={css.category}>
          {result.Categories[0].name}
        </div>
      </div>
    )
  }

  const renderMessage = () => {
    const {
      classes: css,
    } = props

    const {
      loadingSearch,
    } = searchState

    return (
      <div className={css.message}>
        {
          loadingSearch
            ? 'Loading...'
            : 'No results'
        }
      </div>
    )

  }

  const render = () => {
    const {
      handleClose,
      classes: css,
    } = props

    const {
      searchResults = [],
    } = searchState

    return (
      <Modal
        open
        handleClose={handleClose}
        title="Search"
      >
        <div className={css.modal}>
          <Input
            name="search"
            value={searchTerm}
            label=""
            onChange={handleSearch}
            fullWidth
            autoFocus
            icon="faSearch"
          />
          <div className={css.searchResults}>
            {}
            {
              searchResults.length
                ? searchResults.map(renderSearchResult)
                : renderMessage()
            }
          </div>
        </div>
      </Modal>
    )
  }

  return render()
}

SearchModal.propTypes = {
  handleClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(SearchModal)
