import axios from 'axios'
import constants from './usersConstants'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const load = () => async dispatch => {
  try {
    dispatch(loading(true))
    const res = await axios.get('/users')
    dispatch({
      type: constants.SET_USERS,
      users: res.data,
    })
  } catch (e) {
    dispatch(loading(false))
  }
}


export default {
  load,
}