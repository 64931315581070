import React from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'components/modals/FrontModal'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import Button from 'components/elements/Button'
import theme from 'css/front'
import cn from 'classnames'
import FontAwesome from 'components/FontAwesome'
import modalsActions from 'redux/modals/modalsActions'

const styles = {
  modal: {
    width: '400px',
    margin: '10',
    [theme.max(450)]: {
      width: 'calc(100vw - 20px)',
    },
  },
  errorIcon: {
    textAlign: 'center',
    color: theme.colors.red,
    marginBottom: 20,
    fontSize: 25,
    opacity: 0.5,
  },
  text: {
    fontSize: 12,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
}

const ForgotPasswordModal = props => {
  const {
    classes: css,
    handleClose,
  } = props

  const dispatch = useDispatch()

  const handleLoginClick = () => {
    dispatch(modalsActions.isOpen('login'))
  }

  return (
    <Modal
      open
      handleClose={handleClose}
      title="Logged Out"
    >
      <div className={css.modal}>
        <FontAwesome
          name="faExclamationTriangle"
          className={css.errorIcon}
        />
        <div className={css.text}>
          You have been logged out due to activity on another device. Remember, you can be logged in with one device at a time.
        </div>
        <div className={css.buttonContainer}>
          <Button
            className={css.button}
            onClick={handleLoginClick}
            label="Login"
            properties={['small']}
          />
        </div>
      </div>
    </Modal>
  )
}

ForgotPasswordModal.propTypes = {
  classes: propTypes.object.isRequired,
  handleClose: propTypes.func.isRequired,
}

export default injectSheet(styles)(ForgotPasswordModal)
