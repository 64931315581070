import { combineReducers } from 'redux'
import user from 'redux/user/userReducer'
import users from 'redux/users/usersReducer'
import categories from 'redux/categories/categoriesReducer'
import category from 'redux/category/categoryReducer'
import modals from 'redux/modals/modalsReducer'
import lecture from 'redux/lecture/lectureReducer'
import alert from 'redux/alert/alertReducer'
import notifications from 'redux/notifications/notificationsReducer'
import comments from 'redux/comments/commentsReducer'
import membership from 'redux/membership/membershipReducer'
import uiFlags from 'redux/uiFlags/uiFlagsReducer'

export default combineReducers({
  categories,
  category,
  comments,
  lecture,
  membership,
  modals,
  alert,
  user,
  users,
  uiFlags,
  notifications,
})
