import React from 'react'
import { createUseStyles } from 'react-jss'
import cssVars from 'css/variables'
import FontAwesome from 'components/FontAwesome'
import cn from 'classnames'
import PropTypes from 'prop-types'
import listItemStyles from 'css/components/listItem'
import categoryActions from 'redux/category/categoryActions'
import modalsActions from 'redux/modals/modalsActions'
import { useDispatch } from 'react-redux'

const useStyles = createUseStyles({
  row: { ...listItemStyles.row },
  categoryContent: {
    paddingRight: cssVars.padding,
  },
  info: {
    paddingRight: 10,
  },
  categoryEdit: {
    marginLeft: 'auto',
    color: cssVars.darkGray,
    '&:hover': {
      color: cssVars.brand,
    },
    '&:active': {
      color: cssVars.darkGray,
    },
  },
  selectedCategory: {
    background: cssVars.selected,
    '&:nth-child(odd)': {
      background: cssVars.selected,
    },
  },
  categoryName: {

  },
  counts: {
    fontSize: 11,
    color: cssVars.darkGray,
  },
  actions: {
    ...listItemStyles.actions,
    position: 'relative',
  },
  icon: {
    ...listItemStyles.icon,
  },
  fullHeightIcon: {
    height: 40,
    width: 30,
  },
})

let temp = false

const CategoryRow = props => {
  const {
    category,
    handleCategorySelect: parentHandleCategorySelect,
    handleMove: parentHandleMove,
  } = props

  const dispatch = useDispatch()
  const css = useStyles()

  // useEffect(() => {
  //   if (temp) return
  //   dispatch(modalsActions.isOpen('adminLecture'))
  //   dispatch(categoryActions.load(28))
  //   dispatch(lectureActions.load(1535))
  //   temp = true
  // }, [])

  const handleCategorySelect = () => {
    parentHandleCategorySelect(category.id)
  }

  const handleCategoryEdit = () => {
    dispatch(modalsActions.isOpen('adminCategory', true))
    dispatch(categoryActions.load(category.id))
  }

  const handleMove = direction => {
    parentHandleMove(category.id, direction)
  }

  const handleMoveUp = () => {
    handleMove('up')
  }

  const handleMoveDown = () => {
    handleMove('down')
  }

  const renderCategory = () => (
    <div
      className={css.row}
    >
      <div className={css.info}>
        <div>{category.name}</div>
        <div className={css.counts}>
          {
            category.categoryCount > 0
            && (
              <div>
                {`Categories: ${category.categoryCount}`}
              </div>
            )
          }
          {
            category.lectureCount > 0
            && (
              <div>
                {`Lectures: ${category.lectureCount}`}
              </div>
            )
          }
        </div>
      </div>
      <div className={css.actions}>
        <div>
          <FontAwesome
            className={css.icon}
            name="faChevronUp"
            handleClick={handleMoveUp}
          />
          <FontAwesome
            className={css.icon}
            name="faChevronDown"
            handleClick={handleMoveDown}
          />
        </div>
        <FontAwesome
          className={cn(css.icon, css.fullHeightIcon)}
          name="faEdit"
          handleClick={handleCategoryEdit}
        />
        <FontAwesome
          className={cn(css.icon, css.fullHeightIcon)}
          name="faChevronRight"
          handleClick={handleCategorySelect}
        />

      </div>
    </div>
  )

  return renderCategory()
}

CategoryRow.propTypes = {
  category: PropTypes.object.isRequired,
  handleCategorySelect: PropTypes.func.isRequired,
  handleMove: PropTypes.func.isRequired,
}

CategoryRow.defaultProps = {
  lecture: {},
}

export default CategoryRow
