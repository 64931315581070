import { useState } from 'react'
import axios from 'axios'

export default ({
  initLoading = true,
  ...props
} = {}) => {
  const [object, setObject] = useState(null)
  const [loading, setLoading] = useState(initLoading)

  const request = async url => {
    try {
      const res = await axios({
        url,
        method: 'GET',
      })
      setObject(res.data.link)
    } catch (err) {
      setObject(null)
    }
  }

  const handleLoadObject = async url => {
    setLoading(true)
    await request(url)
      .finally(() => {
        setLoading(false)
      })
    
  }

  const handleLoading = newVal => {
    setLoading(!!newVal)
  }

  if (props.type) {
    handleLoadObject(props).catch(console.error)
  }

  return {
    object,
    loading,
    handleLoadObject,
    handleLoading,
  }
}
