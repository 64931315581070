export const imageLink = '/images/subjects'
export const subjects = {
  college: [
    {
      name: 'biology',
      link: 'biology',
    },
    {
      name: 'general chemistry',
      link: 'general-chemistry',
      image: 'generalChemistry',
    },
    {
      name: 'organic chemistry',
      link: 'organic-chemistry',
      image: 'organicChemistry',
    },
    {
      name: 'biochemistry',
      link: 'biochemistry',
    },
    {
      name: 'classical physics',
      link: 'classical-physics',
      image: 'classicalPhysics',
    },
    {
      name: 'modern physics',
      link: 'modern-physics',
      image: 'modernPhysics',
    },
  ],
  medical: [
    {
      name: 'fundamentals',
      link: 'fundamentals',
    },
    {
      name: 'pathology',
      link: 'pathology',
    },
    {
      name: 'hematology',
      link: 'hematology',
    },
    {
      name: 'oncology',
      link: 'oncology',
    },
    {
      name: 'gastrointestinal',
      link: 'gastrointestinal',
    },
    {
      name: 'renal',
      link: 'renal',
    },
    {
      name: 'pulmonology',
      link: 'pulmonology',
    },
    {
      name: 'cardiovascular',
      link: 'cardiovascular',
    },
    {
      name: 'endocrinology',
      link: 'endocrinology',
    },
    {
      name: 'rheumatology',
      link: 'rheumatology',
    },
    {
      name: 'neurology',
      link: 'neurology',
    },
    {
      name: 'dermatology',
      link: 'dermatology',
    },
    {
      name: 'microbiology',
      link: 'microbiology',
    },
    {
      name: 'pharmacology',
      link: 'pharmacology',
    },
    {
      name: 'Behavioral Science',
      link: 'behavioral-science',
      image: 'behavioralScience',
    },
  ],
}
