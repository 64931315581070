import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import theme from 'css/front'
import cn from 'classnames'
import axios from 'axios'
import validator from 'validator'
import alertActions from 'redux/alert/alertActions'

const styles = {
  container: {
    background: theme.colors.backgroundGray,
    maxWidth: 400,
    padding: 20,
    margin: '20px auto 0 auto',
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const ForgotPassword = props => {
  const {
    classes: css,
    match: {
      params,
    },
    history,
  } = props

  const [passwords, setPassword] = useState({
    password1: '',
    password2: '',
  })

  const dispatch = useDispatch()

  const handlePasswordUpdate = (attr, val) => {
    setPassword({
      ...passwords,
      [attr]: val,
    })
  }

  const handleResetPassword = async () => {
    if (!validator.isLength(passwords.password1, { min: 6, max: 200 })) {
      dispatch(alertActions.create('Password must be at least 6 characters', 'error'))
      return
    }

    if (passwords.password1 !== passwords.password2) {
      dispatch(alertActions.create('The passwords do not match.', 'error'))
      return
    }

    try {
      await axios.post(`users/reset-password/${params.resetToken}`, {
        password: passwords.password1,
      })
      history.push('/')
      dispatch(alertActions.create('Password updated. Login with your new password.'))
    } catch (err) {
      dispatch(alertActions.create(err.response.data.message, 'error'))
    }
  }

  return (
    <div className={css.container}>
      <Input
        type="password"
        name="password1"
        value={passwords.password1}
        label="Password"
        onChange={handlePasswordUpdate}
        fullWidth
        autoFocus
      />
      <Input
        type="password"
        name="password2"
        value={passwords.password2}
        label="Repeat password"
        onChange={handlePasswordUpdate}
        fullWidth
      />
      <div className={cn(css.row, css.center)}>
        <Button
          onClick={handleResetPassword}
          label="Reset password"
        />
      </div>
    </div>
  )
}

ForgotPassword.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.shape({
    params: propTypes.object.isRequired,
  }).isRequired,
  history: propTypes.object.isRequired,
}

export default injectSheet(styles)(ForgotPassword)
