import React, { useState, useEffect } from 'react'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import useNotify from 'hooks/useNotify'
import Loading from 'components/Loading'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import moment from 'moment'

const styles = {
  container: {
    margin: 20,
    '& table': {
      border: '1px solid #ccc',
      borderBottom: 'none',
      borderRight: 'none',
      '& td:first-child': {
        textTransform: 'capitalize',
      },
      '& td': {
        border: '1px solid #ccc',
        borderTop: 'none',
        borderLeft: 'none',
        padding: 5,
      },
    },
  },
  addTrial: {
    border: '1px solid #ccc',
    padding: 5,
    maxWidth: 500,
    marginTop: 20,
  },
}

const User = props => {
  const {
    classes: css,
    match: {
      params,
    },
  } = props

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)

  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [newTrial, setNewTrial] = useState({
    endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
    planName: '',
  })

  useEffect(() => {
    axios.get(`users/admin-user/${params.userId}`)
      .then(userRes => {
        const userInfo = userRes.data[0]
        userInfo.trialEnd = userInfo.SubscriptionTrials.length ? userInfo.SubscriptionTrials[0].endDate : ''
        userInfo.trialName = userInfo.SubscriptionTrials.length ? userInfo.SubscriptionTrials[0].planName : ''
        userInfo.payments = userInfo.Payments.length
          ? userInfo.Payments
            .map(payment => `${payment.item} ${payment.amount} ${payment.paymentAt}`)
          : []
        setUser(userInfo)
        setLoading(false)
      })
  }, [])


  const handleUpdateNewTrial = (attr, val) => {
    setNewTrial(prevState => ({
      ...prevState,
      [attr]: val,
    }))
  }

  const renderField = field => {
    if (!user[field]) return null

    return (
      <tr key={field}>
        <td>{field}</td>
        <td>{user[field]}</td>
      </tr>
    )
  }

  const handleSaveTrial = async () => {
    if (!newTrial.endDate || !newTrial.planName) {
      notify('Fill in all of the fields', 'error')
      return
    }

    setLoading(true)
    await axios.post(`/users/admin-create-trial/${params.userId}`, newTrial)
    setUser(prevState => ({
      ...prevState,
      trialEnd: newTrial.endDate,
      trialName: newTrial.planName,
    }))
    setLoading(false)
  }

  if (loading) return <Loading fill />

  return (
    <div className={css.container}>
      <table cellSpacing="0">
        {
          [
            'username',
            'email',
            'name',
            'bio',
            'location',
            'occupation',
            'school',
            'trialName',
            'trialEnd',
          ].map(renderField)
        }
      </table>
      <div className={css.addTrial}>
        <b>Add Trial</b>
        <Input
          name="endDate"
          value={newTrial.endDate}
          label="End date"
          onChange={handleUpdateNewTrial}
          type="date"
          fullWidth
        />
        <Input
          name="planName"
          value={newTrial.planName}
          label="Trial Name"
          onChange={handleUpdateNewTrial}
          fullWidth
        />
        <Button
          label="Save"
          onClick={handleSaveTrial}
        />
      </div>
    </div>
  )
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default injectSheet(styles)(withRouter(User))
