import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useNotify from 'hooks/useNotify'
import Loading from 'components/Loading'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { useDropzone } from 'react-dropzone'
import downloadUrls from 'helpers/downloadUrls'
import useAssetGetter from 'hooks/useAssetGetter'
import useAssetSetter from 'hooks/useAssetSetter'
import theme from 'css/front'

const styles = {
  container: {
    height: 100,
    width: 100,
    border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'default',
    fontSize: 12,
    position: 'relative',
    outline: 'none',
  },
  img: {
    objectFit: 'contain',
    width: 100,
    height: 100,
  },
  deleteButton: {
    position: 'absolute',
    bottom: '-1px',
    left: '0',
    width: '100%',
    border: 'none',
    background: theme.colors.red,
    color: '#fff',
    cursor: 'pointer',
  },
}

const LectureShapshotUpload = props => {
  const {
    classes: css,
    lectureId,
    lectureSlug,
    name,
  } = props

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)

  const {
    object: snapshotImg,
    loading: downloadLoading,
    handleLoadObject,
  } = useAssetGetter()

  const {
    loading: uploadProcessing,
    handleUpload,
    handleDelete,
  } = useAssetSetter('/lectures/edit-snapshot')

  const handleReloadImage = () => handleLoadObject(downloadUrls.getSnapshotUrl(lectureSlug, name))

  useEffect(() => {
    handleReloadImage()
      .catch(console.error)
  }, [])

  const onDrop = useCallback(acceptedFiles => {
    handleUpload(acceptedFiles, { lectureId, name })
      .then(() => {
        notify('uploaded.')
        handleReloadImage().catch(console.error)
      })
      .catch(err => {
        notify('Error. Try refreshing or contact Alex.')
        console.error(err)
      })
  }, [])
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({ onDrop })

  const handleDeleteImg = async event => {
    event.preventDefault()
    event.stopPropagation()
    await handleDelete([lectureId, name])
    await handleReloadImage().catch(console.error)
  }

  const getContent = () => {
    if (uploadProcessing || downloadLoading) return <Loading fill />
    if (!snapshotImg) return 'Add snapshot here'
    return (
      <>
        <img
          className={css.img}
          src={snapshotImg}
          alt="snapshot of lectures"
        />
        <button
          className={css.deleteButton}
          type="button"
          onClick={handleDeleteImg}
        >
          Delete
        </button>
      </>
    )
  }

  return (
    <div {...getRootProps()} className={css.container}>
      <input {...getInputProps()} />
      {getContent()}
    </div>
  )
}

LectureShapshotUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  lectureId: PropTypes.number.isRequired,
  lectureSlug: PropTypes.string.isRequired,
}

export default injectSheet(styles)(LectureShapshotUpload)