import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import theme from 'css/front'
import axios from 'axios'
import validator from 'validator'
import alertActions from 'redux/alert/alertActions'

const styles = {
  form: {
    background: theme.colors.backgroundGray,
    padding: 20,
    margin: 20,
    marginBottom: 0,
  },
  title: {
    color: theme.colors.nuBlu,
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
  },
}

const AccountForms = React.memo(props => {
  const {
    classes: css,
  } = props

  const dispatch = useDispatch()

  const [profile, setProfile] = useState({
    name: '',
    location: '',
    occupation: '',
    education: '',
    bio: '',
    loading: true,
  })

  const [email, setEmail] = useState({
    email: '',
    loading: true,
  })

  const [password, setPassword] = useState({
    oldPassword: '',
    password1: '',
    password2: '',
  })

  

  const userId = useSelector(state => state.user.id)

  useEffect(() => {
    axios.get(`/users/profile/${userId}`)
      .then(userProfile => {
        setProfile(prevProfile => ({
          ...prevProfile,
          ...userProfile.data,
          loading: false,
        }))
      })
      .catch(() => {
        dispatch(alertActions.create('Cannot load profile. Please refresh.', 'error'))
      })
    axios.get('/users/own-email')
      .then(userEmail => {
        setEmail(prevEmail => ({
          ...prevEmail,
          loading: false,
          email: userEmail.data,
        }))
      })
      .catch(() => {
        dispatch(alertActions.create('Cannot load email. Please refresh.', 'error'))
      })
  }, [userId])

  const handleInputUpdate = formName => (attr, value) => {
    switch (formName) {
      case 'profile':
        setProfile(prevProfile => ({
          ...prevProfile,
          [attr]: attr !== 'bio' ? value : value.substr(0, 500),
        }))
        break
      case 'email':
        setEmail(prevEmail => ({
          ...prevEmail,
          [attr]: value,
        }))
        break
      case 'password':
        setPassword(prevPassword => ({
          ...prevPassword,
          [attr]: value,
        }))
        break
      default:
        console.error('Account form not found')
    }
  }

  const handleSubmitForm = formName => async () => {
    switch (formName) {
      case 'profile':
        try {
          await axios.put('/users/profile', profile)
          dispatch(alertActions.create('Profile updated'))
        } catch (e) {
          dispatch(alertActions.create('Cannot save profile. Please refresh.', 'error'))
        }
        break
      case 'email':
        if (!validator.isEmail(email.email)) {
          dispatch(alertActions.create('Email is invalid.', 'error'))
          return
        }
        try {
          await axios.put('/users/email', email)
          dispatch(alertActions.create('Email updated'))
        } catch (e) {
          dispatch(alertActions.create(e.response.data.message, 'error'))
        }
        break
      case 'password':
        if (password.oldPassword.length === 0) {
          dispatch(alertActions.create('Please enter your old password', 'error'))
          return
        }
        if (password.password1.length < 6) {
          dispatch(alertActions.create('New password must be at least 6 characters', 'error'))
          return
        }
        if (password.password1 !== password.password2) {
          dispatch(alertActions.create('Repeat password does not match', 'error'))
          return
        }
        try {
          await axios.put('/users/change-password', {
            oldPassword: password.oldPassword,
            newPassword: password.password1,
          })
          dispatch(alertActions.create('password updated'))
          setPassword({
            oldPassword: '',
            password1: '',
            password2: '',
          })
        } catch (e) {
          dispatch(alertActions.create(e.response.data.message, 'error'))
        }
        break
      default:
        console.error('Account form not found')
    }
  }

  const renderProfileForm = useMemo(() => (
    <div className={css.form}>
      <div className={css.title}>
        Your profile
      </div>
      <Input
        name="name"
        value={profile.name}
        label="Name"
        onChange={handleInputUpdate('profile')}
        fullWidth
      />
      <Input
        name="location"
        value={profile.location}
        label="Location"
        onChange={handleInputUpdate('profile')}
        fullWidth
      />
      <Input
        name="occupation"
        value={profile.occupation}
        label="Occupation"
        onChange={handleInputUpdate('profile')}
        fullWidth
      />
      <Input
        name="education"
        value={profile.education}
        label="College/education"
        onChange={handleInputUpdate('profile')}
        fullWidth
      />
      <Input
        name="bio"
        value={profile.bio}
        label="A little about yourself. (500 characters max)"
        onChange={handleInputUpdate('profile')}
        fullWidth
        multiline
      />
      <Button
        className={css.button}
        onClick={handleSubmitForm('profile')}
        label="Save"
        properties={['small']}
      />
    </div>
  ), [profile])

  const renderEmailForm = useMemo(() => (
    <div className={css.form}>
      <div className={css.title}>
        Update your email
      </div>
      <Input
        name="email"
        value={email.email}
        label="Email"
        onChange={handleInputUpdate('email')}
        fullWidth
      />
      <Button
        className={css.button}
        onClick={handleSubmitForm('email')}
        label="Save"
        properties={['small']}
      />
    </div>
  ), [email])

  const renderPasswordForm = useMemo(() => (
    <div className={css.form}>
      <div className={css.title}>
        Change your password
      </div>
      <Input
        type="password"
        name="oldPassword"
        value={password.oldPassword}
        label="Old password"
        onChange={handleInputUpdate('password')}
        fullWidth
      />
      <Input
        type="password"
        name="password1"
        value={password.password1}
        label="New password"
        onChange={handleInputUpdate('password')}
        fullWidth
      />
      <Input
        type="password"
        name="password2"
        value={password.password2}
        label="Repeat password"
        onChange={handleInputUpdate('password')}
        fullWidth
      />
      <Button
        className={css.button}
        onClick={handleSubmitForm('password')}
        label="Save"
        properties={['small']}
      />
    </div>
  ), [password])

  return (
    <>
      {renderProfileForm}
      {renderEmailForm}
      {renderPasswordForm}
    </>
  )
})

AccountForms.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(AccountForms)
