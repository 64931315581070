import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Helmet from 'react-helmet'
import imgAndrey from 'images/team/andrey.jpg'
import imgAlex from 'images/team/alex.jpg'

const styles = {
  container: {
    maxWidth: theme.sizes.containerWidth,
    margin: '20px auto',
    [theme.max(theme.sizes.containerWidth + 40)]: {
      margin: 20,
    },
  },
  pageTitle: {
    ...theme.type.avenir,
    fontSize: 19,
    marginBottom: 20,
  },
  teamContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.max(854)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [theme.mobileOnly()]: {
      flexDirection: 'column',
    },
  },
  person: {
    position: 'relative',
    display: 'flex',
    marginBottom: 20,
    background: theme.colors.backgroundGray,
    [theme.max(854)]: {
      flexDirection: 'column',
      flex: 1,
      '&:first-child': {
        marginRight: 10,
      },
    },
    '& img': {
      width: 400,
      height: 400,
      [theme.max(854)]: {
        width: '100%',
        height: 'auto',
      },
    },
  },
  bio: {
    width: 'calc(100% - 400px)',
    padding: 20,
    color: theme.colors.textGray,
    fontSize: 16,
    [theme.max(854)]: {
      width: '100%',
    },
  },
  name: {
    color: theme.colors.nuBlu,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: 15,
  },
}

const Team = props => {
  const {
    classes: css,
  } = props

  return (
    <div className={css.container}>
      <Helmet>
        <title>AK Lectures - Team</title>
        <meta
          name="description"
          content="Meet the team"
        />
      </Helmet>
      <div className={css.pageTitle}>
        Meet the team
      </div>
      <div className={css.teamContainer}>
        <div className={css.person}>
          <img src={imgAndrey} alt="Andrey profile" />
          <div className={css.bio}>
            <div className={css.name}>Andrey Kopot, M.D.</div>
            <div className={css.title}>Instructor</div>
            <p>Andrey grew up in Brooklyn and attended NYU, where he studied at the Courant Institute of Mathematical Sciences and graduated with honors in Applied Mathematics.</p>
            <p>An avid learner and an academic buff, Andrey is committed to expanding the online library and providing you with quality explanations to difficult concepts in the sciences.</p>
            <p>Andrey graduated from Jacobs School of Medicine at University at Buffalo in May 2020. He is currently completing his residency in Internal Medicine at the Cleveland Clinic in Cleveland, Ohio.</p>
          </div>
        </div>
        <div className={css.person}>
          <img src={imgAlex} alt="Alex profile" />
          <div className={css.bio}>
            <div className={css.name}>Aleksey Vovk</div>
            <div className={css.title}>Developer</div>
            <p>Alex grew up in Brooklyn and attended New York Institute of Technology where he received a degree in Computer Science with a concentration in Mathematics.</p>
            <p>Alex is as passionate about technology as he is about education and hopes to combine his interests in the two fields to create an interactive educational environment focused around academics and learning.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Team.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(Team)
