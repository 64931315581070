import axios from 'axios'
import constants from './userConstants'
import storage from 'local-storage-fallback'

const guestUser = {
  initLoading: true, // When the page first load, load the user
  loginFailed: false,
  loading: false,
  id: 0,
  username: 'guest',
  role: 'guest',
  token: null,
  registerError: null,
  level: 'user',
  expires: null,
}

export default (state = guestUser, action) => {
  switch (action.type) {
    case constants.SET_USER:
      storage.setItem('auth-token', action.user.token)
      axios.defaults.headers.common['x-auth-token'] = action.user.token
      return {
        ...state,
        loginFailed: false,
        loading: false,
        initLoading: false,
        ...action.user,
      }
    case constants.LOGOUT:
      storage.removeItem('auth-token')
      delete axios.defaults.headers.common['x-auth-token']
      return {
        ...guestUser,
        initLoading: false,
      }
    case constants.LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true,
      }
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    case constants.SET_INIT_LOADING:
      storage.removeItem('auth-token')
      return {
        ...state,
        initLoading: action.isLoading,
      }
    case constants.SET_REGISTER_ERROR:
      return {
        ...state,
        registerError: action.message,
      }
    case constants.SET:
      return {
        ...state,
        [action.name]: action.val,
      }
    default:
      return state
  }
}
