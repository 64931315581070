import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Button from 'components/elements/Button'
import cn from 'classnames'
import { subjects } from 'data/subjects'
import getSubjectImage from 'helpers/getSubjectImage'

const { plans } = require('constants/index')

const styles = {
  header: {
    background: theme.colors.backgroundDarkest,
    textAlign: 'center',
    color: '#fff',
    fontSize: 13,
    padding: '0 20px 98px 20px',
    [theme.max(1000)]: {
      paddingBottom: 50,
      lineHeight: '20px',
    },
  },
  headerTitle: {
    padding: '53px 0 18px 0',
    fontSize: 32,
    ...theme.type.robotoBold,
    [theme.max(1000)]: {
      fontSize: 18,
      padding: '20px 0 18px 0',
    },
  },
  headerLineBreak: {
    [theme.max(1000)]: {
      display: 'none',
    },
  },
  planContainer: {
    display: 'flex',
    [theme.max(1000)]: {
      flexDirection: 'column',
    },
  },
  plan: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '88px 20px 97px 20px',
    flexDirection: 'column',
    ...theme.type.roboto,
    [theme.max(1000)]: {
      padding: '53px 0',
    },
  },
  monthly: {
    background: theme.colors.plans.monthly.background,
    '& .purchase-button': {
      borderColor: `${theme.colors.plans.monthly.main} !important`,
      color: theme.colors.plans.monthly.main,
    },
    '& .plan-color': {
      color: theme.colors.plans.monthly.main,
    },
  },
  quarterly: {
    background: theme.colors.plans.quarterly.background,
    '& .purchase-button': {
      borderColor: `${theme.colors.plans.quarterly.main} !important`,
      color: theme.colors.plans.quarterly.main,
    },
    '& .plan-color': {
      color: theme.colors.plans.quarterly.main,
    },
  },
  biannual: {
    background: theme.colors.plans.biannual.background,
    '& .purchase-button': {
      borderColor: `${theme.colors.plans.biannual.main} !important`,
      color: theme.colors.plans.biannual.main,
    },
    '& .plan-color': {
      color: theme.colors.plans.biannual.main,
    },
  },
  paymentOption: {
    textTransform: 'capitalize',
    ...theme.type.robotoBold,
    fontSize: 21,
  },
  planName: {
    marginTop: 5,
    fontSize: 18,
    ...theme.type.roboto,
    [theme.max(1000)]: {
      display: 'none',
    },
  },
  price: {
    display: 'flex',
    marginTop: 50,
    color: '#fff',
    ...theme.type.roboto,
    [theme.mobileOnly()]: {
      marginTop: 10,
    },
  },
  priceBig: {
    fontSize: 35,
  },
  priceSmall: {
    margin: '4px 1px',
  },
  perMonthMobile: {
    alignSelf: 'flex-end',
    display: 'none',
    [theme.max(1000)]: {
      display: 'block',
    },
  },
  perMonth: {
    fontsize: 22,
    marginTop: 5,
    letterSpacing: '2.2px',
    color: '#FCFCFD',
    [theme.max(1000)]: {
      display: 'none',
    },
  },
  purchaseButton: {
    marginTop: '50px !important',
    width: 268,
    height: 63,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '3px !important',
    [theme.max(1000)]: {
      marginTop: '40px !important',
    },
  },
  planInfo: {
    fontSize: 12,
    marginTop: 19,
    color: '#fff',
    ...theme.type.roboto,
  },
  planInfoMobile: {
    position: 'absolute',
    bottom: 10,
    left: 20,
  },
  highlight: {
    border: `1px solid ${theme.colors.nuBlu}`,
  },
  hideMobile: {
    ...theme.components.hideMobile,
  },
  hideDesktop: {
    ...theme.components.hideDesktop,
  },
  mobileTitleContainer: {
    marginLeft: 10,
  },
  introTextContainer: {
    background: '#F5F5F5',
  },
  introText: {
    color: '#1B1D30',
    fontSize: 14,
    lineHeight: '30px',
    padding: '108px 30px',
    maxWidth: 660,
    margin: '0 auto',
    textAlign: 'center',
  },
  introTitle: {
    ...theme.type.robotoLight,
    fontSize: 16,
    marginBottom: 10,
    letterSpacing: 1.28,
  },
  introSubTitle: {
    ...theme.type.robotoBold,
    fontSize: 30,
    marginBottom: 40,
    [theme.max(1000)]: {
      fontSize: 20,
    },
  },
  bold: {
    fontWeight: 'bold',
    [theme.max(1000)]: {
      fontWeight: 'normal',
    },
  },
  introBody: {
    lineHeight: '28px',
  },
  // subjects
  subjectsContainer: {
    background: '#38607E url(\'/images/specs-background.png\')',
    padding: '140px 0',
    [theme.max(1000)]: {
      padding: '70px 0',
    },
  },
  subjects: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 1200,
    margin: '0 auto',
    [theme.max(1160)]: {
      maxWidth: 1000,
    },
    [theme.max(1000)]: {
      padding: '0 30px',
    },
  },
  subject: {
    position: 'relative',
    height: 120,
    textAlign: 'center',
    width: 145,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    '&:hover': {
      color: '#fff',
    },
  },
  subjectImg: {
    maxWidth: 72,
    maxHeight: 72,
  },
  subjectName: {
    position: 'absolute',
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '1.1px',
    fontSize: 11,
    marginTop: 15,
  },
}

const Pricing = props => {
  const {
    classes: css,
  } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderPlan = plan => (
    <div className={cn(css.plan, css[plan.name])}>
      <div className={cn(css.paymentOption, 'plan-color')}>
        {plan.label}
      </div>
      <div className={cn(css.planName, 'plan-color')}>
        Medical Lectures
      </div>
      <div className={css.price}>
        <div className={css.priceBig}>
          $
          {String(plan.perMonth).split('.')[0]}
        </div>
        <div className={css.priceSmall}>
          {String(plan.perMonth).split('.')[1]}
        </div>
        <div className={css.perMonthMobile}>/ MONTH</div>
      </div>
      <div className={css.perMonth}>PER MONTH</div>
      <Button
        label="PURCHASE PACKAGE"
        className={cn(css.purchaseButton, 'purchase-button')}
        link={`/purchase/${plan.name}`}
      />
      <div className={css.planInfo}>
        {`($${plan.price} ${plan.description})`}
      </div>
    </div>
  )

  const renderPlans = () => (
    <div className={css.planContainer}>
      {plans.map(renderPlan)}
    </div>
  )

  const renderSubject = subject => (
    <div
      className={css.subject}
      key={subject.name}
    >
      <img
        className={css.subjectImg}
        src={getSubjectImage(subject.image || subject.link)}
        alt={subject.name}
      />
      <div className={css.subjectName}>{subject.name}</div>
    </div>
  )

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.headerTitle}>
          Medical Lecture Packages
        </div>
        Getting into medical school can be tough but staying afloat in medical school can be even more challenging!
        <br className={css.headerLineBreak} />
        It is precisely why we decided to create a series of lectures geared specifically for medical students.
      </div>
      {renderPlans()}
      <div className={css.introTextContainer}>
        <div className={css.introText}>
          <div className={css.introTitle}>
            GAIN ACCESS TO
          </div>
          <div className={css.introSubTitle}>
            Over 1,100 Medical Lectures
          </div>
          <p className={css.introBody}>
            The sheer volume of information and detail that medical students have to learn can be daunting at times, even for the most diligent of us. Medical students are often tasked to make sense of a sea of information in the shortest period of time. Having gone through the process ourselves, we know what it takes to get through medical school and score well on the boards.
            {' '}
            <span className={css.bold}>
              Our library contains over 1,100 videos in some of the most challenging topics in medical school including:
            </span>
          </p>
        </div>
      </div>
      <div className={css.subjectsContainer}>
        <div className={css.subjects}>
          {subjects.medical.map(renderSubject)}
        </div>
      </div>
    </div>
  )
}

Pricing.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(Pricing)
