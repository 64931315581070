import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Loading from 'components/Loading'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { round } from 'lodash'

const commas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const styles = {
  container: {
    margin: 20,
  },
  title: {
    paddingLeft: 32,
    fontSize: 16,
    marginTop: 20,
  },
  chart: {
    position: 'relative',
    width: 1000,
    fontSize: 10,
  },

}

const formatPaymentsByWeek = data => {
  const points = []
  const startDate = moment('2019-08-12 00:00:00')
  const now = moment()

  while (now.diff(startDate, 'days') >= 0) {
    points.push({
      x: startDate.format('w/YY'),
      y: 0,
    })
    startDate.add('7', 'day')
  }

  data.forEach(payment => {
    const paymentAt = moment(payment.paymentAt).format('w/YY')
    const paymentPoint = points.find(point => point.x === paymentAt)
    if (paymentPoint) {
      paymentPoint.y += Number(payment.net)
    }
  })

  return points
}

const formatCountByWeek = data => {
  const points = []
  const startDate = moment('2019-08-12 00:00:00')
  const now = moment()

  while (now.diff(startDate, 'days') >= 0) {
    points.push({
      x: startDate.format('w/YY'),
      payments: 0,
      customers: 0,
    })
    startDate.add('7', 'day')
  }

  data.pastPayments.forEach(payment => {
    const paymentAt = moment(payment.paymentAt).format('w/YY')
    const paymentPoint = points.find(point => point.x === paymentAt)
    if (paymentPoint) {
      paymentPoint.payments += 1
    }
  })

  data.subscribers.forEach(subscriber => {
    const createdAt = moment(subscriber.createdAt).format('w/YY')
    const subscriberPoint = points.find(point => point.x === createdAt)
    if (subscriberPoint) {
      subscriberPoint.customers += 1
    }
  })

  return points
}

const formatLastMonthCounts = data => {
  const points = []
  const startDate = moment().subtract('1', 'month')
  const now = moment()

  while (now.diff(startDate, 'days') >= 0) {
    points.push({
      x: startDate.format('MM/DD'),
      payments: 0,
      customers: 0,
    })
    startDate.add('1', 'day')
  }

  data.pastPayments.forEach(payment => {
    const paymentAt = moment(payment.paymentAt).format('MM/DD')
    const paymentPoint = points.find(point => point.x === paymentAt)
    if (paymentPoint) {
      paymentPoint.payments += 1
    }
  })

  data.subscribers.forEach(subscriber => {
    const createdAt = moment(subscriber.createdAt).format('MM/DD')
    const subscriberPoint = points.find(point => point.x === createdAt)
    if (subscriberPoint) {
      subscriberPoint.customers += 1
    }
  })

  return points
}

const formatLastYearPayments = data => {
  const points = []
  const startDate = moment().subtract('1', 'year')
  const now = moment()

  while (now.diff(startDate, 'months') >= 0) {
    points.push({
      x: startDate.format('MM/YY'),
      y: 0,
    })
    startDate.add('1', 'month')
  }

  data.forEach(payment => {
    const paymentAt = moment(payment.paymentAt).format('MM/YY')
    const paymentPoint = points.find(point => point.x === paymentAt)
    if (paymentPoint) {
      paymentPoint.y += Number(payment.net)
    }
  })

  return points
}

const AdminPayments = props => {
  const {
    classes: css,
  } = props

  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.get('/users/admin-all-payments')
      .then(paymentInfo => {
        paymentInfo.data.payments = formatPaymentsByWeek(paymentInfo.data.pastPayments)
        paymentInfo.data.counts = formatLastMonthCounts(paymentInfo.data)
        paymentInfo.data.countsByWeek = formatCountByWeek(paymentInfo.data)
        paymentInfo.data.yearPayments = formatLastYearPayments(paymentInfo.data.pastPayments)
        setInfo(paymentInfo.data)
        setLoading(false)
      })
  }, [])

  if (loading) return <Loading fill />

  return (

    <div className={css.container}>
      <div className={css.total}>
        Total:&nbsp;
        {commas(info.totalAmount)}
        &nbsp;| Net:&nbsp;
        {commas(info.totalNet)}
      </div>
      <div className={css.chart}>
        <div className={css.title}>
          Amount per day
        </div>
        <Line
          data={{
            labels: info.counts.map(p => p.x),
            datasets: [{
              label: 'Customers',
              data: info.counts.map(p => p.customers),
              borderColor: '#7AD1EF',
              lineTension: 0.3,
            }, {
              label: 'Payments',
              data: info.counts.map(p => p.payments),
              borderColor: '#569684',
              lineTension: 0.3,
            }],
          }}
        />
      </div>
      <div className={css.chart}>
        <div className={css.title}>
          Amount per week
        </div>
        <Line
          data={{
            labels: info.countsByWeek.map(p => p.x),
            datasets: [{
              label: 'Customers',
              data: info.countsByWeek.map(p => p.customers),
              borderColor: '#7AD1EF',
              lineTension: 0.3,
            }, {
              label: 'Payments',
              data: info.countsByWeek.map(p => p.payments),
              borderColor: '#569684',
              lineTension: 0.3,
            }],
          }}
        />
      </div>
      <div className={css.chart}>
        <div className={css.title}>
          Total net per week
        </div>
        <Line
          data={{
            labels: info.payments.map(p => p.x),
            datasets: [{
              label: 'Net',
              data: info.payments.map(p => round(p.y, 2)),
              borderColor: '#569684',
              lineTension: 0.3,
            }],
          }}
        />
      </div>
      <div className={css.chart}>
        <div className={css.title}>
          Total net per month
        </div>
        <Line
          data={{
            labels: info.yearPayments.map(p => p.x),
            datasets: [{
              label: 'Net',
              data: info.yearPayments.map(p => round(p.y, 2)),
              borderColor: '#569684',
              lineTension: 0.3,
            }],
          }}
        />
      </div>
    </div>
  )
}

AdminPayments.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(AdminPayments)
