import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { CssBaseline } from '@material-ui/core'
import Loading from 'components/Loading'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import useNotify from 'hooks/useNotify'
import moment from 'moment'
import Button from 'components/elements/Button'
import theme from 'css/front'
import cn from 'classnames'

const useStyles = createUseStyles({
  container: {
    padding: 20,
  },
  table: {
    margin: '0 auto',
    width: 'calc(100% - 40px)',
    '& td': {
      borderBottom: '1px solid #ccc',
      padding: '10px 0 10px 5px',
    },
  },
  lectureInfo: {
    width: 200,
  },
  content: {
    padding: '0 20px',
    fontSize: 12,
  },
  buttons: {
    width: 190,
    '& > button': {
      marginRight: '10px',
    },
  },
  lectureTitle: {
    color: theme.colors.nuBlu,
  },
  date: {
    fontSize: 13,
  },
  approved: {
    borderLeft: `5px solid ${theme.colors.green}`,
  },
  rejected: {
    borderLeft: `5px solid ${theme.colors.red}`,
  },
})

const formatLectureTime = time => `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`

const AdminCorrections = () => {
  const css = useStyles()

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)

  const [loading, setLoading] = useState(true)
  const [corrections, setCorrections] = useState([])

  useEffect(() => {
    axios.get('/lectures/corrections')
      .then(res => {
        setCorrections(res.data)
        setLoading(false)
      })
      .catch(() => {
        notify('Could not load. Refresh the page.')
        setLoading(false)
      })
  }, [])

  const handleResponse = async (correctionId, isApproved) => {
    axios.post(`/lectures/corrections/review/${correctionId}`, {
      isApproved,
    })
    setCorrections(allCorrections => allCorrections.reduce((all, correction) => {
      if (correction.id === correctionId) {
        correction.isApproved = isApproved
        correction.isReviewed = true
      }
      all.push(correction)
      return all
    }, []))
  }

  const getReviewClass = correction => {
    if (!correction.isReviewed) return null
    return correction.isApproved ? css.approved : css.rejected
  }

  const renderCorrection = correction => (
    <tr>
      <td className={cn(css.lectureInfo, getReviewClass(correction))}>
        <div className={css.category}>
          {correction.Lecture.Categories[0].name}
        </div>
        <div className={css.lectureTitle}>
          <a
            href={`/lecture/${correction.Lecture.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            {correction.Lecture.name}
          </a>
        </div>
        <div className={css.date}>
          {'created on '}
          {moment(correction.created_at).format('MM/DD/YYYY')}
          {' by '}
          {correction.User.username}
        </div>
      </td>
      <td>
        <div className={css.content}>
          {
            correction.type === 'TALK'
            && (
              <span>
                [
                {formatLectureTime(correction.lectureTime)}
                ]
                {' '}
              </span>
            )
          }
          {correction.content}
        </div>
      </td>
      <td className={css.buttons}>
        <Button
          label="Reject"
          onClick={() => handleResponse(correction.id, false)}
          properties={['small']}
        />
        <Button
          label="Approve"
          onClick={() => handleResponse(correction.id, true)}
          properties={['small']}
        />
      </td>
    </tr>
  )

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div className={CssBaseline.container}>
      <table className={css.table}>
        {corrections.map(renderCorrection)}
      </table>
    </div>
  )
}

export default AdminCorrections
