import moment from 'moment'
import constants from './membershipConstants'

const stripeDateToDate = timestamp => moment(Number(`${timestamp}000`)).format('MMMM Do, YYYY')
const dbDateToDate = date => moment(date).format('MMMM Do, YYYY')
const getMembershipEndDate = (date, membershipType) => {
  if (!date) return null
  if (membershipType === 'trial') return dbDateToDate(date)
  return stripeDateToDate(date)
}

const initState = {
  loading: true,
  plan: null,
  canceledAt: null,
  nextPaymentAt: null,
}


export default (state = initState, { type, ...action }) => {
  switch (type) {
    case constants.LOAD:
      return {
        loading: false,
        ...action,
        nextPaymentAt: getMembershipEndDate(action.nextPaymentAt, action.membershipType),
        canceledAt: action.canceledAt ? stripeDateToDate(action.canceledAt) : null,
      }
    case constants.SET_LOADING:
      return {
        ...initState,
        loading: action.isLoading,
      }
    case constants.CLEAR:
      return { ...initState }
    default:
      return { ...state }
  }
}
