import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import theme from 'css/front'
import cn from 'classnames'

const useStyles = createUseStyles({
  inputContainer: {
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  label: {

  },
  input: {
    outline: 'none',
    border: `1px solid ${theme.colors.borderGray}`,
    fontSize: 14,
    padding: 5,
    width: '100%',
  },
  multiline: {
    height: 300,
  },
})

const SimpleInput = props => {
  const {
    type,
    label,
    name,
    multiline,
    value,
    onChange,
    placeholder,
    fullWidth,
  } = props

  const css = useStyles()

  const renderSingleLine = () => (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      className={css.input}
      onChange={event => onChange(name, event.target.value)}
    />
  )

  const renderMultiline = () => (
    <textarea
      type={type}
      placeholder={placeholder}
      name={name}
      className={cn(css.input, css.multiline)}
      onChange={event => onChange(name, event.target.value)}
    >
      {value}
    </textarea>
  )

  return (
    <div
      className={cn({
        [css.inputContainer]: true,
        [css.fullWidth]: fullWidth,
      })}
    >
      <div className={css.label}>
        {label}
      </div>
      { multiline ? renderMultiline() : renderSingleLine() }
    </div>
  )
}

SimpleInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
}

SimpleInput.defaultProps = {
  type: 'text',
  label: null,
  placeholder: '',
  fullWidth: true,
  multiline: false,
}

export default SimpleInput
