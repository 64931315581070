import React, { useState } from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import md5 from 'js-md5'
import moment from 'moment'
import cn from 'classnames'
import CommentInput from './CommentInput'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    borderBottom: `1px solid ${theme.colors.divider}`,
    marginTop: 5,
    padding: '45px 0 45px 15px',
    [theme.mobileOnly()]: {
      padding: '45px 0 45px 0',
    },
  },
  reply: {
    borderBottom: 'none',
    paddingLeft: 0,
    paddingBottom: 5,
  },
  avatar: {
    height: 70,
    width: 70,
    marginRight: 25,
    borderRadius: '50%',
    flexShrink: 0,
    [theme.mobileOnly()]: {
      height: 48,
      width: 48,
      marginRight: 10,
    },
  },
  replyAvatar: {
    height: 55,
    width: 55,
    [theme.mobileOnly()]: {
      height: 36,
      width: 36,
    },
  },
  header: {
    marginTop: 10,
    marginBottom: 5,
  },
  username: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: 12,
    fontSize: 16,
    color: theme.colors.textDarkGray,
    [theme.mobileOnly()]: {
      display: 'block',
      lineHeight: '12px',
    },
  },
  date: {
    fontSize: 13,
  },
  body: {
    position: 'relative',
    width: '100%',
  },
  commentText: {
    fontSize: 13,
    maxWidth: 'calc(100% - 100px)',
    [theme.mobileOnly()]: {
      maxWidth: 'calc(100% - 20px)',
    },
  },
  replyButton: {
    position: 'absolute',
    top: 10,
    right: 0,
    background: 'none',
    border: 'none',
    ...theme.components.title,
    fontWeight: '800',
    fontSize: 15,
    cursor: 'pointer',
    marginLeft: 'auto',
    '&:hover': {
      color: theme.colors.babyBlu,
    },
    '&:active': {
      color: theme.colors.nuBlu,
    },
  },
  footer: {
    marginTop: 10,
  },
}

const Comment = props => {
  const {
    lectureSlug,
    comment,
    classes: css,
    replies,
    allowReply,
  } = props

  const [showReply, setShowReply] = useState(false)

  const isReply = !!comment.parentId
  const username = comment.user ? comment.user.username : comment.name

  const handleShowReply = () => {
    setShowReply(true)
  }

  const handleHideReply = () => {
    setShowReply(false)
  }

  if (!username) {
    return null
  }

  return (
    <div
      className={cn({
        [css.container]: true,
        [css.reply]: isReply,
      })}
    >
      <img
        src={`https://www.gravatar.com/avatar/${md5(username)}?d=retro`}
        alt={username}
        className={cn({
          [css.avatar]: true,
          [css.replyAvatar]: isReply,
        })}
      />
      <div className={css.body}>
        <div className={css.header}>
          <span className={css.username}>{username}</span>
          <span className={css.date}>
            {moment(comment.postedAt).format('L')}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {moment(comment.postedAt).format('LT')}
          </span>
          {
            !isReply && !showReply && allowReply
            && (
              <button
                className={css.replyButton}
                onClick={handleShowReply}
                type="button"
              >
                Reply
              </button>
            )
          }
        </div>
        <div className={css.commentText}>
          {comment.comment}
        </div>
        {replies}
        <div className={css.footer}>
          {
            showReply
            && (
              <CommentInput
                lectureSlug={lectureSlug}
                parentId={comment.id}
                onHideReply={handleHideReply}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

Comment.propTypes = {
  comment: propTypes.object.isRequired,
  classes: propTypes.object.isRequired,
  lectureSlug: propTypes.string.isRequired,
  replies: propTypes.array,
  allowReply: propTypes.bool,
}

Comment.defaultProps = {
  replies: [],
  allowReply: true,
}

export default injectSheet(styles)(Comment)
