import React, { useState } from 'react'
import Modal from 'components/modals/FrontModal'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import theme from 'css/front'
import axios from 'axios'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useDispatch } from 'react-redux'
import useNotify from 'hooks/useNotify'

const styles = {
  modal: {
    width: '400px',
    margin: '10',
    [theme.max(450)]: {
      width: 'calc(100vw - 80px)',
    },
  },
  tab: {
    height: 35,
  },
  time: {
    marginTop: 10,
  },
  timeInput: {
    width: 80,
    marginRight: '15px !important',
  },
  textarea: {
    display: 'block',
    width: '100%',
    marginTop: 20,
    height: 100,
    resize: 'none',
    padding: 10,
    outline: 'none',
    fontSize: 14,
    ...theme.type.helvetica,
  },
  buttonRow: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitting: {
    color: theme.colors.nuBlu,
  },
}

const CorrectionsModal = props => {
  const {
    classes: css,
    handleClose,
  } = props

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)

  const [type, setType] = useState('talk')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')
  const [corrections, setCorrections] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const lecture = useSelector(state => state.lecture)

  const handleSubmit = async () => {
    if (type === 'talk' && minutes === '' && seconds === '') {
      notify('Fill in the time of the correction.', 'error')
      return
    }
    if (corrections === '') {
      notify('The correction cannot be blank.', 'error')
      return
    }

    setSubmitting(true)
    try {
      notify('Correction submitted')
      await axios.post(`/lectures/corrections/${lecture.id}`, {
        type,
        time: (Number(minutes) || 0) * 60 + (Number(seconds) || 0),
        corrections,
      })
      handleClose()
    } catch (e) {
      notify('There was an error submitting your correction. Please try again later.', 'error')
    }
    setSubmitting(false)
  }

  const renderTimeInput = () => (
    <div>
      <Input
        label="Minutes"
        placeholder="##"
        name="minutes"
        value={minutes}
        onChange={(name, val) => { setMinutes(val) }}
        className={css.timeInput}
        labelLockedOnTop
        format={{
          time: true,
          timePattern: ['m'],
        }}
      />
      <Input
        label="Seconds"
        placeholder="##"
        name="seconds"
        value={seconds}
        onChange={(name, val) => { setSeconds(val) }}
        className={css.timeInput}
        labelLockedOnTop
        format={{
          time: true,
          timePattern: ['s'],
        }}
      />
    </div>
  )

  return (
    <Modal
      open
      handleClose={handleClose}
      title="Suggest a correction"
    >
      <div className={css.modal}>
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={(e, val) => { setType(val) }}
          aria-label="text alignment"
        >
          <ToggleButton
            value="talk"
            aria-label="left aligned"
            className={css.tab}
          >
            Talk
          </ToggleButton>
          <ToggleButton
            value="notes"
            aria-label="right aligned"
            className={css.tab}
          >
            Notes
          </ToggleButton>
        </ToggleButtonGroup>
        <div className={css.time}>
          {
            type === 'talk'
            && (renderTimeInput())
          }
          <textarea
            className={css.textarea}
            placeholder="Correction"
            value={corrections}
            onChange={e => { setCorrections(e.target.value) }}
          />
        </div>
        <div className={css.buttonRow}>
          {
            submitting
              ? (
                <div className={css.submitting}>
                  Submitting
                </div>
              )
              : (
                <Button
                  className={css.button}
                  onClick={handleSubmit}
                  label="Submit"
                  properties={['small']}
                />
              )
          }
        </div>
      </div>
    </Modal>
  )
}

CorrectionsModal.propTypes = {
  classes: propTypes.object.isRequired,
  handleClose: propTypes.func.isRequired,
}

export default injectSheet(styles)(CorrectionsModal)
