import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { createUseStyles } from 'react-jss'
import theme from 'css/front'
import propTypes from 'prop-types'
import axios from 'axios'
import Loading from 'components/Loading'
import PageHeader from 'components/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import modalsActions from 'redux/modals/modalsActions'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { camelCase } from 'lodash'
import cn from 'classnames'
import FontAwesome from 'components/FontAwesome'
import imgPlay from 'images/play.svg'
import isMobileWidth from 'helpers/isMobileWidth'
import getLectureThumbnailLink from 'helpers/getLectureThumbnailLink'

const useStyles = createUseStyles({
  mobileBackToSubjects: {
    position: 'relative',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.type.notoSansJpBlack,
    fontWeight: 600,
    color: '#4C5158',
    borderBottom: '1px solid #EBEBEB',
    padding: 16,
    [theme.mobileOnly()]: {
      display: 'flex',
    },
  },
  navIcon: {
    color: '#BFC0BF',
    fontWeight: 100,
    position: 'absolute',
    top: '50%',
    marginTop: '-10px',
  },
  navIconLeft: {
    left: 10,
  },
  navIconRight: {
    right: 10,
  },
  categoryContainer: {
    display: 'flex',
  },
  chapter: {
    display: 'block',
    padding: 16,
    color: theme.colors.textGray,
    borderBottom: '1px solid #EBEBEB',
    fontSize: 14,
    [theme.mobileOnly()]: {
      textAlign: 'center',
    },
  },
  chapterContainer: {
    background: '#fff',
    width: 400,
    padding: '29px 55px',
    [theme.mobileOnly()]: {
      width: '100vw',
      padding: 0,
    },
  },
  mobilePlaylistNav: {
    background: '#F8F7F6',
    position: 'relative',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.type.notoSansJpBlack,
    color: '#4C5158',
    borderBottom: '1px solid #EBEBEB',
    padding: '16px 30px',
    width: '100vw',
    [theme.mobileOnly()]: {
      display: 'flex',
    },
  },
  chapterSelected: {
    color: `${theme.colors.nuBlu} !important`,
  },
  chapterNewLectures: {
    display: 'inline-block',
    background: '#007BBC',
    color: '#fff',
    padding: '2px 5px',
    fontSize: 10,
    borderRadius: '2px',
    marginLeft: 5,
  },
  playlistsContainer: {
    background: '#f1f1f1',
    width: 'calc(100% - 400px)',
    paddingBottom: 42,
    [theme.mobileOnly()]: {
      width: '100vw',
    },
  },
  playlist: {
    background: '#fff',
    margin: '42px 55px 0 55px',
    maxWidth: 990,
    border: '1px solid #e5e5e5',
    [theme.max(1212)]: {
      margin: '42px 26px 0 26px',
    },
    [theme.mobileOnly()]: {
      margin: 0,
      width: '100vw',
      border: 'none',
      borderBottom: '1px solid #e5e5e5',
    },
  },
  playlistTitle: {
    position: 'relative',
    display: 'flex',
    border: 'none',
    borderBottom: '1px solid #e5e5e5',
    background: '#fff',
    padding: 0,
    width: '100%',
    outline: 'none',
  },
  playlistIndex: {
    height: 54,
    width: 54,
    ...theme.type.notoSansJpBlack,
    color: '#fff',
    fontSize: 18,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    [theme.mobileOnly()]: {
      height: 62,
      width: 62,
    },
  },
  playlistName: {
    color: '#3A3C4E',
    fontSize: 18,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    height: 54, //todo make this 100%.
    [theme.mobileOnly()]: {
      height: 62, //todo make this 100%.
      fontSize: 14,
      color: '#6F748E',
    },
  },
  lectureContainer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(125px, 1fr) )',
    gridGap: '64px',
    padding: '35px 54px',
    [theme.max(1500)]: {
      gridGap: '32px',
      padding: '35px 54px',
    },
    [theme.max(1380)]: {
      gridTemplateColumns: 'repeat(4, minmax(125px, 1fr) )',
    },
    [theme.max(1212)]: {
      gridTemplateColumns: 'repeat(3, minmax(125px, 1fr) )',
    },
    [theme.max(500)]: {
      gridTemplateColumns: 'repeat(2, minmax(125px, 1fr) )',
      gridGap: 'auto',
    },
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  showAllLectures: {
    [theme.mobileOnly()]: {
      display: 'grid',
    },
  },
  newLecture: {
    position: 'absolute',
    background: '#007BBC',
    color: '#fff',
    padding: '5px',
    zIndex: '4',
    left: '-9px',
    top: '-9px',
    fontSize: 12,
    borderRadius: '2px',
  },
  lecture: {
    display: 'block',
    width: 125,
  },
  lectureImgContainer: {
    position: 'relative',
  },
  lectureImgOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(74,74,74,.25)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: 50,
      width: 50,
    },
  },
  lectureImg: {
    display: 'block',
    height: 125,
    width: 125,
    objectFit: 'cover',
  },
  lectureTitle: {
    color: '#4C5158',
    fontSize: 14,
    marginTop: 20,
  },
  hideMobile: {
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  watchedLecture: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(255, 255, 255, 0.85)',
    padding: '2px 8px',
    fontSize: 12,
    color: theme.colors.nuBlu,
    borderTop: `1px solid ${theme.colors.borderGray}`,
  },
})

const getChapter = (topCategory, chapterSlug) => {
  if (!chapterSlug) return null
  const chapter = topCategory.childCategories
    .find(childCategory => childCategory.slug === chapterSlug)

  return chapter || null
}

const Categories = props => {
  const {
    history,
    match: {
      params: {
        subjectSlug,
        chapterSlug,
      },
    },
    level,
  } = props

  const css = useStyles()

  const user = useSelector(state => state.user)

  const [loading, setLoading] = useState(true)
  const [topCategory, setTopCategory] = useState({})
  const [expandedPlaylistIds, setExplandedPlaylistIds] = useState([])
  const [watchedLectureIds, setWatchedLectureIds] = useState([])

  const dispatch = useDispatch()

  const explandPlaylist = playlistId => {
    setExplandedPlaylistIds(prevIds => [...prevIds, playlistId])
  }

  const loadCategoryFromUrl = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`/categories/slug-tree/${level}/${subjectSlug}`)
      setTopCategory(res.data)

      // on mobile show the chapters to the user first
      if (!chapterSlug && !isMobileWidth()) {
        history.replace(`/subject/${level === 'college' ? '' : `${level}/`}${subjectSlug}/${res.data.childCategories[0].slug}`)
      }
    } catch (e) {
      // history.replace('/404')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCategoryFromUrl().catch(console.error)
  }, [level, subjectSlug])

  useEffect(() => {
    if (user.id === 0) return
    // updated watched
    axios.get(`/categories/watched/${level}/${subjectSlug}/${chapterSlug}`)
      .then(res => {
        setWatchedLectureIds(res.data)
      })
      .catch(console.error)
  }, [level, subjectSlug, chapterSlug, user.id])

  const navigateChapters = goNext => {
    // close all expanded playlists
    setExplandedPlaylistIds([])

    const chapterIndex = topCategory.childCategories
      .findIndex(chapter => chapter.slug === chapterSlug)
    history.push(`/subject/${level === 'college' ? '' : `${level}/`}${subjectSlug}/${topCategory.childCategories[chapterIndex + (goNext ? 1 : -1)].slug}`)
  }

  const canNavigateChapters = goNext => {
    const chapterIndex = topCategory.childCategories
      .findIndex(chapter => chapter.slug === chapterSlug)
    const checkIndex = chapterIndex + (goNext ? 1 : -1)
    if (topCategory.childCategories.length - 1 < checkIndex || checkIndex < 0) return false
    return true
  }

  const handleBackToMobileChapters = () => {
    if (chapterSlug && isMobileWidth()) {
      history.push(`/subject/${level === 'college' ? '' : `${level}/`}${subjectSlug}`)
    }
  }

  const renderLecture = (playlist, lecture) => (
    <Link
      key={lecture.slug}
      to={`/lecture/${playlist.slug}/${lecture.slug}`}
      className={css.lecture}
    >
      <div className={css.lectureImgContainer}>
        {
          !!lecture.isNew
          && (
            <div className={css.newLecture}>New</div>
          )
        }
        {
          watchedLectureIds.includes(lecture.id)
          && (
            <div className={css.watchedLecture}>Watched</div>
          )
        }
        <div className={css.lectureImgOverlay}>
          <img src={imgPlay} alt="Play Lecture" />
        </div>
        <img
          className={css.lectureImg}
          src={getLectureThumbnailLink(lecture)}
          alt={lecture.name}
          loading="lazy"
        />
      </div>
      <div className={css.lectureTitle}>
        {lecture.name}
      </div>
    </Link>
  )

  const renderChapters = () => topCategory.childCategories.map(chapter => (
    <Link
      key={chapter.id}
      to={`/subject/${level === 'college' ? '' : `${level}/`}${subjectSlug}/${chapter.slug}`}
      className={cn({
        [css.chapter]: true,
        [css.chapterSelected]: chapter.slug === chapterSlug,
      })}
      // close all expanded playlists
      onClick={() => { setExplandedPlaylistIds([]) }}
    >
      {chapter.name}
      {
        chapter.hasNewLectures
        && (
          <span className={css.chapterNewLectures}>New</span>
        )
      }
    </Link>
  ))

  const renderPlaylists = () => getChapter(topCategory, chapterSlug)
    .childCategories.map((playlist, index) => (
      <div className={css.playlist} key={playlist.id}>
        <button
          className={css.playlistTitle}
          onClick={() => { isMobileWidth() && explandPlaylist(playlist.id) }}
          type="button"
        >
          <div
            className={css.playlistIndex}
            style={{ background: theme.colors[camelCase(topCategory.name)] }}
          >
            {index + 1}
          </div>
          <div className={css.playlistName}>
            {playlist.name}
          </div>
        </button>
        <div
          className={cn({
            [css.lectureContainer]: true,
            [css.showAllLectures]: expandedPlaylistIds.includes(playlist.id),
          })}
        >
          {playlist.lectures.map(lecture => renderLecture(playlist, lecture))}
        </div>
      </div>
    ))

  if (loading) return <Loading full />

  return (
    <>
      <Helmet>
        <title>
          {`AK Lectures - ${topCategory.name}`}
        </title>
      </Helmet>
      <PageHeader
        label="Playlists:"
        subject={topCategory.name}
        name={!!chapterSlug && !!getChapter(topCategory, chapterSlug) && getChapter(topCategory, chapterSlug).name}
        handleNextClick={() => { navigateChapters(true) }}
        showNextButton={!canNavigateChapters(true)}
        handleNameClick={handleBackToMobileChapters}
        buttonText="Next Playlist"
      />
      <div className={css.categoryContainer}>
        <div
          className={cn({
            [css.chapterContainer]: true,
            [css.hideMobile]: chapterSlug,
          })}
        >
          <div className={css.mobileBackToSubjects}>
            <FontAwesome
              className={cn(css.navIcon, css.navIconLeft)}
              name="faChevronLeft"
              handleClick={() => { dispatch(modalsActions.isOpen('subjects')) }}
            />
            CHAPTERS
          </div>
          {renderChapters()}
        </div>
        {
          !!chapterSlug && !!getChapter(topCategory, chapterSlug)
          && (
            <div className={css.playlistsContainer}>
              <div className={css.mobilePlaylistNav}>
                {
                  canNavigateChapters(false)
                  && (
                    <FontAwesome
                      className={cn(css.navIcon, css.navIconLeft)}
                      name="faChevronLeft"
                      handleClick={() => { navigateChapters(false) }}
                    />
                  )
                }
                {getChapter(topCategory, chapterSlug).name}
                {
                  canNavigateChapters(true)
                  && (
                    <FontAwesome
                      className={cn(css.navIcon, css.navIconRight)}
                      name="faChevronRight"
                      handleClick={() => { navigateChapters(true) }}
                    />
                  )
                }
              </div>
              {renderPlaylists()}
            </div>
          )
        }
      </div>
    </>
  )
}

Categories.propTypes = {
  match: propTypes.shape({
    params: propTypes.object.isRequired,
  }).isRequired,
  level: propTypes.string,
  history: propTypes.object.isRequired,
}

Categories.defaultProps = {
  level: 'college',
}


export default withRouter(Categories)
