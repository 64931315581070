import { useEffect } from 'react'
import { withRouter } from "react-router"
import userActions from 'redux/user/userActions'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const Logout = ({ history }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(userActions.logout())
    history.replace('/')
  })

  return null
}

Logout.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(Logout)
