import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'
import jss from 'jss'
import jssGlobal from 'jss-global'
import Home from 'containers/Home'
import Categories from 'containers/Categories'
import Lecture from 'containers/Lecture'
import Modals from 'containers/Modals'
import Purchase from 'containers/Purchase'
import Pricing from 'containers/Pricing'
import ThankYou from 'containers/ThankYou'
import ForgotPassword from 'containers/ForgotPassword'
import UserComments from 'containers/UserComments'
import Account from 'containers/Account'
import Logout from 'containers/Logout'
import Donate from 'containers/about/Donate'
import Team from 'containers/about/Team'
import Contact from 'containers/about/Contact'
import NotFound from 'containers/NotFound'
import injectSheet from 'react-jss'
import globalCss from 'css/global'
import AdminRoute from 'routes/AdminRoute'
import 'react-table/react-table.css'
import { bindActionCreators } from 'redux'
import userActions from 'redux/user/userActions'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import Notifications from 'components/Notifications'
import Loading from 'components/Loading'
import ReactGA from 'react-ga'
import axios from 'axios'
import storage from 'local-storage-fallback'
import packageJson from '../package.json'

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-41379872-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const { plans } = require('constants/index')

jss.setup({
  plugins: [jssGlobal()],
})

const globalStyles = {
  '@global': globalCss,
}

class App extends Component {
  static propTypes = {
    user: propTypes.shape({
      initLoading: propTypes.bool.isRequired,
    }).isRequired,
    userActions: propTypes.shape({
      loginToken: propTypes.func.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development' && window.location.protocol !== 'https:') {
      window.location = window.location.href.replace('http://', 'https://')
      return
    }

    axios.get(`${window.location.protocol}//${window.location.host}/meta.json?cb=${Date.now()}`).then(({ data }) => {
      console.log(data.version, packageJson.version)
      if (data.version !== packageJson.version) {
        if (typeof caches !== 'undefined') {
          caches.keys().then(names => {
            names.forEach(name => {
              caches.delete(name)
            })
          })
        }

        const safteyKey = `reload${packageJson.version}`
        const safteyReloadCounter = Number(storage.getItem(safteyKey) || 0) + 1
        storage.setItem(safteyKey, safteyReloadCounter)
        if (safteyReloadCounter < 2) {
          window.location.reload(true)
        }
      }
    })

    const { userActions: { loginToken } } = this.props
    loginToken()
  }

  isMedical = CategoriesComponent => props => (
    <CategoriesComponent
      {...props}
      level="medical"
    />
  )

  render() {
    const {
      user: {
        initLoading,
      },
    } = this.props


    if (initLoading) {
      return <Loading />
    }

    return (
      <Router>
        <div>
          <div style={{ minHeight: 'calc(100vh - 95px)' }}>
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/buffalo" component={Home} />
              <Route exact path="/buffalo-medical" component={Home} />
              <Route exact path="/subject/medical/:subjectSlug/:chapterSlug?" component={this.isMedical(Categories)} />
              <Route exact path="/subject/:subjectSlug/:chapterSlug?" component={Categories} />
              <Route exact path="/lecture/:playlist/:slug" component={Lecture} />
              <Route exact path="/lecture/:slug" component={Lecture} />
              <Route exact path="/user-comments" component={UserComments} />
              <Route exact path="/account" component={Account} />
              <Route exact path={`/purchase/:plan(${plans.map(plan => plan.name).join('|')})`} component={Purchase} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/reset-password/:resetToken" component={ForgotPassword} />
              <Route exact path="/donate" component={Donate} />
              <Route exact path="/team" component={Team} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/thank-you-monthly" component={ThankYou} />
              <Route exact path="/thank-you-quarterly" component={ThankYou} />
              <Route exact path="/thank-you-biannual" component={ThankYou} />
              <Route exact path="/thank-you-annual" component={ThankYou} />
              <Route exact path="/thank-you" component={ThankYou} />
              <Route exact path="/logout" component={Logout} />
              {/* Admin */}
              <Route exact path="/admin/*" component={AdminRoute} />
              {/* old route redirects */}
              <Route exact path="/donate.php" render={() => <Redirect to="/donate" />} />
              <Route exact path="/team.php" render={() => <Redirect to="/team" />} />
              <Route exact path="/contact.php" render={() => <Redirect to="/contact" />} />
              {/* 404 */}
              <Route exact component={NotFound} />
            </Switch>
          </div>
          <Footer />
          <Notifications />
          <Modals />
        </div>
      </Router>
    )
  }
}

const mapStateToProps = ({
  user,
}) => ({
  user,
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  injectSheet(globalStyles)(App),
)
