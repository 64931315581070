import constants from './modalsConstants'

const isOpen = (name, toggle = true) => ({
  type: constants.IS_OPEN,
  name,
  isOpen: toggle,
})

const close = () => ({
  type: constants.CLOSE,
})

export default {
  isOpen,
  close,
}
