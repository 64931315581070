import constants from './categoryConstants'

const initState = {
  loading: true,
}

const newCategory = {
  id: 0,
  name: '',
  slug: '',
  description: '',
  parents: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.LOAD:
      return {
        ...initState,
        ...action.category,
        loading: false,
      }
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    case constants.NEW:
      return {
        ...initState,
        ...newCategory,
        parents: [action.parentId],
        loading: false,
      }
    case constants.CLEAR:
      return {
        ...initState,
      }
    default:
      return state
  }
}
