import React, { useState, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import modalsActions from 'redux/modals/modalsActions'
import { useDispatch, useSelector } from 'react-redux'
import { memoize, camelCase } from 'lodash'
import { Link } from 'react-router-dom'
import theme from 'css/front'
import userActions from 'redux/user/userActions'
import cn from 'classnames'
import { subjects } from 'data/subjects'
import getSubjectImage from 'helpers/getSubjectImage'
import imgClose from 'images/close.svg'
import imgSearch from 'images/search.svg'
import imgLogin from 'images/login.svg'
import imgLeftArrow from 'images/left-arrow.svg'

const labelMap = {
  college: 'Science Lectures',
  medical: 'Medical Lectures',
}

const styles = {
  container: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    overflowX: 'auto',
    background: '#1B1D30',
  },
  containerBody: {
    maxHeight: 'calc(100vh - 172px)',
    overflow: 'auto',
  },
  menuRow: {
    color: '#fff',
    height: 86,
    borderTop: '1px solid #707070',
    fontSize: 19,
    ...theme.type.robotoLight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: 1,
  },
  header: {
    padding: '0 21px',
    borderTop: 'none',
    '& img': {
      flexShrink: 0,
    },
  },
  subMenuHeader: {
    ...theme.type.robotoBold,
    fontSize: 14,
    color: '#686975',
    letterSpacing: 3,
    marginBottom: -10,
  },
  subMenu: {
    borderTop: 'none',
    height: 50,
  },
  headerDivider: {
    width: '100%',
  },
  subject: {
    textTransform: 'capitalize',
    display: 'flex',
    borderTop: 'none',
  },
  subjectImg: {
    height: 36,
    width: 36,
    objectFit: 'contain',
    marginRight: 28,
    marginLeft: 28,
  },
  subjectName: {
    width: 200,
  },
  bottom: {
    position: 'absolute',
    bottom: '0',
    left: 0,
    right: 0,
    '& img': {
      marginRight: 11,
    },
  },
}

const MobileMenu = props => {
  const {
    classes: css,
    isOpen,
    handleClose,
  } = props

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const [page, setPage] = useState(null)

  useEffect(() => {
    setPage(null)
  }, [isOpen])

  const handleShowModal = memoize(modalName => () => {
    dispatch(modalsActions.isOpen(modalName))
    handleClose()
  })

  const logout = () => {
    dispatch(userActions.logout())
    handleClose()
  }

  const renderSubject = (subject, level) => (
    <Link
      to={`/subject/${level === 'medical' ? 'medical/' : ''}${subject.link}`}
      className={cn(css.menuRow, css.subject)}
      onClick={handleClose}
    >
      <img
        className={css.subjectImg}
        src={getSubjectImage(camelCase(subject.name))}
        alt={subject.name}
        loading="lazy"
      />
      <div className={css.subjectName}>
        {subject.name}
      </div>
    </Link>
  )

  const renderSubjects = level => (
    <>
      <div className={cn(css.menuRow, css.header)}>
        <img
          src={imgClose}
          alt="close"
          onClick={handleClose}
        />
        <div className={css.headerDivider} />
        <img
          src={imgLeftArrow}
          alt="back"
          onClick={() => { setPage(null) }}
        />
      </div>
      <div
        className={css.menuRow}
      >
        {labelMap[level]}
      </div>
      <div className={css.subjects}>
        {subjects[level].map(subject => renderSubject(subject, level))}
      </div>
    </>
  )

  const renderMainMenu = () => (
    <>
      <div className={cn(css.menuRow, css.header)}>
        <img
          src={imgClose}
          alt="close"
          onClick={handleClose}
        />
        <div className={css.headerDivider} />
        <img
          src={imgSearch}
          alt="search"
          onClick={handleShowModal('search')}
        />
      </div>
      <div className={css.containerBody}>
        <div
          className={css.menuRow}
          onClick={() => { setPage('college') }}
        >
          {labelMap.college}
        </div>
        <div
          className={css.menuRow}
          onClick={() => { setPage('medical') }}
        >
          {labelMap.medical}
        </div>
        <div className={cn(css.menuRow, css.subMenuHeader)}>
          ABOUT
        </div>
        <Link
          to="/team"
          className={cn(css.menuRow, css.subMenu)}
          onClick={handleClose}
        >
          The Team
        </Link>
        <a
          href="https://www.etsy.com/listing/458728846/short-sleeve-t-shirt-white"
          className={cn(css.menuRow, css.subMenu)}
          target="_blank"
          rel="noreferrer"
        >
          AK Shirts
        </a>
        <Link
          to="/donate"
          className={cn(css.menuRow, css.subMenu)}
          onClick={handleClose}
        >
          Donate
        </Link>
        <Link
          to="/contact"
          className={cn(css.menuRow, css.subMenu)}
          onClick={handleClose}
        >
          Contact Us
        </Link>
      </div>
      {
        user.id > 0
          ? (
            <div className={css.bottom}>
              <Link
                to="/account"
                className={css.menuRow}
                onClick={handleClose}
              >
                <img src={imgLogin} alt="login" />
                Account
              </Link>
              <div
                className={css.menuRow}
                onClick={logout}
              >
                Log out
              </div>
            </div>
          )
          : (
            <div
              className={cn(css.menuRow, css.bottom)}
              onClick={handleShowModal('login')}
            >
              <img src={imgLogin} alt="login" />
              Login / Register
            </div>
          )
      }
    </>
  )

  const renderBody = () => {
    if (['college', 'medical'].includes(page)) return renderSubjects(page)
    return renderMainMenu()
  }

  return (
    <Drawer
      anchor="top"
      open={isOpen}
      onClose={handleClose}
    >
      <div className={css.container}>
        {renderBody()}
      </div>
    </Drawer>
  )
}

MobileMenu.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool,
  handleClose: propTypes.func.isRequired,
}

MobileMenu.defaultProps = {
  isOpen: false,
}

export default injectSheet(styles)(MobileMenu)