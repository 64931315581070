import axios from 'axios'
import constants from './membershipConstants'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const clear = () => ({
  type: constants.CLEAR,
})

const load = (noCache = false) => async dispatch => {
  dispatch(loading(true))
  const res = await axios.get(`/users/current-subscription?no-cache=${noCache ? 1 : 0}`)
  dispatch({ type: constants.LOAD, ...res.data })
}

const setSubscription = subscription => ({
  type: constants.setSubscription,
  ...subscription,
})

export default {
  clear,
  load,
  setSubscription,
}
