import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import theme from 'css/front'
import cn from 'classnames'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { createUseStyles } from 'react-jss'
import Comment from 'components/lecture/Comment'
import notificationsActions from 'redux/notifications/notificationsActions'
import { useDispatch } from 'react-redux'
import Loading from 'components/Loading'

const useStyles = createUseStyles({
  container: {
    maxWidth: theme.sizes.containerWidth,
    margin: '0 auto',
    padding: '20px 0',
  },
  title: {
    ...theme.components.title,
    paddingLeft: 15,
  },
  comment: {
    padding: '15px 15px 0 0',
  },
  lectureLinkContainer: {
    paddingLeft: 15,
  },
  lectureLink: {
    color: theme.colors.nuBlu,
  },
  noComments: {
    padding: 15,
  },
})


const UserComments = props => {
  const [loading, setLoading] = useState(true)
  const [comments, setComments] = useState([])
  const [page, setPage] = useState(1)
  const [hasNextPage] = useState(false)

  const css = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(notificationsActions.setAsViewed())
  }, [])

  useEffect(() => {
    setLoading(true)
    axios.get(`comments/user-comments?page=${page}`)
      .then(res => {
        setComments(res.data)
        setLoading(false)
      })
  }, [page])

  const renderComment = comment => (
    <div key={comment.id} className={css.comment}>
      <div className={css.lectureLinkContainer}>
        <Link
          className={css.lectureLink}
          to={`/lecture/${comment.lecture.slug}`}
        >
          {comment.lecture.name}
        </Link>
      </div>
      <Comment
        comment={comment}
        lectureSlug={comment.lecture.slug}
        allowReply={false}
        replies={
          comment.replies.map(reply => (
            <Comment
              key={reply.id}
              comment={reply}
              allowReply={false}
              lectureSlug={comment.lecture.slug}
            />
          ))
        }
      />
    </div>
  )

  return (
    <div className={css.container}>
      <Helmet>
        <title>Latest comments</title>
      </Helmet>
      <div className={css.title}>
        Recent comment replies
      </div>
      {
        !loading && comments.length === 0
        && (
          <div className={css.noComments}>
            No replies to your recent comments
          </div>
        )
      }
      {
        loading
          ? <Loading />
          : comments.map(renderComment)
      }
    </div>
  )
}

export default UserComments
