import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import cssVars from 'css/variables'
import ModalCategory from 'components/admin/lectures/ModalCategory'
import CategoryList from 'components/admin/lectures/CategoryList'
import categoriesActions from 'redux/categories/categoriesActions'

const useStyles = createUseStyles({
  page: {
    display: 'flex',
    padding: cssVars.padding,
  },
})

const AdminCategories = () => {
  const css = useStyles()
  const dispatch = useDispatch()

  const modals = useSelector(state => state.modals)
  const {
    categories,
    loading: isCategoriesLoading,
  } = useSelector(state => state.categories)

  const [selected, setSelected] = useState([1])

  useEffect(() => {
    // load the categories
    dispatch(categoriesActions.load())
  }, [])

  const handleSelect = (showLectures, categoryId, index) => {
    setSelected(prevState => {
      const selectedCategories = prevState.slice(0, parseInt(index, 10) + 1)
      selectedCategories.push(categoryId)
      return selectedCategories
    })
  }

  const handleCategorySelect = (categoryId, index) => {
    handleSelect(false, categoryId, index)
  }

  if (isCategoriesLoading) return (<div>Loading...</div>)

  return (
    <div className={css.page}>
      <div className={css.page}>
        {selected.map((categoryId, index) => {
          const selectedCategory = categories[categoryId]

          const columnEl = (
            <CategoryList
              key={`column-${selectedCategory.id}`}
              categoryIndex={index}
              parentCategory={selectedCategory}
              handleCategorySelect={handleCategorySelect}
            />
          )
          return columnEl
        })}
      </div>
      {
        (modals.adminCategory || modals.adminLecture)
        && (
          <ModalCategory />
        )
      }
    </div>
  )
}

export default AdminCategories
