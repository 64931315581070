import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import cssVars from 'css/variables'
import PropTypes from 'prop-types'
import FontAwesome from 'components/FontAwesome'
import Input from 'components/elements/Input'
import Row from 'components/Row'
import modalsActions from 'redux/modals/modalsActions'
import lectureActions from 'redux/lecture/lectureActions'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/elements/Button'
import useNotify from 'hooks/useNotify'
import CategoryPicker from '../CategoryPicker'
import LectureSnapshotUpload from './LectureSnapshotUpload'

const useStyles = createUseStyles({
  goBack: {
    display: 'flex',
    cursor: 'pointer',
  },
  goBackIcon: {
    marginRight: 5,
  },
  input: {
    marginRight: cssVars.padding / 2,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  categoryRow: {
    width: '100%',
    background: cssVars.altColor1,
    borderBottom: cssVars.border,
    padding: cssVars.padding / 2,
    paddingLeft: cssVars.padding / 4,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    '& .categoryArrow': {
      margin: '0 5px',
      color: cssVars.darkGray,
    },
  },
  fullWidth: {
    marginTop: 10,
    width: '100%',
  },
  snapshotUploadContainer: {
    marginLeft: 'auto',
    display: 'flex',
  },
})

const LectureForm = props => {
  const {
    lecture,
    lectureEdit,
    handleValueChange,
  } = props

  const [selectedCategoryId, setSelectedCategoryid] = useState(null)

  const dispatch = useDispatch()
  const categories = useSelector(state => state.categories)

  const css = useStyles()

  const notify = useNotify(dispatch)

  const getValue = field => lectureEdit[field] || lecture[field] || ''

  const handleRemoveCategory = categoryId => {
    const parentCategories = getValue('Categories')
    if (parentCategories.length === 1) {
      notify('Lecture must belong to at least one category', 'error')
      return
    }
    handleValueChange('Categories', parentCategories.filter(category => category.id !== categoryId))
  }

  const handleAddCategory = () => {
    const parentCategories = getValue('Categories')
    parentCategories.push({
      id: Number(selectedCategoryId),
    })

    handleValueChange('Categories', parentCategories)
    setSelectedCategoryid(null)
  }

  const handleCategorySelect = categoryId => {
    setSelectedCategoryid(categoryId)
  }

  const handleBackToCategory = () => {
    dispatch(modalsActions.isOpen('adminCategory'))
    dispatch(lectureActions.clearLecture())
  }

  const getParentChain = parentId => {
    let parentCategory = categories.categories[parentId]
    const parentCategories = [parentCategory]

    while (parentCategory.parents.length > 0) {
      if (!categories.categories[parentCategory.parents[0]]) {
        break
      }
      parentCategory = categories.categories[parentCategory.parents[0]]
      parentCategories.push(parentCategory)
    }
    return parentCategories
  }

  const renderParent = parentChain => {
    if (parentChain.length < 2) return null

    return (
      <div className={css.categoryRow} key={parentChain[0].id}>
        <FontAwesome
          className="categoryArrow"
          name="faTimes"
          handleClick={() => handleRemoveCategory(parentChain[0].id)}
        />
        {
          parentChain
            .reduce((all, parentCategory) => {
              all.push(
                <div
                  key={parentCategory.id}
                >
                  {parentCategory.name}
                </div>,
              )
              all.push(
                <FontAwesome
                  key={`${parentCategory.id}-arrow`}
                  className="categoryArrow"
                  name="faChevronRight"
                />,
              )
              return all
            }, [])
            .slice(0, -3)
            .reverse()
        }
      </div>
    )
  }

  const renderParents = parentChains => parentChains.map(parentChain => renderParent(parentChain))

  if (lecture.loading) {
    return (
      <div>
        Loading lecture...
      </div>
    )
  }

  return (
    <>
      <Row>
        <div
          className={css.goBack}
          onClick={handleBackToCategory}
        >
          <FontAwesome
            name="faChevronLeft"
            className={css.goBackIcon}
          />
          Back to category
        </div>
        <div className={css.snapshotUploadContainer}>
          {
            ['snapshot', 'snapshot2', 'snapshot3'].map(name => (
              <LectureSnapshotUpload
                name={name}
                lectureId={lecture.id}
                lectureSlug={lecture.slug}
              />
            ))
          }
        </div>
      </Row>
      <Row>
        <Input
          name="name"
          value={getValue('name')}
          onChange={handleValueChange}
          fullWidth
        />
        <Input
          name="slug"
          value={getValue('slug')}
          onChange={handleValueChange}
          fullWidth
        />
      </Row>
      <Row>
        <Input
          name="description"
          value={getValue('description')}
          onChange={handleValueChange}
          fullWidth
          multiline
          rows={null}
        />
      </Row>
      <Row multiline>
        Parent category:
        {renderParents(getValue('Categories').map(parentLecture => getParentChain(parentLecture.id)))}
      </Row>
      <Row multiline>
        <CategoryPicker
          selectedCategoryId={selectedCategoryId}
          handleCategorySelect={handleCategorySelect}
        />
        <div className={css.fullWidth}>
          <Button
            label="Add to category"
            onClick={handleAddCategory}
            properties={['small']}
          />
        </div>
      </Row>
    </>
  )
}

LectureForm.propTypes = {
  lecture: PropTypes.object.isRequired,
  lectureEdit: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
}

export default LectureForm
