import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { createUseStyles } from 'react-jss'
import theme from 'css/front'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useStyles = createUseStyles({
  container: {
    padding: 20,
  },
  header: {
    display: 'flex',
    fontSize: 14,
  },
  breadCrumbs: {
    display: 'flex',
    fontSize: 13,
    '& > div': {
      marginRight: 5,
      paddingRight: 5,
      borderRight: '1px solid #ccc',
    },
  },
  user: {
    color: theme.colors.nuBlu,
    marginRight: 10,
  },
  comment: {
    borderBottom: '1px solid #ccc',
    marginBottom: 20,
    paddingBottom: 20,
    maxWidth: 800,
  },
  replies: {
    marginLeft: 20,
    '& > div': {
      paddingBottom: 0,
      marginTop: 15,
      borderBottom: 'none',
    },
  },
})

const AdminComments = () => {
  const css = useStyles()

  const [commentCreatedAtLT, setCommentCreatedAtLT] = useState(null)
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    axios.get('comments/admin/comments')
      .then(res => {
        setComments(res.data)
        setLoading(false)
      })
  }, [commentCreatedAtLT])

  const renderParents = categories => {
    const categoryList = []

    const getParents = category => {
      categoryList.push(category.name)
      if (category.parents && category.parents.length) {
        getParents(category.parents[0])
      }
    }
    getParents(categories[0])
    return categoryList.reverse().map(name => (
      <div>
        {name}
      </div>
    ))
  }

  const renderComment = comment => (
    <div className={css.comment} key={comment.id}>
      <div className={css.breadCrumbs}>
        { !!comment.lecture && renderParents(comment.lecture.Categories) }
      </div>
      {
        !!comment.lecture
        && (
        <div className={css.lecture}>
          <Link to={`/lecture/${comment.lecture.slug}`}>
            {comment.lecture.name}
          </Link>
        </div>
        )
      }
      <div className={css.header}>
        <div className={css.user}>
          {comment.user ? comment.user.username : comment.name}
        </div>
        <div>
          {moment(comment.postedAt).format('MM/DD/YYYY h:MMA')}
        </div>
      </div>
      <div>
        {comment.comment}
      </div>
      {
        !!comment.replies && !!comment.replies.length
          && (
            <div className={css.replies}>
              {comment.replies.map(renderComment)}
            </div>
          )
      }
    </div>
  )

  const renderComments = () => (
    <div>
      {comments.map(renderComment)}
    </div>
  )

  return (
    <div className={css.container}>
      {
        loading ? 'Loading...' : renderComments()
      }
    </div>
  )
}

export default AdminComments

