import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import { subjects } from 'data/subjects'
import getSubjectImage from 'helpers/getSubjectImage'

const styles = {
  container: {
    position: 'relative',
    margin: '20px auto',
  },
  textContainer: {
    width: '100vw',
    background: theme.colors.backgroundGray,
    display: 'flex',
    justifyContent: 'center',
    [theme.mobileOnly()]: {
      passing: '10px 20px',
    },
  },
  text: {
    ...theme.type.roboto,
    maxWidth: 650,
    textAlign: 'center',
    fontSize: 19,
    padding: 30,
    fontWeight: '100',
    color: theme.colors.textGray,
    lineHeight: '40px',
    [theme.max(500)]: {
      lineHeight: '30px',
    },
  },
  // subjects
  subjects: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(200px, 1fr) )',
    gridGap: '20px',
    maxWidth: 1000,
    margin: '20px auto 0 auto',
    padding: 20,
    [theme.max(917)]: {
      gridTemplateColumns: 'repeat(2, minmax(100px, 1fr) )',
      gridGap: '10px',
      padding: 10,
    },
  },
  subject: {
    position: 'relative',
    height: 120,
    textAlign: 'center',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: '#fff',
    },
  },
  subjectImg: {
    maxWidth: 60,
    maxHeight: 60,
  },
  subjectName: {
    position: 'absolute',
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 11,
    marginTop: 15,
  },
}

const ThankYou = props => {
  const {
    classes: css,
  } = props

  const renderSubject = subject => (
    <a
      href={`subject/medical/${subject.link}`}
      className={css.subject}
      key={subject.name}
      style={{ background: theme.colors[subject.image || subject.link] }}
    >
      <img
        className={css.subjectImg}
        src={getSubjectImage(subject.image || subject.link)}
        alt={subject.name}
      />
      <div className={css.subjectName}>{subject.name}</div>
    </a>
  )

  return (
    <div className={css.container}>
      <div className={css.textContainer}>
        <div className={css.text}>
          Thank you for your purchase! You now have full access to our library of 1,100+ videos geared specifically towards topics covered in medical school. Use them to learn the material needed to ace your classes, your boards and impress the attendings on the wards! Your purchase will help fund this website and bring more high yield topics in the near future! 
        </div>
      </div>
      <div className={css.subjects}>
        {subjects.medical.map(renderSubject)}
      </div>
    </div>
  )
}

ThankYou.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(ThankYou)
