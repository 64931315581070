import cssVars from 'css/variables'

export default {
  row: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: cssVars.altColor1,
    margin: 1,
    fontSize: 14,
    padding: cssVars.padding / 2,
    '&:nth-child(odd)': {
      background: '#fff',
    },
    cursor: 'default',
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    border: `1px solid ${cssVars.gray}`,
    color: cssVars.darkGray,
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 11,
    background: '#fff',
    marginLeft: 5,
    '&:hover': {
      color: cssVars.brand,
    },
    '&:active': {
      color: cssVars.darkGray,
    },
    '&.disabled': {
      color: 'transparent',
      background: 'none',
      borderColor: 'transparent',
    },
  },
}
