import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import axios from 'axios'
import { Link } from 'react-router-dom'
import moment from 'moment'
import modalsActions from 'redux/modals/modalsActions'

const styles = {
  subscription: {
    background: theme.colors.backgroundGray,
    padding: 20,
    fontSize: 16,
    display: 'flex',
    width: '100%',
    margin: '20px 20px 0 0',
  },
  subscriptionStatus: {
    color: theme.colors.nuBlu,
    textTransform: 'capitalize',
  },
  paymentLines: {
    margin: '20px 20px 0 0',
    width: '100%',
  },
  paymentLine: {
    display: 'flex',
    padding: 5,
    fontSize: 12,
    '&:nth-child(odd)': {
      background: theme.colors.backgroundGray,
    },
  },
  lineInfo: {
    marginLeft: 'auto',
    width: 150,
    display: 'flex',
  },
  paidDate: {
    marginLeft: 'auto',
  },
  nextPayment: {
    margin: '20px 20px 0 0',
    fontSize: 12,
  },
  cancelMembershipContainer: {
    margin: '20px 20px 0 0',
    display: 'flex',
    fontSize: 12,
    [theme.mobileOnly()]: {
      flexDirection: 'column',
    },
  },
  cancelMembership: {
    color: theme.colors.babyBlu,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.nuBlu,
    },
    '&:active': {
      color: theme.colors.babyBlu,
    },
  },
  hideDesktop: {
    ...theme.components.hideDesktop,
  },
  hideMobile: {
    ...theme.components.hideMobile,
  },
}

const Payments = props => {
  const {
    classes: css,
  } = props

  const dispatch = useDispatch()

  const [paymentLines, setPaymentLines] = useState([])

  const subscriptionPlan = useSelector(state => state.membership)
  
  useEffect(() => {
    axios.get('/users/payments')
      .then(paymentLinesRes => {
        setPaymentLines(paymentLinesRes.data)
      })
  }, [])

  const handleCancelMembership = () => {
    dispatch(modalsActions.isOpen('cancelMembership'))
  }

  const renderSubscription = () => (
    subscriptionPlan.plan
      ? (
        <div className={css.subscriptionStatus}>
          {subscriptionPlan.plan.split('-')[0]}
        </div>
      )
      : (
        <>
          <div className={css.subscriptionStatus}>
            Free&nbsp;
          </div>
          <Link to="/pricing">Learn More</Link>
        </>
      )
  )

  const renderPaymentLine = paymentLine => (
    <div className={css.paymentLine} key={paymentLine.paymentAt}>
      <div className={css.item}>
        {paymentLine.item}
      </div>
      <div className={css.lineInfo}>
        <div className={css.price}>
          $
          {paymentLine.amount}
        </div>
        <div className={css.paidDate}>
          {moment(paymentLine.paymentAt).format('MM/DD/YYYY')}
        </div>
      </div>
    </div>
  )

  const renderNextpayment = () => {
    let message = 'Loading subscription information...'

    if (!subscriptionPlan.loading) {
      if (!subscriptionPlan.nextPaymentAt) {
        return null
      }
      if (subscriptionPlan.membershipType === 'trial') {
        message = `Active until: ${subscriptionPlan.nextPaymentAt}`
      } else if (subscriptionPlan.canceledAt) {
        message = `Plan canceled on ${subscriptionPlan.canceledAt}. Active until ${subscriptionPlan.nextPaymentAt}`
      } else {
        message = `Next payment on: ${subscriptionPlan.nextPaymentAt}`
      }
    }
    return (
      <div className={css.nextPayment}>
        {message}
      </div>
    )
  }

  const renderCancelMembership = () => {
    if (
      subscriptionPlan.loading
      || subscriptionPlan.canceledAt
      || subscriptionPlan.membershipType === 'trial'
      || !subscriptionPlan.nextPaymentAt
    ) {
      return null
    }

    return (
      <div className={css.cancelMembershipContainer}>
        <div
          className={css.cancelMembership}
          onClick={handleCancelMembership}
        >
          Cancel membership
        </div>
        &nbsp;- Your account will continue to have medical access until&nbsp;
        {subscriptionPlan.nextPaymentAt}
        .
      </div>
    )
  }

  return (
    <div className={css.container}>
      <div className={css.subscription}>
        Subscription:&nbsp;
        {!subscriptionPlan.loading && renderSubscription()}
      </div>
      <div className={css.hideDesktop}>
        {renderCancelMembership()}
      </div>
      <div className={css.paymentLines}>
        {paymentLines.map(renderPaymentLine)}
      </div>
      {renderNextpayment()}
      <div className={css.hideMobile}>
        {renderCancelMembership()}
      </div>
    </div>
    
  )
}

Payments.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(Payments)
