import React, { useState } from 'react'
import injectSheet from 'react-jss'
import cssVars from 'css/variables'
import PropTypes from 'prop-types'
import listItemStyles from 'css/components/listItem'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import FontAwesome from 'components/FontAwesome'
import Button from 'components/elements/Button'

const styles = {
  body: {
    borderRight: `1px solid ${cssVars.gray}`,
    marginRight: cssVars.padding,
    width: 400,
    maxHeight: 500,
    overflow: 'auto',
  },
  lectureRow: {
    ...listItemStyles.row,
  },
  actions: {
    ...listItemStyles.actions,
  },
  icon: {
    ...listItemStyles.icon,
  },
  selected: {
    background: `${cssVars.selected} !important`,
  },
}

const Lectures = props => {
  const {
    handleSelect,
    selectedLectureId,
    classes: css,
    lectures,
    handleUpdateLectureOrder,
  } = props

  const [newLectureOrder, setNewLectureOrder] = useState([])

  const getLectures = () => (newLectureOrder.length ? newLectureOrder : lectures)

  const handleMove = (lectureId, direction) => {
    const updatedLectureOrder = [...getLectures()]
    const lectureIndex = updatedLectureOrder.findIndex(lecture => lecture.id === lectureId)

    if (direction === 'up' && lectureIndex === 0) return
    if (direction === 'down' && lectureIndex === updatedLectureOrder.length - 1) return

    const newIndex = lectureIndex + (direction === 'up' ? -1 : 1)
    const switchLecture = updatedLectureOrder[newIndex]

    updatedLectureOrder[newIndex] = updatedLectureOrder[lectureIndex]
    updatedLectureOrder[lectureIndex] = switchLecture

    // if the new order is the same as the old order then cancel the new order
    const newOrder = isEqual(
      lectures.map(lecture => lecture.id),
      updatedLectureOrder.map(lecture => lecture.id),
    )
      ? []
      : updatedLectureOrder
    setNewLectureOrder(newOrder)
  }

  const handleCancelNewOrder = () => {
    setNewLectureOrder([])
  }

  const handleSaveNewOrder = () => {
    handleUpdateLectureOrder(newLectureOrder)
  }

  const renderUpdateOrderButtons = () => (
    <>
      <div>
        <Button
          label="Cancel"
          onClick={handleCancelNewOrder}
          properties={['small']}
          className={css.columnTitleButtons}
        />
        <Button
          label="Save"
          type="primary"
          onClick={handleSaveNewOrder}
          properties={['small']}
          className={css.columnTitleButtons}
        />
      </div>
    </>
  )

  const renderLectureRow = (lecture, isSelected) => (
    <div
      key={lecture.id}
      className={classNames(css.lectureRow, {[css.selected]: isSelected})}
      onClick={() => { handleSelect(lecture.id) }}
    >
      {lecture.name}
      <div className={css.actions}>
        <div>
          <FontAwesome
            className={css.icon}
            name="faChevronUp"
            handleClick={() => { handleMove(lecture.id, 'up') }}
            stopPropagation
          />
          <FontAwesome
            className={css.icon}
            name="faChevronDown"
            handleClick={() => { handleMove(lecture.id, 'down') }}
            stopPropagation
          />
        </div>
      </div>
    </div>
  )

  


  if (getLectures().length === 0) {
    return <div />
  }

  return (
    <div className={css.body}>
      {!!newLectureOrder.length && renderUpdateOrderButtons()}
      {getLectures().map(lecture => renderLectureRow(lecture, selectedLectureId === lecture.id))}
    </div>
  )
}

Lectures.propTypes = {
  lectures: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedLectureId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  handleUpdateLectureOrder: PropTypes.func.isRequired,
}

Lectures.defaultProps = {
  selectedLectureId: null,
}

export default injectSheet(styles)(Lectures)
