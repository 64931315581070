import React from 'react'
import propTypes from 'prop-types'
import theme from 'css/front'
import { useSelector } from 'react-redux'
import PurchaseForm from 'components/purchase/PurchaseForm'
import { Elements, StripeProvider } from 'react-stripe-elements'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'

const styles = {

  container: {
    maxWidth: 500,
    margin: '20px auto',
    padding: 20,
    background: theme.colors.backgroundGray,
    [theme.max(520)]: {
      margin: 20,
    },
  },
}

const Subscribe = props => {
  const {
    classes: css,
  } = props

  const membership = useSelector(state => state.membership)

  const renderAlreadySubscribed = () => (
    <div>
      <div className={css.container}>
        You currently have an active plan until&nbsp;
        {membership.nextPaymentAt}
        .
        <br />
        <br />
        View it on&nbsp;
        <Link to="/account">your account</Link>
        &nbsp;page.
      </div>
    </div>
  )

  const renderSubscriptionForm = () => (
    <StripeProvider
      apiKey={
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_STRIPE_PUBLIC_TEST
          : process.env.REACT_APP_STRIPE_PUBLIC_LIVE
      }
    >
      <Elements>
        <PurchaseForm />
      </Elements>
    </StripeProvider>
  )

  return (
    <div className={css.container}>
      <div className={css.stripeForm}>
        {
          membership.plan
            ? renderAlreadySubscribed()
            : renderSubscriptionForm()
        }
      </div>
    </div>
  )
}

Subscribe.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(Subscribe)
