import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import userActions from 'redux/user/userActions'
import modalsActions from 'redux/modals/modalsActions'
import { connect } from 'react-redux'
import Modal from 'components/modals/FrontModal'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import Row from 'components/Row'
import theme from 'css/front'

const styles = {
  modal: {
    width: '400px',
    margin: '10',
    [theme.mobileOnly()]: {
      width: 'calc(90vw - 40px)',
    },
  },
  usernameTextField: {
    marginBottom: '10px',
  },
  errorMessage: {
    color: '#800',
    fontSize: '14px',
    marginTop: '10px',
  },
  forgotPasswordContainer: {
    display: 'flex',
  },
  forgotPassword: {
    fontSize: '13px',
    marginTop: '5px',
    marginLeft: 'auto',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.babyBlu,
    },
  },
  buttonRow: {
    padding: '10px',
  },
  register: {
    marginTop: '40px',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  registerLink: {
    color: theme.colors.nuBlu,
    cursor: 'pointer',
  },
}

class Login extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    userActions: PropTypes.object.isRequired,
    modalsActions: PropTypes.shape({
      isOpen: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
  }

  state = {
    username: '',
    password: '',
    error: '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleLogin = () => {
    const {
      username,
      password,
    } = this.state
    const {
      userActions: {
        login,
      },
    } = this.props
    login(username, password)
  }

  submitOnEnter = e => {
    if (e.key === 'Enter') {
      this.handleLogin()
    }
  }

  handleRegister = () => {
    const {
      modalsActions: {
        isOpen,
      },
    } = this.props

    isOpen('register')
  }

  handleForgotPassword = () => {
    const {
      modalsActions: {
        isOpen,
      },
    } = this.props

    isOpen('forgotPassword')
  }

  render() {
    const {
      handleClose,
      classes: css,
    } = this.props

    const {
      username,
      password,
      error,
    } = this.state

    return (
      <Modal
        open
        handleClose={handleClose}
        title="Sign In"
      >
        <div className={css.modal}>
          <TextField
            className={css.usernameTextField}
            label="Username"
            autoFocus
            value={username}
            onChange={this.handleChange('username')}
            onKeyDown={this.submitOnEnter}
            fullWidth
          />
          <TextField
            label="Password"
            value={password}
            onChange={this.handleChange('password')}
            type="password"
            onKeyDown={this.submitOnEnter}
            fullWidth
          />
          <div className={css.forgotPasswordContainer}>
            <div
              className={css.forgotPassword}
              onClick={this.handleForgotPassword}
            >
              Forgot password?
            </div>
          </div>
          
          <Row
            center
            className={css.buttonRow}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleLogin}
            >
                Login
            </Button>
          </Row>
          
          <div className={css.errorMessage}>
            {error}
          </div>

          <div className={css.register}>
            Looking to&nbsp;
            <div
              className={css.registerLink}
              onClick={this.handleRegister}
            >
              create an account
            </div>
            ?
          </div>

        </div>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  user,
}) => ({
  user,
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch),
  modalsActions: bindActionCreators(modalsActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  injectSheet(styles)(Login),
)
