import React from 'react'
import Modal from '@material-ui/core/Modal'
import cssVars from 'css/variables'
import injectSheet from 'react-jss'
import FontAwesome from 'components/FontAwesome'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import modalsActions from 'redux/modals/modalsActions'

const styles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    background: '#fff',
    outline: 'none',
    width: `calc(100vw - ${cssVars.padding * 2}px)`,
    height: `calc(100vh - ${cssVars.padding * 2}px)`,
    margin: cssVars.padding,
    overflowY: 'auto',
  },
  header: {
    padding: cssVars.padding,
    borderBottom: `1px solid ${cssVars.gray}`,
    display: 'flex',
  },
  actions: {
    marginLeft: 'auto',
    color: cssVars.darkGray,
  },
  body: {
    position: 'relative',
    padding: cssVars.padding,
    minHeight: 'calc(100vh - 103px)',
    paddingBottom: 77,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: cssVars.padding,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
}

const mapDispatchToProps = dispatch => ({
  modalsActions: bindActionCreators(modalsActions, dispatch),
})

const mapStateToProps = ({ modals }) => ({ modals })

class ModalWrapper extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    buttons: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    modalClass: PropTypes.string,
    modalsActions: PropTypes.shape({
      close: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    title: '',
    modalClass: '',
    handleClose: null,
  }

  static handleCancelPropagation(e) {
    e.stopPropagation()
  }

  handleClose = () => {
    const {
      handleClose,
      modalsActions: {
        close,
      },
    } = this.props

    if (handleClose) {
      handleClose()
    } else {
      close()
    }
  }

  render() {
    const {
      classes: css,
      modalClass,
      isOpen,
      title,
      children,
      buttons,
    } = this.props

    const modalClasses = [css.modal]
    if (modalClass) {
      modalClasses.push(modalClass)
    }

    return (
      <Modal
        open={isOpen}
        onClick={this.handleClose}
        className={css.overlay}
      >
        <div
          className={classNames(modalClasses)}
          onClick={ModalWrapper.handleCancelPropagation}
        >
          <div className={css.header}>
            <div className={css.title}>
              {title || ''}
            </div>
            <div className={css.actions}>
              <FontAwesome
                name="faTimes"
                handleClick={this.handleClose}
              />
            </div>

          </div>
          <div className={css.body}>
            {children}
            <div className={css.buttons}>
              {buttons}
            </div>
          </div>
          
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(ModalWrapper))
