import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'components/modals/FrontModal'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import theme from 'css/front'
import axios from 'axios'
import modalsActions from 'redux/modals/modalsActions'
import alertActions from 'redux/alert/alertActions'
import cn from 'classnames'
import validator from 'validator'

const styles = {
  modal: {
    width: '400px',
    margin: '10',
    [theme.max(450)]: {
      width: 'calc(100vw - 80px)',
    },
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  backToLoginContainer: {
    display: 'flex',
  },
  backToLogin: {
    fontSize: '13px',
    marginTop: '5px',
    marginLeft: 'auto',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.babyBlu,
    },
  },
}

const ForgotPasswordModal = props => {
  const {
    classes: css,
    handleClose,
  } = props

  const [email, setEmail] = useState('')
  const dispatch = useDispatch()

  const handleEmailUpdate = (attr, val) => {
    setEmail(val)
  }

  const handleBackToLogin = () => {
    dispatch(modalsActions.isOpen('login'))
  }

  const handleResetPassword = async () => {
    if (!validator.isEmail(email)) {
      dispatch(alertActions.create('Please enter a valid email', 'error'))
      return
    }

    try {
      await axios.post('users/reset-password', {
        email,
      })
      dispatch(alertActions.create('Please check your email for the next steps.'))
    } catch (err) {
      dispatch(alertActions.create(err.response.data.message, 'error'))
    }
  }



  return (
    <Modal
      open
      handleClose={handleClose}
      title="Forgot Password"
    >
      <div className={css.modal}>
        <div className={css.info}>
          Enter your email to reset your password.
        </div>
        <div className={css.row}>
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            label=""
            onChange={handleEmailUpdate}
            fullWidth
            autoFocus
          />
        </div>
        <div className={css.backToLoginContainer}>
          <div
            className={css.backToLogin}
            onClick={handleBackToLogin}
          >
            Back to login
          </div>
        </div>
        
        <div className={cn(css.row, css.center)}>
          <Button
            onClick={handleResetPassword}
            label="Reset"
          />
        </div>
        
      </div>
    </Modal>
  )
}

ForgotPasswordModal.propTypes = {
  classes: propTypes.object.isRequired,
  handleClose: propTypes.func.isRequired,
}

export default injectSheet(styles)(ForgotPasswordModal)
