import React, { Component } from 'react'
import injectSheet from 'react-jss'
import ReactTable from 'react-table'
import cssVars from 'css/variables'
import moment from 'moment'
import usersActions from 'redux/users/usersActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import propTypes from 'prop-types'
import Input from 'components/elements/Input'
import { debounce, memoize } from 'lodash'

const styles = {
  page: {
    height: 'calc(100vh - 180px)',
    padding: cssVars.padding,
    fontSize: 14,
  },
  wrap: {
    whiteSpace: 'normal',
  },
  searchContainer: {
    marginBottom: 20,
  },
}


class AdminUsers extends Component {
  static propTypes = {
    classes: propTypes.object.isRequired,
    usersActions: propTypes.shape({
      load: propTypes.func.isRequired,
    }).isRequired,
    users: propTypes.shape({
      users: propTypes.array.isRequired,
      loading: propTypes.bool.isRequired,
    }).isRequired,
    history: propTypes.object.isRequired,
  }

  state = {
    searchVal: '',
    search: '',
  }

  handleSearch = debounce(val => {
    this.setState({
      search: val,
    })
  }, 400)

  searchUsers = memoize(term => {
    const {
      users: {
        users,
      },
    } = this.props

    return users.filter(user => [
      'username',
      'email',
      'bio',
      'location',
      'occupation',
      'school',
    ]
      .map(field => user[field])
      .join(' ')
      .toLowerCase()
      .search(term.toLowerCase()) > -1)
  })

  componentDidMount() {
    const { usersActions: { load } } = this.props
    load()
  }

  handleUpdateSearch = (attr, val) => {
    this.setState({
      searchVal: val,
    })

    this.handleSearch(val)
  }

  renderCreatedAt = node => moment(node.cellData).format('MM/DD/YY h:mma')


  getTableColumns = () => {
    const { classes: css } = this.props
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        id: 'info',
        Header: 'info',
        accessor: props => (
          <div className={css.wrap}>
            {
              ['name', 'location', 'school', 'occupation', 'bio']
                .filter(field => props[field])
                .map(field => (
                  <div>
                    <b>{field}</b>
                    {' '}
                    {props[field]}
                  </div>
                ))
            }
          </div>
        ),
      },
      {
        id: 'lastPayment',
        Header: 'Last Payment',
        accessor: props => (
          props.Payments.length
            ? moment(props.Payments[0].paymentAt).format('X')
            : null
        ),
        sortMethod: (a, b) => a - b,
        Cell: props => (
          props.original.Payments.length
            ? moment(props.original.Payments[0].paymentAt).format('MM/DD/YY h:mma')
            : null
        ),
      },
      {
        id: 'trialEnd',
        Header: 'Trial ends',
        accessor: props => (
          props.SubscriptionTrials.length
            ? moment(props.SubscriptionTrials[0].trialEnd).format('X')
            : null
        ),
        sortMethod: (a, b) => a - b,
        Cell: props => (
          props.original.SubscriptionTrials.length
            ? moment(props.original.SubscriptionTrials[0].trialEnd).format('MM/DD/YY')
            : null
        ),
      },
      {
        id: 'trialName',
        Header: 'Trial Name',
        accessor: props => (
          props.SubscriptionTrials.length
            ? props.SubscriptionTrials[0].planName
            : null
        ),
      },
      {
        id: 'created',
        Header: 'Created',
        accessor: props => moment(props.createdAt).format('X'),
        sortMethod: (a, b) => a - b,
        Cell: props => moment(props.original.createdAt).format('MM/DD/YY h:mma'),
      },

    ]
  }

  getUserData = () => {
    const {
      users: {
        users,
      },
    } = this.props

    const {
      search,
    } = this.state

    if (search === '') return users

    return this.searchUsers(search)
  }

  handleGoToUser = userId => {
    const {
      history,
    } = this.props
    history.push(`/admin/user/${userId}`)
  }

  render() {
    const {
      classes: css,
    } = this.props

    const {
      searchVal,
    } = this.state

    return (
      <div className={css.page}>
        <div className={css.searchContainer}>
          <Input
            name="search"
            value={searchVal}
            label="Search"
            onChange={this.handleUpdateSearch}
            fullWidth
          />
        </div>
        <ReactTable
          data={this.getUserData()}
          columns={this.getTableColumns()}
          getTdProps={(state, rowInfo) => ({
            onClick: () => {
              this.handleGoToUser(rowInfo.original.id)
            },
          })}
          defaultPageSize={50}
          defaultSorted={[
            {
              id: 'created',
              desc: true,
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  users,
}) => ({
  users,
})

const mapDispatchToProps = dispatch => ({
  usersActions: bindActionCreators(usersActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  injectSheet(styles)(AdminUsers),
)
