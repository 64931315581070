import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import commentsActions from 'redux/comments/commentsActions'
import Loading from 'components/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { camelCase } from 'lodash'
import Comment from './Comment'
import CommentInput from './CommentInput'
import modalsActions from 'redux/modals/modalsActions'

const styles = {
  commentsContainer: {
    maxWidth: theme.sizes.containerWidth,
    margin: '10px 60px 25px 70px',
    paddingTop: 70,
    [theme.mobileOnly()]: {
      margin: '45px 30px 0 30px',
      paddingTop: 0,
    },
  },
  noComments: {
    padding: '20px 0',
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    maxWidth: 400,
    border: '1px solid #ccc',
    resize: 'none',
    padding: 10,
    outline: 'none',
    marginBottom: 10,
    fontSize: 14,
  },
  inputSubmit: {
    width: 70,
  },
  loadMore: {
    background: theme.colors.backgroundGray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    fontSize: 12,
    margin: '45px 0 30px 0',
    cursor: 'pointer',
    color: '#fff',
    fontWeight: 900,
  },
  reportError: {
    display: 'inline-block',
    color: theme.colors.nuBlu,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.babyBlu,
    },
    '&:click': {
      color: theme.colors.nuBlu,
    },
  },
}

const CommentContainer = props => {
  const {
    lecture,
    classes: css,
  } = props

  const dispatch = useDispatch()

  const comments = useSelector(state => state.comments)
  const user = useSelector(state => state.user)

  useEffect(() => {
    dispatch(commentsActions.loadLecture({ lectureSlug: lecture.slug }))
  }, [lecture.slug])

  const loadMore = () => {
    dispatch(commentsActions.loadLecture({
      lectureSlug: lecture.slug,
      page: Math.floor(comments.comments.length / 20) + 1,
      clearLectures: false,
    }))
  }

  const handleReportErrorClick = () => {
    dispatch(modalsActions.isOpen('corrections'))
  }

  const renderNoComments = () => (
    <div className={css.noComments}>
      This lecture doesn't have any comments yet
    </div>
  )

  const renderLoadMore = () => (
    <div
      className={css.loadMore}
      style={{ background: theme.colors[camelCase(lecture.categories[0].slug)] }}
      onClick={loadMore}
    >
      LOAD MORE COMMENTS
    </div>
  )

  const renderComment = comment => {
    const replies = comment.replies ? comment.replies.map(renderComment) : []
    return (
      <Comment
        key={comment.id}
        comment={comment}
        lectureSlug={lecture.slug}
        replies={replies}
      />
    )
  }

  if (comments.loading) {
    return (
      <div className={css.commentsContainer}>
        <Loading fill />
      </div>
    )
  }

  return (
    <div className={css.commentsContainer}>
      { comments.comments.length === 0 && renderNoComments() }
      <CommentInput
        lectureSlug={lecture.slug}
      />
      {
        user.id > 0
        && (
          <div
            className={css.reportError}
            onClick={handleReportErrorClick}
          >
            Report an error in the lecture
          </div>
        )
      }
      { comments.comments.map(renderComment) }
      { comments.comments.length > 0 && comments.commentCount > comments.comments.length && renderLoadMore() }
    </div>
  )
}

CommentContainer.propTypes = {
  lecture: propTypes.object.isRequired,
  classes: propTypes.object.isRequired,
  commentsActions: propTypes.shape({
    loadLecture: propTypes.func.isRequired,
  }).isRequired,
}

export default injectSheet(styles)(CommentContainer)
