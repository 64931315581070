import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import cssVars from 'css/variables'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import PropTypes from 'prop-types'
import cn from 'classnames'

const styles = {
  modalDefault: {
    outline: 'none',
    top: '50%',
    left: '50%',
    position: 'absolute',
    padding: '40px 20px 20px 20px',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90vw',
  },
  header: {
    padding: cssVars.halfPadding,
    minWidth: '100%',
    width: 'auto',
  },
}

class ModalWrapper extends PureComponent {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
  }

  static defaultProps = {
    title: '',
    className: '',
    children: <div />,
  }

  render() {
    const {
      classes: css,
      handleClose,
      title,
      children,
      className,
    } = this.props

    return (
      <Modal
        open
        onClose={handleClose}
      >
        <Paper
          elevation={1}
          className={cn(css.modalDefault, className)}
        >

          {
            title !== ''
              && (
                <AppBar
                  elevation="0"
                  color="default"
                  className={css.header}
                >
                  {title}
                </AppBar>
              )
          }
          {children}
        </Paper>
      </Modal>
    )
  }
}


export default injectSheet(styles)(ModalWrapper)