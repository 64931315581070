import React from 'react'
import injectSheet from 'react-jss'
import theme from 'css/front'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import modalsActions from 'redux/modals/modalsActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const styles = {
  container: {
    margin: '40px 20px 20px 20px',
    padding: 50,
    background: theme.colors.backgroundGray,
    [theme.mobileOnly()]: {
      margin: 20,
      padding: '30px 10px',
    },
  },
  title: {
    fontSize: 50,
    textAlign: 'center',
    color: theme.colors.nuBlu,
    [theme.mobileOnly()]: {
      fontSize: 30,
    },
  },
  subtitle: {
    fontSize: 20,
    textAlign: 'center',
    marginTop: 20,
  },
  options: {
    textAlign: 'center',
    marginTop: 10,
  },
}

const NotFound = props => {
  const {
    classes: css,
  } = props

  const openSearch = () => {
    const {
      modalsActions: {
        isOpen,
      },
    } = props

    isOpen('search')
  }

  return (
    <div className={css.container}>
      <div className={css.title}>
        Page not found
      </div>
      <div className={css.subtitle}>
        We couldn't find the page you're looking for.
      </div>
      <div className={css.options}>
        Try
        &nbsp;
        <Link to="#" onClick={openSearch}>searching</Link>
        &nbsp;
        for the lecture or going
        &nbsp;
        <Link to="/">home</Link>
        .
      </div>
    </div>
  )
}

NotFound.propTypes = {
  classes: propTypes.object.isRequired,
  modalsActions: propTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  modalsActions: bindActionCreators(modalsActions, dispatch),
})

const mapStateToProps = () => {}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectSheet(styles)(NotFound),
)
