import React, { useState, useMemo, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import cssVars from 'css/variables'
import Button from 'components/elements/Button'
import modalsActions from 'redux/modals/modalsActions'
import notificationsActions from 'redux/notifications/notificationsActions'
import PropTypes from 'prop-types'
import theme from 'css/front'
import { memoize } from 'lodash'
import imgLogo from 'images/logo-white.svg'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import cn from 'classnames'
import imgSearch from 'images/search.svg'
import imgHamburger from 'images/hamburger.svg'
import MobileMenu from './MobileMenu'

const styles = {
  header: {
    padding: cssVars.padding,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  floatingHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  staticHeader: {
    background: theme.colors.backgroundDarkest,
  },
  logoContainer: {
    overflow: 'hidden',
    transition: 'width .2s',
  },
  logo: {
    width: 174,
  },
  menuItemContainer: {
    display: 'flex',
    width: 250,
    [theme.max(750)]: {
      display: 'none',
    },
  },
  mobileMenuItemContainer: {
    display: 'none',
    [theme.max(750)]: {
      display: 'block',
    },
  },
  menuButtonContainer: {
    position: 'relative',
  },
  hamburger: {
    color: theme.colors.textGray,
    fontSize: 20,
  },
  menuItem: {
    marginLeft: cssVars.halfPadding,
    textDecoration: 'none',
    cursor: 'default',
    display: 'flex',
    '& span': {
      color: '#fff !important',
      textTransform: 'none',
    },
  },
  adminMenu: {
    display: 'flex',
    alignItems: 'center',
    padding: cssVars.halfPadding,
    borderBottom: `${cssVars.gray} 1px solid`,
  },
  search: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.nuBlu,
    },
    '&:active': {
      color: '#fff',
    },
  },
  icon: {
    height: 20,
  },
  dropDownMenuItem: {
    height: 'auto !important',
    padding: '0 !important',
    '& > div': {
      width: '120px !important',
    },
    '&  button': {
      width: '120px !important',
      justifyContent: 'left !important',
      padding: '0 0 0 16px !important',
      minHeight: 'auto !important',
      '&:hover': {
        background: 'none !important',
      },
    },
    '& a': {
      display: 'block',
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      width: '120px !important',
      '& span': {
        color: `${theme.colors.textGray} !important`,
      },
    },
  },
  badge: {
    position: 'absolute',
    top: 5,
    right: 5,
    height: 10,
    width: 10,
    background: theme.colors.badge,
    borderRadius: '50%',
  },
}

let notificationCheckCounter = 1

const Header = props => {
  const {
    classes: css,
  } = props
  const dispatch = useDispatch()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [openDropDownMenu, setOpenDropDownMenu] = useState({
    name: null,
    parentEl: null,
  })

  const user = useSelector(state => state.user)
  const notifications = useSelector(state => state.notifications)
  const isHeaderFloat = useSelector(state => state.uiFlags.isHeaderFloat)

  const notificationTimeout = useRef(null)

  const getNotifications = () => {
    notificationCheckCounter += 1
    dispatch(notificationsActions.load())
    setTimeout(() => {
      getNotifications()
    }, Math.log(notificationCheckCounter) * 60 * 1000)
  }

  useEffect(() => {
    if (user.id > 0) {
      getNotifications()
    } else if (notificationTimeout.current !== null) {
      clearTimeout(notificationTimeout.current)
    }
    return () => {
      if (notificationTimeout.current === null) return
      clearTimeout(notificationTimeout.current)
    }
  }, [user.id])

  const menu = {
    sciences: {
      name: 'SCIENCES',
      type: 'modal',
      modal: 'subjectsScience',
    },
    medical: {
      name: 'MEDICAL',
      type: 'modal',
      modal: 'subjectsMedical',
    },
    about: {
      name: 'About',
      type: 'dropDown',
      subMenu: [
        {
          name: 'Team',
          type: 'link',
          link: '/team',
        },
        {
          name: 'Shirts',
          type: 'external',
          link: 'https://www.etsy.com/listing/458728846/short-sleeve-t-shirt-white?ref=shop_home_active_1',
        },
        {
          name: 'Donate',
          type: 'link',
          link: '/donate',
        },
        {
          name: 'Contact',
          type: 'link',
          link: '/contact',
        },
      ],
    },
    user: useMemo(() => (
      user.id > 0
        ? ({
          name: user.username,
          type: 'dropDown',
          badge: notifications.hasNew,
          subMenu: [
            {
              name: 'Comments',
              type: 'link',
              link: '/user-comments',
              badge: notifications.hasNew,
            },
            {
              name: 'Account',
              type: 'link',
              link: '/account',
            },
            {
              name: 'Logout',
              type: 'link',
              link: '/logout',
            },
          ].concat(
            user.role === 'admin'
              ? [
                {
                  name: 'Lectures',
                  type: 'link',
                  link: '/admin/lectures',
                },
                {
                  name: 'All Comments',
                  type: 'link',
                  link: '/admin/comments',
                },
                {
                  name: 'Actions',
                  type: 'link',
                  link: '/admin/actions',
                },
                {
                  name: 'Users',
                  type: 'link',
                  link: '/admin/users',
                },
                {
                  name: 'Trials',
                  type: 'link',
                  link: '/admin/trials',
                },
                {
                  name: 'Payments',
                  type: 'link',
                  link: '/admin/payments',
                },
                {
                  name: 'Corrections',
                  type: 'link',
                  link: '/admin/corrections',
                },
              ]
              : [],
          ),
        })
        : ({
          name: 'Login/Register',
          type: 'modal',
          modal: 'login',
        })

    ), [user, notifications]),
    search: {
      name: 'Search',
      type: 'search',
    },
  }

  const openModal = memoize(modal => () => {
    dispatch(modalsActions.isOpen(modal, true))
  })

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState)
  }

  const handleOpenDropDown = memoize(menuName => event => {
    setOpenDropDownMenu({
      name: menuName,
      parentEl: event.currentTarget.parentElement,
    })
  })

  const handleCloseDropDown = () => {
    setOpenDropDownMenu({
      name: null,
      parentEl: null,
    })
  }


  const menuItemMap = {
    external: item => (
      <a
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          properties={['link']}
          label={item.name}
          type="noBorder"
        />
      </a>
    ),
    link: item => (
      <div
        className={css.menuButtonContainer}
      >
        { item.badge && <div className={css.badge} /> }
        <Button
          properties={['link']}
          label={item.name}
          link={item.link}
          type="noBorder"
        />
      </div>
    ),
    search: () => (
      <div
        className={css.search}
        onClick={openModal('search')}
      >
        <img
          className={css.icon}
          src={imgSearch}
          alt="search"
        />
      </div>
    ),
    modal: item => (
      <div
        onClick={openModal(item.modal)}
      >
        <Button
          properties={['link']}
          label={item.name}
          type="noBorder"
        />
      </div>
    ),
    dropDown: item => (
      <>
        <div
          className={css.menuButtonContainer}
          onClick={handleOpenDropDown(item.name)}
        >
          { item.badge && <div className={css.badge} /> }
          <Button
            properties={['link']}
            label={item.name}
            type="noBorder"
            badge={item.badge}
          />
        </div>
        <Menu
          anchorEl={openDropDownMenu.parentEl}
          open={openDropDownMenu.name === item.name}
          onClose={handleCloseDropDown}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {
            item.subMenu.map(childItem => (
              <MenuItem
                className={css.dropDownMenuItem}
                key={childItem.name}
                onClick={handleCloseDropDown}
              >
                {getMenuItem(childItem)}
              </MenuItem>
            ))
          }
        </Menu>
      </>
    ),
  }

  const getMenuItem = menuItem => (
    <div
      className={css.menuItem}
      key={menuItem.name}
    >
      {
        menuItemMap[menuItem.type]
          ? menuItemMap[menuItem.type](menuItem)
          : menuItem.name + ' missing'
      }
    </div>
  )

  return (
    <>
      <header
        className={cn({
          [css.header]: true,
          [css.staticHeader]: !isHeaderFloat,
          [css.floatingHeader]: isHeaderFloat,
        })}
      >
        <nav className={css.menuItemContainer}>
          {getMenuItem(menu.sciences)}
          {getMenuItem(menu.medical)}
        </nav>
        <nav className={css.mobileMenuItemContainer}>
          <div
            onClick={handleToggleMobileMenu}
          >
            <img
              className={css.icon}
              src={imgHamburger}
              alt="Mobile menu"
            />
          </div>
        </nav>
        <Link to="/" className={css.logoContainer}>
          <img className={css.logo} src={imgLogo} alt="AK Lectures logo" />
        </Link>
        <nav className={css.mobileMenuItemContainer}>
          {getMenuItem(menu.search)}
        </nav>
        <nav className={css.menuItemContainer}>
          {getMenuItem(menu.search)}
          {getMenuItem(menu.about)}
          {getMenuItem(menu.user)}
        </nav>
      </header>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        handleClose={handleToggleMobileMenu}
      />
    </>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(Header)
