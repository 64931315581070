import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import theme from 'css/front'
import { Link } from 'react-router-dom'
import imgPlay from 'images/play.svg'
import getLectureThumbnailLink from 'helpers/getLectureThumbnailLink'
import cn from 'classnames'

const useStyles = createUseStyles({
  container: {
    padding: '45px 0',
    position: 'relative',
  },
  header: {
    marginBottom: 40,
    padding: '0 45px',
  },
  sectionTitle: {
    ...theme.components.title,
  },
  sectionSubtitle: {
    fontSize: 22,
    color: theme.colors.textDarkGray,
    marginTop: 5,
  },
  divider: {
    width: 'calc(100% - 90px)',
    height: 1,
    background: theme.colors.divider,
    margin: '60px 45px',
  },
  lectureContainer: {
    maxHeight: 775,
    overflowY: 'auto',
    // padding right and margin right together are 45
    padding: '0 25px 0 45px',
    marginRight: 20,
  },
  lecture: {
    display: 'flex',
    padding: 10,
    marginBottom: 60,
    '&:last-of-type': {
      marginBottom: 20,
    },
  },
  lectureImgContainer: {
    position: 'relative',
    marginRight: 30,
  },
  lectureImgOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(74,74,74,.25)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButton: {
    height: 50,
    width: 50,
  },
  lectureImg: {
    display: 'block',
    height: 125,
    width: 125,
    objectFit: 'cover',
  },
  lectureTitle: {
    color: '#4C5158',
    fontSize: 18,
  },
  selectedLecture: {
    border: `1px solid ${theme.colors.nuBlu}`,
  },
})

const LectureNavigation = props => {
  const {
    playlist,
    nextPlaylist,
    currentPlaylistSlug,
    currentLectureSlug,
  } = props

  const css = useStyles()

  useEffect(() => {
    const selectedLectureEl = document
      .getElementById('playlist')
      .getElementsByClassName('selected-lecture')
    const topPos = selectedLectureEl[0].offsetTop
    document.getElementById('playlist').scrollTop = topPos - 110
  }, [currentPlaylistSlug, currentLectureSlug])

  const renderLecture = (playlistSlug, lecture, isCurrentLecture = false) => (
    <Link
      key={lecture.slug}
      to={`/lecture/${playlistSlug}/${lecture.slug}`}
      className={cn({
        [css.lecture]: true,
        [css.selectedLecture]: isCurrentLecture,
        'selected-lecture': isCurrentLecture,
      })}
    >
      <div className={css.lectureImgContainer}>
        <div className={css.lectureImgOverlay}>
          <img
            className={css.playButton}
            src={imgPlay}
            alt="Play Lecture"
          />
        </div>
        <img
          className={css.lectureImg}
          src={getLectureThumbnailLink(lecture)}
          alt={lecture.name}
          loading="lazy"
        />
      </div>
      <div className={css.lectureTitle}>
        {lecture.name}
      </div>
    </Link>
  )

  const renderPlaylist = (playlistSlug, lectures, currentLectureSlug = null) => (
    <div className={css.lectureContainer} id="playlist">
      {lectures.map(lecture => renderLecture(playlistSlug, lecture, lecture.slug === currentLectureSlug))}
    </div>
  )

  return (
    <div className={css.container}>
      <div className={css.playlist}>
        <div className={css.header}>
          <div className={css.sectionTitle}>
            NEXT VIDEOS
          </div>
        </div>
        {renderPlaylist(currentPlaylistSlug, playlist, currentLectureSlug)}
      </div>
      {
        !!nextPlaylist
        && (
          <>
            <div className={css.divider} />
            <div className={css.playlist}>
              <div className={css.header}>
                <div className={css.sectionTitle}>
                  NEXT PLAYLIST
                </div>
                <div className={css.sectionSubtitle}>
                  {nextPlaylist.name}
                </div>
              </div>
              {renderPlaylist(nextPlaylist.slug, nextPlaylist.lectures)}
            </div>
          </>
        )
      }
    </div>
  )
}

LectureNavigation.propTypes = {
  playlist: PropTypes.array.isRequired,
  nextPlaylist: PropTypes.object.isRequired,
  currentPlaylistSlug: PropTypes.string.isRequired,
  currentLectureSlug: PropTypes.string.isRequired,
}

export default LectureNavigation
