import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import alertActions from 'redux/alert/alertActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'

const mapStateToProps = ({
  alert,
}) => ({
  alert,
})
const mapDispatchToProps = dispatch => ({
  alertActions: bindActionCreators(alertActions, dispatch),
})


class Notifications extends PureComponent {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    alertActions: PropTypes.shape({
      clear: PropTypes.func.isRequired,
    }).isRequired,
    alert: PropTypes.shape({
      queue: PropTypes.array.isRequired,
    }).isRequired,
  }

  idsDisplayed = []

  componentDidUpdate() {
    const {
      enqueueSnackbar,
      alert: {
        queue,
      },
      alertActions: {
        clear,
      },
    } = this.props
    const newNotifications = queue
      .filter(notification => !this.idsDisplayed.includes(notification.id))

    if (newNotifications.length === 0) return

    for (let i = 0; i < newNotifications.length; i += 1) {
      const notification = newNotifications[i]
      this.idsDisplayed.push(notification.id)
      enqueueSnackbar(notification.message, {
        variant: notification.status,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
    }
    clear()
  }

  render() {
    return (
      <>
      </>
    )
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Notifications))
