import constants from './modalsConstants'

const initState = {
  adminCategory: false,
  adminLecture: false,
  forgotPassword: false,
  login: false,
  register: false,
  search: false,
  subjectsScience: false,
  subjectsMedical: false,
  cancelMembership: false,
  duplicateLogin: false,
  buffaloRegister: false,
  corrections: false,
  backupLectures: false,
}

export default (state = initState, action) => {
  let newState
  switch (action.type) {
    case constants.IS_OPEN:
      if (typeof state[action.name] === 'undefined') {
        return { ...state }
      }
      newState = { ...state }

      Object.keys(newState).forEach(modalName => {
        newState[modalName] = false
      })

      newState[action.name] = !!action.isOpen

      return newState
    case constants.CLOSE:
      return { ...initState }
    default:
      return state
  }
}
