import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Loading from 'components/Loading'
import useNotify from 'hooks/useNotify'
import { useDispatch } from 'react-redux'

const styles = {
  buttonContainer: {
    position: 'relative',
  },
  button: {
    color: theme.colors.nuBlu,
    background: '#fff',
    border: 'none',
    fontSize: 16,
    cursor: 'pointer',
    outline: 'none',
  },
}

const DownloadSnapshot = props => {
  const {
    classes: css,
    lectureSlug,
    name,
    label,
  } = props

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)

  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    try {
      const response = await axios({
        url: `/download/snapshot-link/${lectureSlug}/${name}?download=1`,
        method: 'GET',
      })
      const url = response.data.link
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${lectureSlug}.jpg`)
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      const message = err.response.status === 403
        ? 'Please login to download notes.'
        : 'Error downloading notes. Try again later.'
      notify(message, 'error')
    }
    setLoading(false)
  }

  return (
    <div className={css.buttonContainer}>
      {
        loading
          ? <Loading />
          : (
            <button
              className={css.button}
              onClick={handleClick}
              type="button"
            >
              {label}
            </button>
          )
      }
    </div>
  )
}

DownloadSnapshot.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  lectureSlug: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(DownloadSnapshot)