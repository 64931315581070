import biochemistry from 'images/subjects/biochemistry.svg'
import biology from 'images/subjects/biology.svg'
import cardiovascular from 'images/subjects/cardiovascular.svg'
import generalChemistry from 'images/subjects/general-chemistry.svg'
import gastrointestinal from 'images/subjects/gastrointestinal.svg'
import hematology from 'images/subjects/hematology.svg'
import modernPhysics from 'images/subjects/modern-physics.svg'
import oncology from 'images/subjects/oncology.svg'
import organicChemistry from 'images/subjects/organic-chemistry.svg'
import classicalPhysics from 'images/subjects/classical-physics.svg'
import pulmonology from 'images/subjects/pulmonology.svg'
import renal from 'images/subjects/renal.svg'
import fundamentals from 'images/subjects/fundamentals.svg'
import endocrinology from 'images/subjects/endocrinology.svg'
import rheumatology from 'images/subjects/rheumatology.svg'
import pathology from 'images/subjects/pathology.svg'
import neurology from 'images/subjects/neurology.svg'
import dermatology from 'images/subjects/dermatology.svg'
import pharmacology from 'images/subjects/pharmacology.svg'
import microbiology from 'images/subjects/microbiology.svg'
import behavioralScience from 'images/subjects/behavioral-science.svg'

import biochemistryCategory from 'images/categories/biochemistry.svg'
import biologyCategory from 'images/categories/biology.svg'
import cardiovascularCategory from 'images/categories/cardiovascular.svg'
import generalChemistryCategory from 'images/categories/general-chemistry.svg'
import gastrointestinalCategory from 'images/categories/gastrointestinal.svg'
import hematologyCategory from 'images/categories/hematology.svg'
import modernPhysicsCategory from 'images/categories/modern-physics.svg'
import oncologyCategory from 'images/categories/oncology.svg'
import organicChemistryCategory from 'images/categories/organic-chemistry.svg'
import classicalPhysicsCategory from 'images/categories/classical-physics.svg'
import pulmonologyCategory from 'images/categories/pulmonology.svg'
import renalCategory from 'images/categories/renal.svg'
import fundamentalsCategory from 'images/categories/fundamentals.svg'
import endocrinologyCategory from 'images/categories/endocrinology.svg'
import rheumatologyCategory from 'images/categories/rheumatology.svg'
import pathologyCategory from 'images/categories/pathology.svg'
import neurologyCategory from 'images/categories/neurology.svg'
import dermatologyCategory from 'images/categories/dermatology.svg'
import pharmacologyCategory from 'images/categories/pharmacology.svg'
import microbiologyCategory from 'images/categories/microbiology.svg'
import behavioralScienceCategory from 'images/categories/behavioral-science.svg'

const images = {
  biochemistry,
  biology,
  cardiovascular,
  generalChemistry,
  gastrointestinal,
  hematology,
  modernPhysics,
  oncology,
  organicChemistry,
  classicalPhysics,
  pulmonology,
  renal,
  fundamentals,
  endocrinology,
  rheumatology,
  pathology,
  neurology,
  dermatology,
  pharmacology,
  microbiology,
  behavioralScience,
}

const categories = {
  biochemistry: biochemistryCategory,
  biology: biologyCategory,
  cardiovascular: cardiovascularCategory,
  generalChemistry: generalChemistryCategory,
  gastrointestinal: gastrointestinalCategory,
  hematology: hematologyCategory,
  modernPhysics: modernPhysicsCategory,
  oncology: oncologyCategory,
  organicChemistry: organicChemistryCategory,
  classicalPhysics: classicalPhysicsCategory,
  pulmonology: pulmonologyCategory,
  renal: renalCategory,
  fundamentals: fundamentalsCategory,
  endocrinology: endocrinologyCategory,
  rheumatology: rheumatologyCategory,
  pathology: pathologyCategory,
  neurology: neurologyCategory,
  dermatology: dermatologyCategory,
  pharmacology: pharmacologyCategory,
  microbiology: microbiologyCategory,
  behavioralScience: behavioralScienceCategory,
}

export const getCategoryImage = image => categories[image] || null

export default image => images[image] || null
