import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import theme from 'css/front'
import cn from 'classnames'
import { subjects } from 'data/subjects'
import getSubjectImage from 'helpers/getSubjectImage'
import imgBanner from 'images/home-banner.jpg'
import imgMobileBanner from 'images/home-banner-mobile.jpg'
import imgMedicalBanner from 'images/medical-banner.jpg'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { camelCase } from 'lodash'
import uiFlagsActions from 'redux/uiFlags/uiFlagsActions'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import modalsActions from 'redux/modals/modalsActions'

const styles = {
  banner: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100vw',
    height: 500,
    backgroundImage: `url(${imgBanner})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    [theme.max(750)]: {
      backgroundImage: `url(${imgMobileBanner})`,
    },
  },
  mobileLine: {
    display: 'none',
    position: 'absolute',
    top: 90,
    left: '5%',
    height: 1,
    width: '90%',
    background: '#4C4F5F',
    [theme.max(750)]: {
      display: 'block',
    },
  },
  slogan: {
    marginBottom: 250,
    fontSize: 42,
    color: '#fff',
    textAlign: 'center',
    ...theme.type.robotoLight,
    letterSpacing: '1px',
    [theme.max(750)]: {
      marginBottom: 270,
      fontSize: 28,
    },
  },
  sloganBold: {
    ...theme.type.robotoBold,
  },
  title: {
    ...theme.type.roboto,
  },
  subTitle: {
    maxWidth: theme.sizes.containerWidth,
    textTransform: 'uppercase',
    fontSize: 18,
    fontFamily: 'Noto Sans JP',
    textAlign: 'center',
    margin: '0 auto',
    color: '#3A3C4E',
    fontWeight: 500,
    borderBottom: '1px solid #939598',
    padding: '30px 0',
  },
  medicalSubtitle: {
    fontSize: 42,
    ...theme.type.robotoLight,
    color: '#fff',
    textTransform: 'none',
    borderColor: '#6FCEEE',
    [theme.mobileOnly()]: {
      color: '#3A3C4E',
      fontSize: 30,
      borderColor: '#DFDFDF',
    },
  },
  textTitle: {
    color: theme.colors.nuBlu,
    fontSize: 36,
    padding: '70px 0 40px 0',
    textAlign: 'center',
    lineHeight: '44px',
  },
  text: {
    ...theme.type.robotoLight,
    textAlign: 'center',
    fontSize: 18,
    padding: 30,
    color: theme.colors.textGray,
    [theme.max(500)]: {
      lineHeight: '30px',
    },
  },
  medicalText: {
    background: `url(${imgMedicalBanner}) center center`,
    backgroundSize: 'cover',
    color: '#fff',
    fontSize: 20,
    [theme.mobileOnly()]: {
      background: 'none',
      color: theme.colors.textGray,
      fontSize: 16,
    },
  },
  textContainer: {
    maxWidth: theme.sizes.containerWidth,
    margin: '0 auto',
    lineHeight: '32px',
  },
  subjectContainerBackground: {
    width: '100vw',
    background: theme.colors.containerBackground,
  },
  subjectContainer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(300px, 1fr) )',
    gridGap: '20px',
    padding: '30px 0',
    maxWidth: theme.sizes.containerWidth,
    margin: '0 auto',
    justifyContent: 'center',
    '& > a': {
      height: 377,
      [theme.max(1200)]: {
        height: 280,
      },
    },
    [theme.mobileOnly()]: {
      padding: 0,
      gridTemplateColumns: 'repeat(2, minmax(50%, 1fr) )',
      gridGap: 0,
      '& > a': {
        height: 187,
      },
    },
  },
  subject: {
    position: 'relative',
    textAlign: 'center',
    color: theme.colors.babyBlu,
    transition: 'color .2s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.colors.nuBlu,
    },
    '&:active': {
      color: theme.colors.babyBlu,
    },
    [theme.mobileOnly()]: {
      height: 187,
    },
  },
  subjectHasNewLectures: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
    background: 'rgba(255, 255, 255, 0.5)',
    color: theme.colors.nuBlu,
    [theme.mobileOnly()]: {
      fontSize: 10,
      padding: 5,
    },
  },
  subjectImg: {
    maxWidth: 120,
    maxHeight: 120,
    [theme.max(1200)]: {
      maxWidth: 100,
      height: 100,
    },
    [theme.mobileOnly()]: {
      maxWidth: 80,
      height: 80,
    },
  },
  subjectName: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#fff',
    letterSpacing: '2px',
  },
  lectureCount: {
    position: 'absolute',
    bottom: 4,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 13,
    color: '#fff',
  },
  pricingButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '70px auto 80px auto',
    color: '#fff',
    border: '2px solid #fff',
    width: 370,
    height: 78,
    fontSize: 18,
    fontFamily: 'Noto Sans JP',
    [theme.mobileOnly()]: {
      width: 'calc(100vw - 60px)',
      borderColor: '#007BBC',
      color: '#007BBC',
    },
  },
}

const Home = props => {
  const {
    classes: css,
    location: {
      pathname: pathName,
    },
  } = props

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const [subjectsWithNewLectures, setSubjectsWithNewLectures] = useState([])
  const [lectureCount, setLectureCount] = useState({})

  useEffect(() => {
    dispatch(uiFlagsActions.setHeaderFloat(true))

    if (pathName === '/buffalo-medical' && user.id === 0) {
      dispatch(modalsActions.isOpen('buffaloRegister', true))
    }

    axios.get('categories/home-page-info')
      .then(res => {
        setSubjectsWithNewLectures(res.data.newLectures)
        setLectureCount(res.data.lectureCount)
      })
      .catch(console.error)

    return () => {
      dispatch(uiFlagsActions.setHeaderFloat(false))
    }
  }, [])

  const renderSubject = (subject, level) => (
    <Link
      to={`subject/${level === 'medical' ? 'medical/' : ''}${subject.link}`}
      className={cn(css.subject, css[`${level}Subject`])}
      key={subject.link}
      style={{ background: theme.colors[camelCase(subject.name)] }}
    >
      <img
        className={css.subjectImg}
        src={getSubjectImage(camelCase(subject.name))}
        alt={subject.name}
        loading="lazy"
      />
      {
        subjectsWithNewLectures.includes(`${level}-${subject.link}`)
          && (
            <span className={css.subjectHasNewLectures}>New Lectures</span>
          )
      }
      <div className={css.subjectName}>{subject.name}</div>
      {
        !!lectureCount[`${level}-${subject.link}`]
        && (
          <div className={css.lectureCount}>
            {lectureCount[`${level}-${subject.link}`]}
            {' '}
            lectures
          </div>
        )
      }
    </Link>
  )

  return (
    <div>
      <Helmet>
        <title>AK Lectures</title>
        <meta
          name="description"
          content="On a mission to create a library of lectures that provide simple explanations to many difficult topics in the sciences"
        />
      </Helmet>
      <div className={css.banner}>
        <div className={css.mobileLine} />
        <div className={css.slogan}>
          Difficult Concepts
          <br />
          <span className={css.sloganBold}>Simple Explanations</span>
        </div>
      </div>
      <div className={css.text}>
        <div className={css.subTitle}>
          ak lectures
        </div>
        <div className={css.textTitle}>
          A Library of Over 2,000 Free Lectures
        </div>
        <div className={css.textContainer}>
          We are on a mission to create a library of lectures that provide simple explanations to many difficult topics in the sciences. We think that every difficult topic can be explained in simple terms and it is our aim to do just that in the shortest amount of time. Whether you are trying to do well in a college course or just looking to expand your general knowledge, you can browse and watch our collection of over 2,000 videos for free!
        </div>
      </div>
      <div className={css.subjectContainerBackground}>
        <div className={cn(css.subjectContainer, css.subjectContainer3cols)}>
          {subjects.college.map(subject => renderSubject(subject, 'college'))}
        </div>
      </div>
      <div className={cn(css.text, css.medicalText)}>
        <div className={css.textContainer}>
          <div className={cn(css.subTitle, css.medicalSubtitle)}>
            Medical
            {' '}
            <span className={css.sloganBold}>Lectures</span>
          </div>
          <br />
          We are proud to announce our new section geared specifically for medical students! We have chosen some of the most challenging topics that are taught in medical school and simplified them. You can browse through our collection of 1,100+ videos in a variety of subjects. Use them to help supplement your medical education and ace your board exams!
        </div>
        <Link to="/pricing" className={css.pricingButton}>
          EXPLORE OUR PACKAGES
        </Link>
      </div>
      <div className={css.subjectContainerBackground} style={{ background: '#fff' }}>
        <div className={cn(css.subjectContainer, css.subjectContainer3cols)}>
          {subjects.medical.map(subject => renderSubject(subject, 'medical'))}
        </div>
      </div>
    </div>
  )
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default injectSheet(styles)(Home)
