module.exports = {
  plans: [
    {
      name: 'monthly',
      label: 'Monthly',
      perMonth: 34.99,
      description: 'Billed per month.',
      price: 34.99,
      legalPeriod: 'month',
    },
    {
      name: 'quarterly',
      label: '3 Months',
      perMonth: 24.99,
      description: 'billed every three months',
      price: 74.97,
      legalPeriod: '3 months',
    },
    {
      name: 'biannual',
      label: '6 Months',
      perMonth: 19.99,
      description: 'billed every six months',
      price: 119.94,
      legalPeriod: '6 months',
    },
    // {
    //   name: 'annual',
    //   label: '12 Months',
    //   perMonth: 19.99,
    //   description: 'billed every twelve months',
    //   price: 239.88,
    //   legalPeriod: '12 months',
    // },
    // {
    //   name: 'daily',
    //   perMonth: 360,
    //   description: 'Billed daily.',
    //   price: 12.00,
    // },
  ],
}
