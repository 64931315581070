import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import FontAwesome from './FontAwesome'
import cn from 'classnames'

const styles = {
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  loadingWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  loadingIcon: {
    display: 'inline-block',
    animationDuration: '5000ms',
    animationName: 'spin',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    color: theme.colors.babyBlu,
    fontSize: 35,
  },
  fill: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

const Loading = props => {
  const {
    classes: css,
    className,
    fill,
  } = props

  const wrapperClasses = [
    css.loadingWrapper,
  ]

  if (fill) {
    wrapperClasses.push(css.fill)
  }

  if (className) {
    wrapperClasses.push(...className.split(' '))
  }

  return (
    <div className={cn(wrapperClasses)}>
      <FontAwesome
        className={css.loadingIcon}
        name="faSpinner"
      />
    </div>
  )
}

Loading.propTypes = {
  classes: propTypes.object.isRequired,
  className: propTypes.string,
  fill: propTypes.bool,
}

Loading.defaultProps = {
  className: '',
  fill: false,
}

export default React.memo(injectSheet(styles)(Loading))
