import React from 'react'
import injectSheet from 'react-jss'
import cssVars from 'css/variables'
import propTypes from 'prop-types'
import cn from 'classnames'

const styles = {
  row: {
    display: 'flex',
    padding: cssVars.padding,
    paddingBottom: 0,
    position: 'relative',
    width: '100%',
  },
  multiline: {
    flexWrap: 'wrap',
  },
  center: {
    justifyContent: 'center',
  },
}

const Row = function (props) {
  const {
    children,
    classes: css,
    multiline,
    center,
    className,
  } = props

  return (
    <div
      className={cn(
        css.row,
        {
          [css.multiline]: multiline,
          [css.center]: center,
        },
        className,
      )}
    >
      {children}
    </div>
  )
}

Row.propTypes = {
  children: propTypes.oneOfType([propTypes.element, propTypes.array]).isRequired,
  classes: propTypes.object.isRequired,
  multiline: propTypes.bool,
  center: propTypes.bool,
  className: propTypes.string,
}

Row.defaultProps = {
  multiline: false,
  center: false,
  className: null,
}

export default injectSheet(styles)(Row)
