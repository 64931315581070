import React from 'react'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const FontAwesome = function ({ name, className, ...props }) {
  if (typeof icons[name] === 'undefined') {
    console.error(`FontAwesome icon '${name}' doesn't exists`)
  }

  const handleClick = e => {
    const {
      stopPropagation,
    } = props
    if (stopPropagation) {
      e.stopPropagation()
    }

    props.handleClick()
  }

  return (
    <div
      className={className || ''}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        icon={icons[name]}
      />
    </div>

  )
}

FontAwesome.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  stopPropagation: PropTypes.bool,
}

FontAwesome.defaultProps = {
  className: '',
  handleClick: () => {},
  stopPropagation: false,
}

export default FontAwesome
