import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Modal from 'components/modals/Modal'
import PropTypes from 'prop-types'

const styles = {
  title: {
    color: theme.colors.nuBlu,
    textAlign: 'center',
    fontSize: '20px',
    marginBottom: '30px',
  },
  modal: {
    padding: '20px',
    maxHeight: '100vh',
    overflow: 'auto',
  },
}

class FrontModal extends PureComponent {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    children: PropTypes.any,
  }

  static defaultProps = {
    title: '',
    children: <div />,
  }

  render() {
    const {
      handleClose,
      classes: css,
      title,
      children,
    } = this.props

    return (
      <Modal
        handleClose={handleClose}
        className={css.modal}
      >
        <div className={css.title}>
          {title}
        </div>
        {children}
      </Modal>
    )
  }
}

export default injectSheet(styles)(FrontModal)
