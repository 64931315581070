import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'redux/configureStore'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import axiosInit from 'helpers/axiosInit'
import { SnackbarProvider } from 'notistack'
import * as Sentry from '@sentry/browser'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

// init sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://ece88cac2cba4252a1ee7c00df4f64d6@sentry.io/1731104' })
}

// init axios
axiosInit()

// set material ui themes
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3C7FB8',
    },
  },
})

const rootDom = (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </MuiThemeProvider>
  </Provider>
)

ReactDOM.render(rootDom, document.getElementById('root'))

registerServiceWorker()
