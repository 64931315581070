import React from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'components/modals/FrontModal'
import propTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Button from 'components/elements/Button'
import theme from 'css/front'
import lectureActions from 'redux/lecture/lectureActions'

const useStyles = createUseStyles({
  modal: {
    width: '400px',
    margin: '10',
    [theme.max(450)]: {
      width: 'calc(100vw - 90px)',
    },
  },
  errorIcon: {
    textAlign: 'center',
    color: theme.colors.red,
    marginBottom: 20,
    fontSize: 25,
    opacity: 0.5,
  },
  text: {
    fontSize: 15,
    marginBottom: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  divider: {
    width: 15,
    height: 5,
  },
})

const ForgotPasswordModal = props => {
  const {
    handleClose,
  } = props

  const dispatch = useDispatch()
  const css = useStyles()

  const handleCancelClick = () => {
    handleClose()
  }

  const handleSwitchPlayerClick = () => {
    dispatch(lectureActions.setBackUpLecturePlayer(true))
    handleClose()
  }

  return (
    <Modal
      open
      handleClose={handleClose}
      title="Switch lecture player"
    >
      <div className={css.modal}>
        <div className={css.text}>
          <p>Having issues with streaming? Switch to another player</p>
          <p>Due to the COVID-19 pandemic, the video hosting provider used by AK Lectures has experienced issues, leading to a slowdown in video streaming. Here at AK Lectures, we take learning seriously and are taking steps to mitigate this problem. If you are experiencing issues with viewing our medical content, you can try switching to a different player. </p>
        </div>
        <div className={css.buttonContainer}>
          <Button
            className={css.button}
            onClick={handleCancelClick}
            label="Cancel"
            properties={['small']}
          />
          <div className={css.divider} />
          <Button
            className={css.button}
            onClick={handleSwitchPlayerClick}
            label="Switch Player"
            properties={['small']}
          />
        </div>
      </div>
    </Modal>
  )
}

ForgotPasswordModal.propTypes = {
  handleClose: propTypes.func.isRequired,
}

export default ForgotPasswordModal
