import React from 'react'
import Modal from 'components/modals/FrontModal'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import { Link } from 'react-router-dom'
import { subjects } from 'data/subjects'
import cn from 'classnames'
import getSubjectImage from 'helpers/getSubjectImage'
import { camelCase, capitalize } from 'lodash'

const styles = {
  modal: {
    width: 800,
    maxHeight: '80vh',
    overflow: 'auto',
    [theme.max(940)]: {
      width: 'calc(90vw - 40px)',
    },
  },
  subjectRow: {
    position: 'relative',
    display: 'grid',
    margin: '0 auto',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(3, minmax(50px, 1fr) )',
    gridGap: '20px',
    '& > a': {
      height: 200,
    },
    [theme.mobileOnly()]: {
      gridTemplateColumns: 'repeat(1, minmax(50px, 1fr) )',
      '& > a': {
        height: 'auto',
      },
    },
  },
  subject: {
    position: 'relative',
    textAlign: 'center',
    color: theme.colors.babyBlu,
    transition: 'color .2s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      color: theme.colors.nuBlu,
    },
    '&:active': {
      color: theme.colors.babyBlu,
    },
    [theme.mobileOnly()]: {
      alignItems: 'center',
    },
  },
  subjectImg: {
    maxWidth: 80,
    maxHeight: 80,
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  subjectName: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 13,
    color: '#fff',
    letterSpacing: '2px',
    [theme.mobileOnly()]: {
      padding: '15px 0',
      position: 'relative',
    },
  },
  medTitle: {
    padding: '25px 0 15px 10px',
    fontSize: 18,
    fontWeight: 'bold',
  },
}


const SubjectsModal = props => {
  const {
    classes: css,
    handleClose,
  } = props

  const type = props.type === 'medical' ? 'medical' : 'science'

  const renderSubject = subject => (
    <Link
      to={`/subject/${type === 'medical' ? 'medical/' : ''}${subject.link}`}
      className={cn(css.subject, css[`${type}Subject`])}
      key={subject.link}
      onClick={handleClose}
      style={{ background: theme.colors[camelCase(subject.name)] }}
    >
      <img
        className={css.subjectImg}
        src={getSubjectImage(camelCase(subject.name))}
        alt={subject.name}
      />
      <div className={css.subjectName}>{subject.name}</div>
    </Link>
  )

  const render = () => (
    <Modal
      open
      handleClose={handleClose}
      title={`${capitalize(type)} Subjects `}
    >
      <div className={css.modal}>
        <div className={css.subjectRow}>
          {subjects[type === 'medical' ? 'medical' : 'college'].map(renderSubject)}
        </div>
      </div>
    </Modal>
  )

  return render()
}

SubjectsModal.propTypes = {
  classes: propTypes.object.isRequired,
  handleClose: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
}

export default injectSheet(styles)(SubjectsModal)
