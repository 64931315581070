import { useState } from 'react'
import axios from 'axios'

export default postUrl => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const handleDelete = async additionalData => {
    axios.delete(`${postUrl}/${additionalData.join('/')}`)
  }

  const handleUpload = async (files, additionalData) => new Promise((resolve, reject) => {
    setLoading(true)
    const reader = new FileReader()
    reader.onload = () => {
      const data = new FormData()
      data.append('file', Array.isArray(files) ? files[0] : files)
      Object.keys(additionalData).forEach(key => {
        data.append(key, additionalData[key])
      })

      axios.post(postUrl, data)
        .then(res => {
          setResponse(res)
        })
        .catch(reject)
        .finally(() => {
          setLoading(false)
          resolve()
        })
    }
    files.forEach(file => reader.readAsBinaryString(file))
  })

  return { loading, response, handleUpload, handleDelete }
}
