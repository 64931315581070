import React from 'react'
import { createUseStyles } from 'react-jss'
import MaterialUIButton from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import theme from 'css/front'

const useStyles = createUseStyles({
  button: {
    ...theme.type.roboto,
  },
  small: {
    padding: '2px 16px !important',
    minHeight: '25px !important',
    fontSize: '.7rem !important',
    minWidth: '10px !important',
  },
  link: {
    padding: '2px 16px',
    minHeight: '25px',
    minWidth: '10px',
    border: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  red: {
    color: theme.colors.red,
    background: theme.colors.backgroundRed,
  },
})

const getStyle = type => {
  switch (type) {
    case 'primary':
      return {
        variant: 'outlined',
        color: 'primary',
      }
    case 'noBorder':
      return {}
    default:
      return {
        variant: 'outlined',
      }
  }
}

const Button = props => {
  const {
    label,
    type,
    onClick,
    className,
    properties,
    link,
  } = props

  const css = useStyles()

  const additionalClasses = []
  additionalClasses.push(...properties.map(property => css[property]))
  if (className) additionalClasses.push(className)

  return (
    <MaterialUIButton
      {...getStyle(type)}
      onClick={onClick}
      className={classNames(css.button, additionalClasses)}
      component={link ? Link : MaterialUIButton}
      to={link || null}
    >
      {label}
    </MaterialUIButton>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  properties: PropTypes.array,
  link: PropTypes.string,
}

Button.defaultProps = {
  type: 'default',
  disabled: false,
  className: '',
  properties: [],
  link: null,
  onClick: () => {},
}

export default Button
