import moment from 'moment'
import storage from 'local-storage-fallback'
import constants from './lectureConstants'

const storageKey = 'isBackupVideoPlayer'

const initState = {
  loading: true,
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.LOAD:
      const backupExpires = storage.getItem(storageKey)
      return {
        ...state,
        ...action.lecture,
        isBackupPlayer: backupExpires && moment(backupExpires).isAfter(moment()),
        loading: false,
      }
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    case constants.CLEAR:
      return {
        ...initState,
      }
    case constants.SET_BACKUP_PLAYER:
      if (action.isBackupPlayer) {
        storage.setItem(storageKey, moment().add(1, 'hour').format())
      } else {
        storage.removeItem(storageKey)
      }
      return {
        ...state,
        isBackupPlayer: action.isBackupPlayer,
      }
    default:
      return state
  }
}
