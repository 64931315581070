import axios from 'axios'
import exceptions from 'helpers/exceptions'
import constants from './categoryConstants'
import alertActions from '../alert/alertActions'
import categoriesActions from '../categories/categoriesActions'
import modalActions from '../modals/modalsActions'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const load = categoryId => async dispatch => {
  dispatch(loading(true))
  dispatch({ type: constants.CLEAR })
  const res = await axios.get(`/categories/${categoryId}`)
  dispatch({ type: constants.LOAD, category: res.data })
}

const save = category => async dispatch => {
  dispatch(loading(true))
  try {
    const isNew = category.id === 0
    if (isNew) {
      await axios.post('/categories/', category)
      dispatch(categoriesActions.load())
    } else {
      await axios.put(`/categories/${category.id}`, category)
    }
    dispatch(alertActions.create('Category saved'))
  } catch (e) {
    dispatch(alertActions.create('Could not save', 'error'))
    throw exceptions.notFound
  } finally {
    dispatch(loading(false))
  }
}

const create = parentId => ({
  type: constants.NEW,
  parentId,
})

const remove = categoryId => async dispatch => {
  dispatch(loading(true))
  try {
    await axios.delete(`/categories/${categoryId}`)
    dispatch(categoriesActions.load())
    dispatch(alertActions.create('Category deleted'))
    dispatch(modalActions.close())
  } catch (e) {
    const {
      data,
    } = e.response
    dispatch(alertActions.create((data && data.message) || 'Cannot delete', 'error'))
  } finally {
    dispatch(loading(false))
  }
}

const updateLectureOrder = (categoryId, lectureIds) => async dispatch => {
  dispatch(loading(true))
  try {
    await axios.put(`/categories/update-lecture-order/${categoryId}`, {
      lectureIds,
    })
    dispatch(load(categoryId))
    dispatch(alertActions.create('New order saved'))
  } catch (e) {
    dispatch(alertActions.create('Could not save the new order', 'error'))
    dispatch(loading(false))
  }
}

export default {
  create,
  load,
  save,
  remove,
  updateLectureOrder,
}
