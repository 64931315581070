import axios from 'axios'
import constants from './categoriesConstants'
import alertActions from '../alert/alertActions'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const load = () => async dispatch => {
  dispatch(loading(true))
  const res = await axios.get('/categories')
  dispatch({ type: constants.LOAD, categories: res.data })
}

const loadCategory = (level, slug) => async dispatch => {
  dispatch(loading(true))
  const res = await axios.get(`/categories/slug/${level}/${slug}`)
  dispatch({ type: constants.LOAD, categories: res.data })
}

const updateOrder = (parentId, childrenIds) => async dispatch => {
  dispatch(loading(true))
  try {
    await axios.put(`/categories/update-order/${parentId}`, {
      childrenIds,
    })
    dispatch(load())
    dispatch(alertActions.create('Order updated'))
  } catch (e) {
    dispatch(loading(false))
    dispatch(alertActions.create('Could not save new order', 'error'))
  }
}

export default {
  load,
  updateOrder,
  loadCategory,
}
