import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import Input from 'components/elements/SimpleInput'

const useStyles = createUseStyles({
  references: {
    marginTop: 10,
  },
  reference: {
    display: 'flex',
    marginBottom: 5,
  },
})

const newReference = {
  name: '',
  link: '',
}

const LectureReferences = props => {
  const {
    references,
    handleUpdateReferences,
  } = props

  const css = useStyles()

  const handleChange = (idx, name, value) => {
    const newReferences = [...references]
    newReferences[idx] = newReferences[idx] || { ...newReferences }
    newReferences[idx][name] = value
    handleUpdateReferences(newReferences)
  }

  const renderReference = (data, idx) => (
    <div className={css.reference}>
      <Input
        placeholder="Name"
        name="name"
        value={data.name}
        onChange={(name, value) => { handleChange(idx, name, value) }}
      />
      <Input
        placeholder="Link"
        name="link"
        value={data.link}
        onChange={(name, value) => { handleChange(idx, name, value) }}
      />
    </div>
  )

  return (
    <div className={css.references}>
      { [...references, { ...newReference }].map(renderReference) }
    </div>
  )
}

LectureReferences.propTypes = {
  references: PropTypes.array.isRequired,
  handleUpdateReferences: PropTypes.func.isRequired,
}

export default LectureReferences
