const notoSansJpBlack = '/fonts/NotoSans-Black'

export default {
  helvetica: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: '200',
  },
  avenir: {
    fontFamily: 'avenir, sans-serif',
    fontWeight: '200',
  },
  notoSansJp: {
    fontFamily: '\'Noto Sans JP\', sans-serif',
    fontWeight: '200',
  },
  roboto: {
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: '400',
  },
  robotoThin: {
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: '100',
  },
  robotoLight: {
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: '300',
  },
  robotoBold: {
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: '700',
  },
  fonts: {
    notoSansJpBlack: {
      '@font-face': {
        fontFamily: 'Noto Sans JP',
        fontWeight: '900',
        fontStyle: 'normal',
        src: `
          url(${notoSansJpBlack}.woff) format("woff"),
          url(${notoSansJpBlack}.woff2) format("woff2"),
          url(${notoSansJpBlack}.ttf) format("ttf")
        `,
      },
    },
  },
}
