import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import AccountForms from 'components/account/AccountForms'
import Payments from 'components/account/Payments'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    maxWidth: 1200,
    minHeight: 400,
    margin: '0 auto 20px auto',
    [theme.max(780)]: {
      flexDirection: 'column',
    },
  },
  forms: {
    width: 400,
    paddingBottom: 20,
    [theme.max(780)]: {
      width: '100%',
      margin: 0,
    },
  },
  payments: {
    width: 'calc(100% - 400px)',
    [theme.max(780)]: {
      width: 'calc(100% - 40px)',
      margin: '0 20px',
    },
  },
}

const Account = props => {
  const {
    classes: css,
    history,
  } = props

  const userId = useSelector(state => state.user.id)

  useEffect(() => {
    if (userId === 0) {
      history.push('/')
    }
  }, [userId, history])

  if (userId === 0) {
    return null
  }

  return (
    <div className={css.container}>
      <div className={css.forms}>
        <AccountForms />
      </div>
      <div className={css.payments}>
        <Payments />
      </div>
    </div>
  )
}

Account.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
}

export default injectSheet(styles)(Account)
