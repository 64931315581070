import uuid from 'uuid/v4'
import constants from './alertConstants'

const initState = {
  queue: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.CREATE:
      return {
        queue: [...state.queue, {
          id: uuid(),
          message: action.message,
          status: action.status,
        }],
      }
    case constants.REMOVE:
      return {
        queue: state.queue.filter(alert => alert.id !== action.id),
      }
    case constants.CLEAR:
      return { ...initState }
    default:
      return state
  }
}
