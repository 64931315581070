import React, { Component } from 'react'
import injectSheet from 'react-jss'
import cssVars from 'css/variables'
import PropTypes from 'prop-types'
import Button from 'components/elements/Button'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import alertActions from 'redux/alert/alertActions'
import axios from 'axios'

const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: cssVars.padding,
    maxWidth: 400,
    margin: '20px auto',
  },
  buttonContainer: {
    marginTop: 20,
  },
}

const mapStateToProps = ({
  alert,
}) => ({
  alert,
})
const mapDispatchToProps = dispatch => ({
  alertActions: bindActionCreators(alertActions, dispatch),
})

class AdminActions extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    alertActions: PropTypes.shape({
      create: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = {
    fetching: false,
  }

  fetchLectures = () => {
    const {
      alertActions: {
        create,
      },
    } = this.props
    this.setState({ fetching: true })
    axios
      .post('/lectures/fetch-lectures')
      .then(res => {
        create(res.data)
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  refreshSearch = () => {
    const {
      alertActions: {
        create,
      },
    } = this.props
    this.setState({ fetching: true })
    axios
      .post('/search/reindex-lectures')
      .then(res => {
        create(res.data)
      })
      .catch(e => {
        const {
          data,
        } = e.response
        create(data || 'Cannot refresh', 'error')
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  refreshCategoryCache = async () => {
    const {
      alertActions: {
        create: notify,
      },
    } = this.props

    this.setState({ fetching: true })
    await axios.post('/categories/admin-refresh-cache')
    this.setState({ fetching: false })
    notify('Category cache refreshed')
  }

  refreshLectureCache = async () => {
    const {
      alertActions: {
        create,
      },
    } = this.props
    this.setState({ fetching: true })
    axios
      .post('/lectures/admin-refresh-cache')
      .then(res => {
        create('Refreshing')
      })
      .catch(e => {
        const {
          data,
        } = e.response
        create(data || 'Cannot refresh', 'error')
      })
      .finally(() => {
        this.setState({ fetching: false })
      })
  }

  getNewPayments = async () => {
    this.setState({ fetching: true })
    await axios.get('/cron/get-payments')
    this.setState({ fetching: false })
  }

  render() {
    const {
      classes: css,
    } = this.props
    
    const {
      fetching,
    } = this.state

    return (
      <div className={css.page}>
        {
          fetching
            ? ('Working...')
            : (
              <>
                <Button
                  label="Fetch new lectures"
                  onClick={this.fetchLectures}
                  className={css.buttonContainer}
                />
                <Button
                  label="Refresh search"
                  onClick={this.refreshSearch}
                  className={css.buttonContainer}
                />
                <Button
                  label="Refresh category Cache"
                  onClick={this.refreshCategoryCache}
                  className={css.buttonContainer}
                />
                <Button
                  label="Refresh lecture Cache"
                  onClick={this.refreshLectureCache}
                  className={css.buttonContainer}
                />
                <Button
                  label="Get new payments"
                  onClick={this.getNewPayments}
                  className={css.buttonContainer}
                />
              </>
            )
        }
        
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(AdminActions))
