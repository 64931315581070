import typography from 'css/typography'
import theme from 'css/front'

export default {
  'html,body': {
    margin: 0,
    padding: 0,
    color: theme.colors.textGray,
    ...typography.avenir,
  },
  '*': {
    boxSizing: 'border-box',
  },
  a: {
    textDecoration: 'none',
    color: theme.colors.babyBlu,
    '&:hover': {
      color: theme.colors.nuBlu,
    },
    '&:active': {
      color: theme.colors.babyBlu,
    },
  },
  ...typography.fonts.notoSansJpBlack,
}
