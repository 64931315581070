import React from 'react'
import Select from 'components/elements/Select'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import categoriesActions from 'redux/categories/categoriesActions'
import { bindActionCreators } from 'redux'

const styles = {
  select: {
    marginRight: 10,
  },
}

const mapStateToProps = ({
  categories,
}) => ({
  categories,
})
const mapDispatchToProps = dispatch => ({
  categoriesActions: bindActionCreators(categoriesActions, dispatch),
})

class CategoryPicker extends React.Component {
  static propTypes = {
    selectedCategoryId: PropTypes.number,
    handleCategorySelect: PropTypes.func.isRequired,
    categories: PropTypes.shape({
      categories: PropTypes.object.isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    categoriesActions: PropTypes.shape({
      load: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    selectedCategoryId: null,
  }

  componentDidMount() {
    const {
      categories: {
        categories,
      },
      categoriesActions: {
        load,
      },
    } = this.props

    if (Object.keys(categories).length === 0) load()
  }

  renderCategorySelect = selectedId => {
    const {
      categories: {
        categories,
      },
      classes: css,
      handleCategorySelect,
    } = this.props

    const selectedCategories = [categories[selectedId]]

    while (selectedCategories.slice(-1)[0].parents.length > 0) {
      selectedCategories.push(categories[selectedCategories.slice(-1)[0].parents[0]])
    }

    return selectedCategories
      .reverse()
      .map((category, idx, categoryChain) => {
        const options = category.children.reduce((all, childCategoryId) => {
          all[categories[childCategoryId].id] = categories[childCategoryId].name
          return all
        }, {})

        if (!Object.keys(options).length) return <div />

        return (
          <div key={category.id}>
            <Select
              name="category"
              options={options}
              value={categoryChain[idx + 1] ? categoryChain[idx + 1].id : ''}
              handleChange={handleCategorySelect}
              placeholder="Select a category"
              className={css.select}
            />
          </div>
        )
      })
  }

  render() {
    const {
      categories: {
        loading,
      },
      selectedCategoryId,
    } = this.props

    if (loading) return <div />

    return this.renderCategorySelect(selectedCategoryId || 1)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(CategoryPicker))
