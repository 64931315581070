import constants from './commentsConstants'

const initState = {
  loading: true,
  loadingPosting: false,
  lectureId: null,
  comments: [],
  commentCount: 0,
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.LOAD:
      return {
        ...state,
        lectureId: action.lectureId,
        commentCount: action.commentCount,
        comments: action.clearLectures || action.reloadLectures
          ? action.comments
          : [...state.comments, ...(action.comments || [])],
        loading: false,
        loadingPosting: false,
      }
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    case constants.SET_LOADING_POSTING:
      return {
        ...state,
        loadingPosting: action.isLoading,
      }
    case constants.CLEAR:
      return {
        ...initState,
      }
    default:
      return state
  }
}
