import axios from 'axios'
import storage from 'local-storage-fallback'
import constants from './userConstants'
import alertActions from '../alert/alertActions'
import membershipConstants from '../membership/membershipConstants'
import modalsActions from '../modals/modalsActions'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const setUser = userData => dispatch => {
  const {
    medical,
    ...user
  } = userData

  dispatch({
    type: constants.SET_USER,
    user,
  })
  dispatch({
    type: membershipConstants.LOAD,
    ...medical,
  })
}

const login = (username, password) => async dispatch => {
  try {
    dispatch(loading(true))
    const res = await axios.post('/users/login', {
      username,
      password,
    })

    dispatch(setUser(res.data))

    dispatch(modalsActions.isOpen('login', false))
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          dispatch(alertActions.create(error.response.data, 'error'))
          break
        case 401:
          dispatch(alertActions.create('Incorrect login.', 'error'))
          break
        default:
          dispatch(alertActions.create('Internal Error. Please refresh.', 'error'))
          break
      }
    }
  }
}


const register = form => async dispatch => {
  try {
    dispatch(loading(true))
    const res = await axios.post('/users/register', form)
    dispatch(setUser(res.data))
    dispatch(modalsActions.close())
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          dispatch(alertActions.create(error.response.data, 'error'))
          break
        default:
          dispatch(alertActions.create('Internal Error. Please refresh.', 'error'))
          break
      }
    }
  }
}

const loginToken = () => async dispatch => {
  try {
    const token = storage.getItem('auth-token')
    if (token) {
      const res = await axios.post('/users/login', {
        token,
      })
      dispatch(setUser(res.data))
    } else {
      dispatch({
        type: constants.SET_INIT_LOADING,
        isLoading: false,
      })
    }
  } catch (e) {
    dispatch({
      type: constants.SET_INIT_LOADING,
      isLoading: false,
    })
  }
}

const logout = () => async dispatch => {
  dispatch({ type: constants.LOGOUT })
  dispatch({ type: membershipConstants.CLEAR })
}

const set = (name, val) => ({
  type: constants.UPGRADE,
  name,
  val,
})

export default {
  login,
  register,
  loginToken,
  set,
  logout,
}
