import axios from 'axios'
import constants from './commentsConstants'
import alertActions from '../alert/alertActions'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const loadingPosting = isLoading => ({
  type: constants.SET_LOADING_POSTING,
  isLoading,
})

const loadLecture = params => async dispatch => {
  const {
    lectureSlug,
    page = 1,
    clearLectures = true,
    reloadLectures = false,
  } = params

  if (clearLectures) {
    dispatch({ type: constants.CLEAR })
    dispatch(loading(true))
  } else {
    dispatch(loadingPosting(true))
  }
  const res = await axios.get(`/comments/lecture/${lectureSlug}?page=${page}`)
  dispatch({
    type: constants.LOAD,
    ...res.data,
    clearLectures,
    reloadLectures,
  })
}

const postLecture = (lectureSlug, comment, parentId = null) => async dispatch => {
  dispatch(loadingPosting(true))
  try {
    await axios.post(`/comments/lecture/${lectureSlug}`, {
      comment,
      parentId,
    })
    dispatch(loadLecture({
      lectureSlug,
      clearLectures: false,
      reloadLectures: true,
    }))
  } catch (e) {
    const {
      data,
    } = e.response
    dispatch(alertActions.create((data && data.message) || 'Cannot create a comment. Please refresh the page.', 'error'))
  } finally {
    dispatch(loadingPosting(false))
  }
}

export default {
  loadLecture,
  postLecture,
}
