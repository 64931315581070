class notFound extends Error {
  constructor(message) {
    super(message)
    this.name = 'Resource not found'
  }
}

export default {
  notFound,
}
