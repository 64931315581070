import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'components/modals/FrontModal'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Button from 'components/elements/Button'
import axios from 'axios'
import Loading from 'components/Loading'
import membershipActions from 'redux/membership/membershipActions'
import modalsActions from 'redux/modals/modalsActions'
import Input from 'components/elements/Input'

const styles = {
  modal: {
    width: '400px',
    margin: '10',
    [theme.max(450)]: {
      width: 'calc(100vw - 80px)',
    },
  },
  reason: {
    margin: '15px 0',
  },
  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    marginTop: 30,
    height: 37,
  },
  cancelButton: {
    marginLeft: 'auto',
  },
}

const CancelMembershipModal = props => {
  const {
    handleClose,
    classes: css,
  } = props

  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  const dispatch = useDispatch()

  const handleCancelMembership = async () => {
    setLoading(true)
    await axios.post('/users/membership-delete', {
      reason,
    })
    dispatch(membershipActions.load(true))
    dispatch(modalsActions.isOpen('cancelMembership', false))
  }

  return (
    <Modal
      open
      handleClose={handleClose}
      title="Cancel Membership"
    >
      <div className={css.modal}>
        Your membership will continue to be active until the end of the payment period.
        <div className={css.reason}>
          <Input
            name="reason"
            value={reason}
            label="Reason for cancellation (optional)"
            onChange={(attr, val) => setReason(val)}
            fullWidth
            multiline
          />
        </div>
        <div className={css.buttonsContainer}>
          {
            loading
              ? <Loading fill />
              : (
                <>
                  <Button
                    onClick={handleClose}
                    label="Close"
                  />
                  <Button
                    className={css.cancelButton}
                    onClick={handleCancelMembership}
                    label="Cancel Membership"
                    properties={['red']}
                  />
                </>
              )
          }
          
        </div>
      </div>
    </Modal>
  )
}

CancelMembershipModal.propTypes = {
  handleClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(CancelMembershipModal)
