import typography from './typography'

export default {
  colors: {
    nuBlu: '#007BBC',
    babyBlu: '#7AD1EF',
    headerGray: '#44484B',
    textGray: '#717781',
    textDarkGray: '#3F4049',
    backgroundGray: '#F7F7F7',
    containerBackground: '#EBEBEB',
    borderGray: '#c9cacc',
    green: '#569684',
    red: '#bc0000',
    divider: '#E6E6E6',
    backgroundRed: '#fae6e6',
    badge: '#cc5757',
    backgroundDarkest: '#1B1D30',

    // -- subjects ---
    biology: '#007BBC',
    generalChemistry: '#81CCB9',
    organicChemistry: '#7AD1EF',
    biochemistry: '#039590',
    classicalPhysics: '#008DB4',
    modernPhysics: '#3A3C4E',
    fundamentals: '#519ED1',
    hematology: '#797DB1',
    oncology: '#3C6D9C',
    renal: '#B669A9',
    gastrointestinal: '#767A9E',
    pulmonology: '#99C38A',
    cardiovascular: '#E37172',
    endocrinology: '#E884A8',
    rheumatology: '#6B889E',
    pathology: '#50B07D',
    neurology: '#5FB4BE',
    dermatology: '#5F9E7D',
    microbiology: '#BD97B8',
    pharmacology: '#006284',
    behavioralScience: '#D79374',
    plans: {
      monthly: {
        main: '#B7DDEA',
        background: '#16304C',
      },
      quarterly: {
        main: '#7AD0EE',
        background: '#142B45',
      },
      biannual: {
        main: '#60AEE6',
        background: '#0F2640',
      },
    },
  },
  components: {
    row: {
      display: 'flex',
    },
    hideMobile: {
      '@media (max-width: 980px)': {
        display: 'none',
      },
    },
    hideDesktop: {
      '@media (min-width: 981px)': {
        display: 'none',
      },
    },
    title: {
      fontWeight: 900,
      letterSpacing: '1px',
      fontSize: 16,
      color: '#007BBC',
      textTransform: 'uppercase',
      '@media (max-width: 980px)': {
        fontSize: 12,
      },
    },
  },
  sizes: {
    containerWidth: 1172,
  },
  resetButton: {
    border: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    overflow: 'visible',
    background: 'transparent',
    color: 'inherit',
    font: 'inherit',
    lineHeight: 'normal',
    outline: 'none',
  },
  max: size => `@media (max-width: ${size}px)`,
  mobileOnly: () => '@media (max-width: 980px)',
  desktopOnly: () => '@media (min-width: 981px)',
  type: {
    ...typography,
  },
}
