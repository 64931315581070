export default [
  'load',
  'set_loading',
  'edit',
  'clear',
  'set_backup_player',
].reduce((all, con) => {
  all[con.toUpperCase()] = `LECTURE_${con.toUpperCase()}`
  return all
}, {})
