import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import theme from 'css/front'
import propTypes from 'prop-types'
import { camelCase } from 'lodash'
import { getCategoryImage } from 'helpers/getSubjectImage'
import { Link } from 'react-router-dom'

const styles = {
  header: {
    position: 'relative',
    height: 132,
    display: 'flex',
    background: theme.colors.backgroundGray,
    alignItems: 'center',
    [theme.mobileOnly()]: {
      height: 84,
    },
  },
  subject: {
    position: 'relative',
    width: 400,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.mobileOnly()]: {
      width: '100vw',
    },
  },
  subjectText: {
    ...theme.type.notoSansJpBlack,
    color: '#fff',
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    maxWidth: 210,
    textAlign: 'center',
    '&:hover': {
      color: '#fff',
    },
    [theme.mobileOnly()]: {
      fontSize: 14,
    },
  },
  headerLogo: {
    height: 75,
    marginRight: '30px',
    [theme.mobileOnly()]: {
      height: 40,
    },
  },
  playlist: {
    background: theme.colors.nuBlu,
    width: 'calc(100% - 400px)',
    height: '100%',
    padding: '50px 60px 0 60px',
    display: 'flex',
    [theme.mobileOnly()]: {
      display: 'none',
    },
  },
  playlistTitle: {
    letterSpacing: '2px',
    fontSize: 15,
    color: theme.colors.babyBlu,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  playlistName: {
    fontSize: 24,
    color: '#fff',
  },
  playlistNextButton: {
    marginLeft: 'auto',
    border: `2px solid ${theme.colors.babyBlu}`,
    width: 185,
    height: 47,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.babyBlu,
    cursor: 'default',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 14,
    [theme.max(1400)]: {
      display: 'none',
    },
    '&:hover': {
      opacity: '.75',
    },
    '&:active': {
      opacity: '1',
    },
  },
  rightArrow: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: `10px solid ${theme.colors.babyBlu}`,
    marginLeft: 8,
  },
}

class PageHeader extends PureComponent {
  static propTypes = {
    subject: propTypes.string.isRequired,
    subjectLink: propTypes.string,
    label: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    classes: propTypes.object.isRequired,
    handleNextClick: propTypes.func.isRequired,
    handleNameClick: propTypes.func,
    showNextButton: propTypes.bool.isRequired,
    buttonText: propTypes.string,
  }

  static defaultProps = {
    buttonText: null,
    subjectLink: null,
    handleNameClick: () => {},
  }

  render() {
    const {
      subject,
      classes: css,
      name,
      handleNextClick,
      showNextButton,
      handleNameClick,
      label,
      buttonText,
      subjectLink,
    } = this.props
    
    return (
      <div className={css.header}>
        <div
          className={css.subject}
          style={{ background: theme.colors[camelCase(subject)] }}
          onClick={handleNameClick}
        >
          <img
            src={getCategoryImage(camelCase(subject))}
            alt={`${subject} logo`}
            className={css.headerLogo}
          />
          {
            subjectLink
              ? (
                <Link to={subjectLink} className={css.subjectText}>
                  {subject}
                </Link>
              )
              : (
                <div className={css.subjectText}>
                  {subject}
                </div>
              )
          }
        </div>
        <div className={css.playlist}>
          <div className={css.playlistNameContainer}>
            <div className={css.playlistTitle}>
              {label}
            </div>
            <div className={css.playlistName}>
              {name}
            </div>
          </div>
          {
            showNextButton
            && (
              <div
                className={css.playlistNextButton}
                onClick={handleNextClick}
              >
                {buttonText}
                {' '}
                <div className={css.rightArrow} />
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(PageHeader)