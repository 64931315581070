import axios from 'axios'
import exceptions from 'helpers/exceptions'
import constants from './lectureConstants'
import alertActions from '../alert/alertActions'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const clear = () => ({
  type: constants.CLEAR,
})

const load = lectureId => async dispatch => {
  dispatch(loading(true))
  dispatch(clear())
  const res = await axios.get(`/lectures/${lectureId}`)
  dispatch({ type: constants.LOAD, lecture: res.data })
}

const loadBySlug = (slug, playlist = null) => async dispatch => {
  dispatch(loading(true))
  const query = playlist ? `?playlist=${playlist}` : ''
  const res = await axios.get(`/lectures/slug/${slug}${query}`)
  dispatch({ type: constants.LOAD, lecture: res.data })
}

const save = lecture => async dispatch => {
  dispatch(loading(true))
  try {
    await axios.put(`/lectures/${lecture.id}`, lecture)
    dispatch(alertActions.create('Lecture saved'))
  } catch (e) {
    dispatch(alertActions.create('Could not save lecture', 'error'))
    throw exceptions.notFound
  } finally {
    dispatch(loading(false))
  }
}

const setBackUpLecturePlayer = isBackupPlayer => ({
  type: constants.SET_BACKUP_PLAYER,
  isBackupPlayer,
})

export default {
  load,
  clear,
  save,
  loadBySlug,
  setBackUpLecturePlayer,
}
