import constants from './alertConstants'

const create = (message, status = 'success') => ({
  type: constants.CREATE,
  message,
  status,
})

const remove = id => ({
  type: constants.REMOVE,
  id,
})

const clear = () => ({
  type: constants.CLEAR,
})

export default {
  create,
  remove,
  clear,
}
