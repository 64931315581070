import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/elements/Input'
import Select from 'components/elements/Select'
import Button from '@material-ui/core/Button'
import userActions from 'redux/user/userActions'
import modalsActions from 'redux/modals/modalsActions'
import alertActions from 'redux/alert/alertActions'
import Modal from 'components/modals/FrontModal'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import Row from 'components/Row'
import theme from 'css/front'
import validator from 'validator'
import Loading from 'components/Loading'

const styles = {
  modal: {
    width: '400px',
    margin: '10',
    overflow: 'auto',
    [theme.max(450)]: {
      width: 'calc(100vw - 80px)',
    },
  },
  forgotPassword: {
    fontSize: '13px',
    marginTop: '5px',
    textAlign: 'right',
  },
  buttonRow: {
    padding: '10px',
    marginTop: 10,
  },
  register: {
    marginTop: '40px',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  registerLink: {
    color: theme.colors.nuBlu,
    cursor: 'pointer',
  },
  input: {
    marginTop: 10,
  },
  info: {
    fontSize: 13,
    lineHeight: '20px',
    marginBottom: 10,
  },
}

const initState = {
  username: '',
  password: '',
  password2: '',
  email: '',
}

const Register = props => {
  const {
    handleClose,
    classes: css,
    type,
  } = props

  const [form, setForm] = useState({ ...initState })
  const [errors, setErrors] = useState({})
  const [showInputForSource, setShowInputForSource] = useState(false)

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  useEffect(() => {
    setForm({ ...initState })
    setErrors({})
  }, [])

  const setError = (field, message = null) => {
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors }
      if (message) {
        newErrors[field] = message
      } else if (!message && newErrors[field]) {
        delete newErrors[field]
      }
      return newErrors
    })
  }

  const handleChange = (name, value) => {
    let newVal = value
    setError(name)
    if (name === 'username') {
      if (!validator.isAlphanumeric(newVal) && newVal !== '') {
        setError('username', 'Only letters and number allowed')
      }
    }
    if (name === 'source') {
      if (newVal === 'Other' && !showInputForSource) {
        newVal = ''
        setShowInputForSource(true)
      }
    }

    setForm(prevState => ({ ...prevState, [name]: newVal }))
  }

  const handleRegister = () => {
    const {
      username,
      email,
      password,
      password2,
    } = form

    const notify = (message, status) => dispatch(alertActions.create(message, status))

    if (Object.keys(errors).length) {
      notify('Fix all errors to register.', 'error')
      return
    }

    if (type === 'buffalo') {
      const {
        fullName = '',
        year,
      } = form

      if (!validator.isLength(fullName, { min: 1, max: 100 })) {
        setError('fullName', 'Fill in your full name.')
        return
      }

      if (!year) {
        setError('year', 'Select your year.')
        return
      }
      
      if (validator.isEmail(email) && email.split('@')[1].toLowerCase() !== 'buffalo.edu') {
        setError('email', 'Please use a buffalo.edu email.')
        return
      }
    }

    if (!validator.isLength(username, { min: 4, max: 100 })) {
      setError('username', 'Your username must be at least 4 characters.')
      return
    }

    if (!validator.isEmail(email)) {
      setError('email', 'Enter a valid email to continue.')
      return
    }

    if (!validator.isLength(password, { min: 6, max: 100 })) {
      setError('password', 'Your password must be at least 6 characters.')
      return
    }

    if (password !== password2) {
      setError('password', 'The passwords entered do not match.')
      return
    }

    if (!username || !email || !password) {
      notify('All fields must be filled in.', 'error')
      return
    }

    dispatch(userActions.register({ registerationType: type, ...form }))
  }

  const handleBackToLogin = () => {
    dispatch(modalsActions.isOpen('login'))
  }

  const submitOnEnter = e => {
    if (e.key === 'Enter') {
      handleRegister()
    }
  }

  const renderBuffaloForm = () => (
    <>
      <div className={css.info}>
        Hey guys! Please fill out the information below by Friday, December 7th 2019! Once we receive everyone's information, we can then process it and give you a free subscription for the year! 
      </div>
      <Input
        label="Full name"
        error={errors.fullName}
        className={css.usernameTextField}
        name="fullName"
        value={form.fullName || ''}
        onChange={handleChange}
        onKeyDown={submitOnEnter}
        fullWidth
      />
      <Select
        label="Year"
        name="year"
        error={errors.year}
        options={{
          first: 'First',
          second: 'Second',
          third: 'Third',
        }}
        disabledOptions={['first', 'second']}
        value={form.year || ''}
        handleChange={val => { handleChange('year', val) }}
        placeholder="Year"
        fullWidth
      />
    </>
  )

  return (
    <Modal
      open
      handleClose={handleClose}
      title="Create an account"
    >
      <div className={css.modal}>
        {type === 'buffalo' && renderBuffaloForm()}
        <div className={css.input}>
          <Input
            error={errors.username}
            className={css.usernameTextField}
            name="username"
            value={form.username}
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            fullWidth
            autoFocus
          />
        </div>
        <div className={css.input}>
          <Input
            error={errors.email}
            className={css.usernameTextField}
            name="email"
            value={form.email}
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            fullWidth
          />
        </div>
        <div className={css.input}>
          <Input
            error={errors.password}
            type="password"
            name="password"
            value={form.password}
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            fullWidth
          />
        </div>
        <div className={css.input}>
          <Input
            label="Repeat password"
            error={errors.password2}
            type="password"
            name="password2"
            value={form.password2}
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            fullWidth
          />
        </div>
        <div className={css.input}>
          {
            showInputForSource
              ? (
                <Input
                  label="Source"
                  name="source"
                  value={form.source}
                  onChange={handleChange}
                  onKeyDown={submitOnEnter}
                  fullWidth
                  autoFocus
                />
              )
              : (
                <Select
                  label="How did you hear about us? (Optional)"
                  name="Source"
                  options={{
                    'Web search': 'Web search',
                    'Social media': 'Social Media',
                    School: 'School',
                    Friend: 'Friend',
                    YouTube: 'YouTube',
                    Reddit: 'Reddit',
                    Other: 'Other',
                  }}
                  value={form.source || ''}
                  handleChange={val => { handleChange('source', val) }}
                  placeholder=""
                  fullWidth
                />
              )
          }
        </div>
        <Row
          center
          className={css.buttonRow}
        >
          {
            user.loading
              ? (
                <Loading fill />
              )
              : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleRegister}
                >
                  Register
                </Button>
              )
          }
        </Row>

        <div className={css.register}>
          Have an account?&nbsp;
          <div
            className={css.registerLink}
            onClick={handleBackToLogin}
          >
            Log in
          </div>
        </div>

      </div>
    </Modal>
  )
}


Register.propTypes = {
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
}

Register.defaultProps = {
  type: 'default',
}

export default injectSheet(styles)(Register)
