import { createStore, applyMiddleware } from 'redux'
import rootReducer from 'redux/rootReducer'
import thunk from 'redux-thunk'
// import logger from 'redux-logger'


// applyMiddleware(logger),
// compose(
// window.devToolsExtension ? window.devToolsExtension() : f => f
// )

const store = createStore(rootReducer, applyMiddleware(thunk))
window.store = store
export default store
