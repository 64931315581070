import React from 'react'
import TextField from '@material-ui/core/TextField'
import propTypes from 'prop-types'
import { capitalize } from 'lodash'
import InputAdornment from '@material-ui/core/InputAdornment'
import FontAwesome from 'components/FontAwesome'
import theme from 'css/front'
import { createUseStyles } from 'react-jss'
import Cleave from 'cleave.js/react'

const useStyles = createUseStyles({
  icon: {
    color: theme.colors.textGray,
  },
})

const MaskedTextField = props => {
  const {
    options,
    inputRef,
    ...other
  } = props

  return (
    <Cleave
      {...other}
      ref={inputRef}
      options={options}
    />
  )
}

MaskedTextField.propTypes = {
  options: propTypes.object.isRequired,
  inputRef: propTypes.any.isRequired,
}

const InputElement = props => {
  const css = useStyles()

  const {
    name,
    onChange: parentOnChange,
    label,
    fullWidth,
    multiline,
    value,
    type,
    className,
    autoFocus,
    placeholder,
    icon,
    required,
    rows,
    error,
    format,
    labelLockedOnTop,
  } = props

  const onChange = e => {
    parentOnChange(name, e.target.value)
  }

  const renderIcon = () => ({
    startAdornment: (
      <InputAdornment position="start">
        <FontAwesome
          name={icon}
          className={css.icon}
        />
      </InputAdornment>
    ),
  })

  const getInputProps = () => ({
    ...(!!icon && renderIcon()),
    ...(!!format && {
      inputComponent: MaskedTextField,
      inputProps: { options: format },
    }),
  })

  return (
    <TextField
      className={className}
      label={label === null ? capitalize(name) : label}
      type={type}
      value={value || ''}
      onChange={onChange}
      fullWidth={fullWidth}
      multiline={multiline}
      autoFocus={autoFocus}
      placeholder={placeholder}
      required={required}
      rows={rows}
      InputProps={getInputProps()}
      error={!!error}
      helperText={error || null}
      InputLabelProps={{
        ...(labelLockedOnTop ? { shrink: true } : {}),
      }}
    />
  )
}

InputElement.propTypes = {
  type: propTypes.string,
  value: propTypes.string,
  label: propTypes.string,
  className: propTypes.string,
  multiline: propTypes.bool,
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  fullWidth: propTypes.bool,
  autoFocus: propTypes.bool,
  placeholder: propTypes.string,
  icon: propTypes.string,
  required: propTypes.bool,
  rows: propTypes.number,
  error: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  format: propTypes.object,
  labelLockedOnTop: propTypes.bool,
}

InputElement.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  label: null,
  className: '',
  multiline: false,
  fullWidth: false,
  autoFocus: false,
  icon: '',
  required: false,
  rows: 1,
  error: false,
  format: null,
  labelLockedOnTop: false,
}

export default InputElement
