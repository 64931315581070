import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import CategoryRow from 'components/admin/lectures/CategoryRow'
import cssVars from 'css/variables'
import FontAwesome from 'components/FontAwesome'
import PropTypes from 'prop-types'
import listItemStyles from 'css/components/listItem'
import categoryActions from 'redux/category/categoryActions'
import categoriesActions from 'redux/categories/categoriesActions'
import modalsActions from 'redux/modals/modalsActions'
import Button from 'components/elements/Button'
import { isEqual } from 'lodash'

const useStyles = createUseStyles({
  row: { ...listItemStyles.row },
  column: {
    border: cssVars.border,
    maxHeight: 'calc(100vh - 180px)',
    overflow: 'auto',
    minWidth: 350,
  },
  columnTitleWrapper: {
    display: 'flex',
    fontWeight: '500',
    cursor: 'auto',
    color: cssVars.brand,
    padding: cssVars.padding / 2,
    borderBottom: cssVars.border,
  },
  columnTitle: {
    marginRight: 10,
  },
  columnTitleOptions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  columnTitleButtons: {
    marginRight: '5px',
  },
  columnTitleAdd: {
    color: cssVars.darkGray,
    border: `1px solid ${cssVars.gray}`,
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 11,
    '&:hover': {
      color: cssVars.brand,
      border: `1px solid ${cssVars.brand}`,
    },
    '&:active': {
      color: cssVars.darkGray,
      border: `1px solid ${cssVars.darkGray}`,
    },
  },
})

const CategoryList = props => {
  const {
    parentCategory,
    handleCategorySelect: parentHandleCategorySelect,
    categoryIndex,
  } = props

  const css = useStyles()

  const dispatch = useDispatch()
  const { categories } = useSelector(state => state.categories)

  const [newCategoryOrder, setNewCategoryOrder] = useState([])

  const getCategoryIds = () => (newCategoryOrder.length ? newCategoryOrder : parentCategory.children)

  const handleCategorySelect = categoryId => {
    parentHandleCategorySelect(categoryId, categoryIndex)
  }

  const handleAddCategory = () => {
    dispatch(categoryActions.create(parentCategory.id))
    dispatch(modalsActions.isOpen('adminCategory'))
  }

  const handleMove = (categoryId, direction) => {
    const categoryIds = [...getCategoryIds()]
    const categoryIdx = categoryIds.findIndex(id => id === categoryId)

    if (direction === 'up' && categoryIdx === 0) return
    if (direction === 'down' && categoryIdx === categoryIds.length - 1) return

    const newIndex = categoryIdx + (direction === 'up' ? -1 : 1)
    const switchId = categoryIds[newIndex]

    categoryIds[categoryIdx] = switchId
    categoryIds[newIndex] = categoryId

    // if the new order is the same as the old order then cancel the new order
    setNewCategoryOrder(isEqual(parentCategory.children, categoryIds) ? [] : categoryIds)
  }

  const handleSaveNewOrder = () => {
    dispatch(categoriesActions.updateOrder(parentCategory.id, newCategoryOrder))
  }

  const handleCancelNewOrder = () => {
    setNewCategoryOrder(newCategoryOrder)
  }

  const renderUpdateOrderButtons = () => (
    <>
      <div>
        <Button
          label="Cancel"
          onClick={handleCancelNewOrder}
          properties={['small']}
          className={css.columnTitleButtons}
        />
        <Button
          label="Save"
          type="primary"
          onClick={handleSaveNewOrder}
          properties={['small']}
          className={css.columnTitleButtons}
        />
      </div>
    </>
  )

  const renderCategoryRows = () => getCategoryIds().map((categoryId, index) => (
    <CategoryRow
      key={`category-${categoryId}`}
      index={index}
      category={categories[categoryId]}
      handleCategorySelect={handleCategorySelect}
      handleMove={handleMove}
    />
  ))

  return (
    <div className={css.column}>

      <div className={css.columnTitleWrapper}>
        <div className={css.columnTitle}>
          {parentCategory.name}
        </div>
        <div className={css.columnTitleOptions}>
          { !!newCategoryOrder.length && renderUpdateOrderButtons() }
          <FontAwesome
            name="faPlus"
            className={css.columnTitleAdd}
            handleClick={handleAddCategory}
          />
        </div>
      </div>

      {categories && renderCategoryRows()}
    </div>
  )
}

CategoryList.propTypes = {
  categoryIndex: PropTypes.number.isRequired,
  parentCategory: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleCategorySelect: PropTypes.func.isRequired,
}

export default CategoryList
