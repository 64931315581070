import axios from 'axios'
import moment from 'moment'
import constants from './notificationsConstants'

const loading = isLoading => ({
  type: constants.SET_LOADING,
  isLoading,
})

const load = () => async dispatch => {
  try {
    dispatch(loading(true))
    const res = await axios.get('notifications/new')
    dispatch({
      type: constants.SET_NOTIFICATIONS,
      notifications: res.data,
    })
  } catch (e) {
    console.error(e.message)
  }
  dispatch(loading(false))
}

const setAsViewed = () => async dispatch => {
  dispatch({
    type: constants.MARK_ALL_VIEWED,
  })
  axios.put('notifications/mark-all-viewed').catch(console.log)
}

export default {
  load,
  setAsViewed,
}