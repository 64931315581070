import React from 'react'
import PropTypes from 'prop-types'
import theme from 'css/front'
import injectSheet from 'react-jss'

const styles = {
  footer: {
    background: theme.colors.backgroundDarkest,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    padding: 10,
    ...theme.type.robotoLight,
    color: '#fff',
    height: 95,
    alignItems: 'center',
  },
}

const Footer = props => {
  const {
    classes: css,
  } = props

  return (
    <div className={css.footer}>
      &copy; AK LECTURES 2021
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(Footer)
