import constants from './uiFlagsConstants'

const initFlags = {
  isHeaderFloat: false,
}

export default (state = initFlags, action) => {
  switch (action.type) {
    case constants.SET_HEADER_FLOAT:
      return {
        ...state,
        isHeaderFloat: action.isHeaderFloat,
      }
    default:
      return state
  }
}
