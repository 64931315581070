export default [
  'load',
  'set_loading',
  'set_loading_posting',
  'clear',
  'create',
].reduce((all, con) => {
  all[con.toUpperCase()] = `CATEGORY_${con.toUpperCase()}`
  return all
}, {})
