import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import Loading from 'components/Loading'
import validator from 'validator'
import axios from 'axios'
import useNotify from 'hooks/useNotify'

const styles = {
  container: {
    maxWidth: 550,
    margin: '20px auto',
    [theme.max(590)]: {
      margin: 20,
    },
  },
  pageTitle: {
    ...theme.type.avenir,
    fontSize: 19,
    marginBottom: 20,
  },
  formContainer: {
    background: theme.colors.backgroundGray,
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    position: 'relative',
    marginTop: 30,
  },
  input: {
    marginTop: 10,
  },
}

const Contact = props => {
  const {
    classes: css,
  } = props

  const [state, setState] = useState({
    sent: false,
    name: '',
    email: '',
    message: '',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const notify = useNotify(dispatch)

  const setError = (field, message = null) => {
    setErrors(prevState => {
      const newErrors = { ...prevState }
      if (message) {
        newErrors[field] = message
      } else if (!message && newErrors[field]) {
        delete newErrors[field]
      }
      return newErrors
    })
  }

  const handleInputUpdate = (field, value) => {
    setError(field) // clear previous error
    setState(prevState => ({ ...prevState, [field]: value }))
  }

  const handleSubmitForm = async () => {
    const {
      name,
      email,
      message,
    } = state

    if (!validator.isLength(name, { min: 1, max: 100 })) {
      setError('name', 'Please fill in your name.')
      return
    }

    if (!validator.isEmail(email)) {
      setError('email', 'Please enter a valid email')
      return
    }

    if (!validator.isLength(message, { min: 1, max: 10000 })) {
      setError('message', 'Cannot send a blank message.')
      return
    }
    setLoading(true)

    try {
      await axios.post('/users/contact', {
        name,
        email,
        message,
      })
      handleInputUpdate('sent', true)
    } catch (e) {
      notify('Message was not send. Please refresh and try again.', 'error')
    }
    setLoading(false)
  }

  const renderThankYouMessage = useMemo(() => (
    <div className={css.thankYouMessage}>
      Thank you for your input.
    </div>
  ), [css.thankYouMessage])

  const renderForm = useMemo(() => (
    <>
      <Input
        error={errors.name}
        name="name"
        value={state.name}
        label="Name"
        onChange={handleInputUpdate}
        className={css.input}
        autoFocus
      />
      <Input
        error={errors.email}
        name="email"
        value={state.email}
        label="Email"
        onChange={handleInputUpdate}
        className={css.input}
      />
      <Input
        error={errors.message}
        name="message"
        value={state.message}
        label="Message / Question"
        onChange={handleInputUpdate}
        className={css.input}
        multiline
        rows={2}
      />
      <div className={css.buttonContainer}>
        {
          loading
            ? <Loading full />
            : (
              <Button
                className={css.button}
                onClick={handleSubmitForm}
                label="Send"
              />
            )
        }
      </div>
    </>
  ), [state, css, errors, handleSubmitForm])

  return (
    <div className={css.container}>
      <div className={css.pageTitle}>
        Contact us
      </div>
      <div className={css.formContainer}>
        {
          state.sent
            ? renderThankYouMessage
            : renderForm
        }
      </div>
    </div>
  )
}

Contact.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(Contact)
