export default lecture => {
  if (lecture.player === 'vimeo') {
    const thumbnail = lecture.meta.find(meta => meta.property === 'thumbnailId')
    const thumbnailId = thumbnail ? thumbnail.value : null
    return `https://i.vimeocdn.com/video/${thumbnailId}_200x150.jpg`
  }

  // youtube
  const youtubeThumbnailQuality = lecture.meta
    .find(meta => meta.property === 'youtubeThumbnailQuality')
  // return `https://i3.ytimg.com/vi/${lecture.youtubeId}/sddefault.jpg`
  const thumbnailQuality = youtubeThumbnailQuality ? youtubeThumbnailQuality.value : 'maxresdefault'
  return `https://i3.ytimg.com/vi/${lecture.youtubeId}/${thumbnailQuality}.jpg`
}