export default [
  'set_loading',
  'set_user',
  'logout',
  'login_failed',
  'set_init_loading',
  'register',
  'set_register_error',
  'set',
].reduce((all, con) => {
  all[con.toUpperCase()] = `USER_${con.toUpperCase()}`
  return all
}, {})
