import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import theme from 'css/front'
import LectureForm from './LectureForm'
import LectureReferences from './LectureReferences'
import LectureErrors from './LectureErrors'

const tabs = ['Lecture', 'References']

const useStyles = createUseStyles({
  container: {

  },
  tabContainer: {
    borderBottom: `1px solid ${theme.colors.borderGray}`,
    display: 'flex',
  },
  tab: {
    border: `1px solid ${theme.colors.borderGray}`,
    borderBottom: 'none',
    padding: '4px 10px',
    marginRight: 10,
    cursor: 'default',
  },
  selectedTab: {
    border: `1px solid ${theme.colors.headerGray}`,
    borderBottom: 'none',
  },
})

const LectureTabs = props => {
  const {
    lecture,
    lectureEdit,
    handleValueChange,
  } = props

  const css = useStyles()

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const handleUpdateReferences = referenceData => {
    handleValueChange('references', referenceData)
  }

  const handleUpdateErrors = errors => {
    handleValueChange('errors', errors)
  }

  const renderTab = tabName => (
    <div
      className={cn({
        [css.tab]: true,
        [css.selectedTab]: tabName === selectedTab,
      })}
      onClick={() => { setSelectedTab(tabName) }}
    >
      {tabName}
    </div>
  )

  const renderTabView = tabName => {
    if (tabName === 'Lecture') {
      return (
        <LectureForm
          lecture={lecture}
          lectureEdit={lectureEdit}
          handleValueChange={handleValueChange}
        />
      )
    }
    if (tabName === 'References') {
      return (
        <LectureReferences
          references={lectureEdit.references || lecture.references}
          handleUpdateReferences={handleUpdateReferences}
        />
      )
    }
    if (tabName === 'Corrections') {
      return (
        <LectureErrors
          errorText={lectureEdit.errors || lecture.errors}
          setErrorText={handleUpdateErrors}
        />
      )
    }

    return null
  }

  const renderLoading = () => (
    <div>
      Loading
    </div>
  )

  return (
    <div className={css.container}>
      <div className={css.tabContainer}>
        { tabs.map(renderTab) }
      </div>
      <div className={css.tabViewer}>
        { lecture.loading ? renderLoading() : renderTabView(selectedTab) }
      </div>
    </div>
  )
}

LectureTabs.propTypes = {
  lecture: PropTypes.object.isRequired,
  lectureEdit: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
}

export default LectureTabs
