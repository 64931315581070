import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { CardElement, injectStripe } from 'react-stripe-elements'
import Input from 'components/elements/Input'
import Button from 'components/elements/Button'
import theme from 'css/front'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import alertActions from 'redux/alert/alertActions'
import ModalsActions from 'redux/modals/modalsActions'
import cn from 'classnames'
import Loading from 'components/Loading'
import ReactGA from 'react-ga'
import userActions from '../../redux/user/userActions'

const { plans } = require('constants/index')

const styles = {
  row: {
    ...theme.components.row,
    marginBottom: 30,
    position: 'relative',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  mobileColumn: {
    [theme.mobileOnly()]: {
      flexDirection: 'column',
    },
  },
  inputSpace: {
    width: 30,
    [theme.mobileOnly()]: {
      height: 10,
    },
  },
  stripeForm: {
    marginBottom: 30,
    background: '#fff',
    padding: 10,
  },
  planTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  plan: {
    textTransform: 'capitalize',
    color: theme.colors.nuBlu,
  },
  price: {
    marginLeft: 'auto',
  },
  smallText: {
    fontSize: 12,
    color: theme.colors.textGray,
    marginTop: 5,
  },
  smallerText: {
    fontSize: 13,
    lineHeight: '25px',
  },
}

const ccErrors = {
  incomplete_number: 'Please complete the credit card fields.',
}

const PurchaseForm = props => {
  const {
    classes: css,
    history,
    match: {
      params: {
        plan: planName,
      },
    },
  } = props

  const dispatch = useDispatch()

  const plan = plans.find(planInfo => planInfo.name === planName)

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
  })

  const [loading, isLoading] = useState(false)

  const handlePurchase = async () => {
    isLoading(true)
    const {
      firstName,
      lastName,
    } = form
    const tokenRequest = await props.stripe.createToken({
      firstName,
      lastName,
    })

    if (!firstName || !lastName) {
      dispatch(alertActions.create('First and last name cannot be blank', 'error'))
      isLoading(false)
      return
    }

    if (tokenRequest.error) {
      const errorMessage = ccErrors[tokenRequest.error.code] || tokenRequest.error.message
      dispatch(alertActions.create(errorMessage, 'error'))
      isLoading(false)
      return
    }

    try {
      await axios.post('/users/purchase', {
        token: tokenRequest.token,
        firstName,
        lastName,
        plan,
      })
      dispatch(userActions.loginToken())
      isLoading(false)
      try {
        ReactGA.event({
          category: 'Subscription',
          action: plan.name,
          label: 'New subscription',
          value: plan.price,
        })
      } catch (e) {
        console.error(e)
      }
      history.push(`/thank-you-${plan.name}`)
      return
    } catch (e) {
      if (e.response.data.message && e.response.data.message.search('logged in to') > -1) {
        dispatch(ModalsActions.isOpen('login'))
      }
      dispatch(alertActions.create(e.response.data.message, 'error'))
    }
    isLoading(false)
  }

  const handleFormUpdate = (name, val) => {
    setForm({
      ...form,
      [name]: val,
    })
  }

  return (
    <div>
      <div className={cn(css.row, css.mobileColumn)}>
        <Input
          label="First Name"
          name="firstName"
          onChange={handleFormUpdate}
          value={form.firstName}
          fullWidth
          required
        />
        <div className={css.inputSpace} />
        <Input
          label="Last Name"
          name="lastName"
          onChange={handleFormUpdate}
          value={form.lastName}
          fullWidth
          required
        />
      </div>
      <div className={css.stripeForm}>
        <CardElement />
      </div>
      <div className={css.planTitle}>
        Plan
      </div>
      <div className={css.row}>
        <div className={css.plan}>
          {plan.label}
        </div>
        <div className={css.price}>
          $
          {plan.price}
          &nbsp;due today
        </div>
      </div>
      <div className={css.row}>
        {
          loading
            ? <Loading fill />
            : (
              <Button
                label="Checkout"
                onClick={handlePurchase}
              />
            )
        }
      </div>
      <div className={css.smallerText}>
        {
          `
          By clicking the purchase button you authorize AK Lectures to charge your credit card $${plan.price}.
          After today, you will be charged every ${plan.legalPeriod}.
          You may cancel your subscription at any time.
          `
        }
        
      </div>
    </div>
  )
}

PurchaseForm.propTypes = {
  classes: propTypes.object.isRequired,
  stripe: propTypes.object.isRequired,
  match: propTypes.shape({
    params: propTypes.object.isRequired,
  }).isRequired,
  history: propTypes.object.isRequired,
}

export default injectStripe(withRouter(injectSheet(styles)(PurchaseForm)))
