import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import Input from 'components/elements/SimpleInput'

const useStyles = createUseStyles({
  references: {
    marginTop: 10,
  },
  reference: {
    display: 'flex',
    marginBottom: 5,
  },
})

const LectureErrors = props => {
  const {
    errorText,
    setErrorText,
  } = props

  return (
    <div>
      <Input
        label="Corrections"
        name="error"
        onChange={(name, val) => setErrorText(val)}
        value={errorText}
        multiline
      />
    </div>
  )
}

LectureErrors.propTypes = {
  errorText: PropTypes.string.isRequired,
  setErrorText: PropTypes.func.isRequired,
}

export default LectureErrors
