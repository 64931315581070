import React, { useState } from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import theme from 'css/front'
import Button from 'components/elements/Button'
import commentsActions from 'redux/comments/commentsActions'
import FontAwesome from 'components/FontAwesome'
import { useSelector, useDispatch } from 'react-redux'
import useNotify from 'hooks/useNotify'

const styles = {
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    [theme.mobileOnly()]: {
      flexDirection: 'column',
    },
  },
  input: {
    resize: 'none',
    padding: 10,
    outline: 'none',
    fontSize: 14,
    width: 'calc(100% - 160px)',
    background: '#F1F1EF',
    border: 'none',
    height: 90,
    [theme.mobileOnly()]: {
      width: '100%',
    },
  },
  inputSubmit: {
    width: 160,
    flex: 1,
    background: theme.colors.nuBlu,
    color: '#fff',
    outline: 'none',
    fontSize: 16,
    fontWeight: 800,
    letterSpacing: 2,
    textTransform: 'uppercase',
    border: 'none',
    [theme.mobileOnly()]: {
      padding: 10,
      width: '100%',
      marginTop: 10,
      fontSize: 14,
    },
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#ffffff',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.7,
  },
  spinner: {
    display: 'inline-block',
    position: 'relative',
    animationDuration: '5000ms',
    animationName: 'spin',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    color: theme.colors.nuBlu,
    fontSize: 35,
  },
}


const CommentInput = props => {

  const dispatch = useDispatch()
  const comments = useSelector(state => state.comments)
  const notify = useNotify(dispatch)

  const {
    classes: css,
    lectureSlug,
    parentId,
    onHideReply,
  } = props

  const [comment, setComment] = useState('')

  const postComment = () => {
    if (comment.replace(' ', '') === '') {
      notify('Comment cannot be blank', 'error')
      return
    }

    dispatch(commentsActions.postLecture(lectureSlug, comment, parentId))
    setComment('')
    onHideReply()
  }

  return (
    <div className={css.inputContainer}>
      {
        comments.loadingPosting
        && (
          <div className={css.loading}>
            <FontAwesome
              name="faSpinner"
              className={css.spinner}
            />
          </div>
        )
      }
      <textarea
        className={css.input}
        placeholder="Add a comment or question"
        onChange={e => setComment(e.target.value)}
        value={comment}
      />
      <button
        className={css.inputSubmit}
        onClick={postComment}
        type="button"
      >
        Post
      </button>
    </div>
  )
}

CommentInput.propTypes = {
  lectureSlug: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
  parentId: propTypes.number,
  onHideReply: propTypes.func,
}

CommentInput.defaultProps = {
  parentId: null,
  onHideReply: () => {},
}

export default injectSheet(styles)(CommentInput)
