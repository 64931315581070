import React, { PureComponent } from 'react'
import { Route } from 'react-router-dom'
import AdminCategories from 'containers/admin/Categories'
import AdminUsers from 'containers/admin/Users'
import AdminUser from 'containers/admin/User'
import AdminTrials from 'containers/admin/Trials'
import AdminActions from 'containers/admin/Actions'
import AdminPayments from 'containers/admin/Payments'
import AdminComments from 'containers/admin/Comments'
import AdminCorrections from 'containers/admin/Corrections'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

const mapStateToProps = ({
  user,
}) => ({
  user,
})

class AdminRoute extends PureComponent {
  static propTypes = {
    user: propTypes.object.isRequired,
  }

  render() {
    const {
      user,
    } = this.props

    if (user.role !== 'admin') return null

    return (
      <>
        <Route exact path="/admin/lectures" component={AdminCategories} />
        <Route exact path="/admin/comments" component={AdminComments} />
        <Route exact path="/admin/actions" component={AdminActions} />
        <Route exact path="/admin/users" component={AdminUsers} />
        <Route exact path="/admin/user/:userId" component={AdminUser} />
        <Route exact path="/admin/trials" component={AdminTrials} />
        <Route exact path="/admin/payments" component={AdminPayments} />
        <Route exact path="/admin/corrections" component={AdminCorrections} />
      </>
    )
  }
}

export default connect(mapStateToProps)(AdminRoute)
