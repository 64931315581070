
import constants from './uiFlagsConstants'

const setHeaderFloat = isHeaderFloat => ({
  type: constants.SET_HEADER_FLOAT,
  isHeaderFloat,
})


export default {
  setHeaderFloat,
}
