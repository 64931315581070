import moment from 'moment'
import constants from './notificationsConstants'

const initState = {
  loading: false,
  all: [],
  hasNew: false,
  viewedAllAt: moment(),
}

export default (state = initState, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }
    case constants.SET_NOTIFICATIONS:
      return {
        ...state,
        all: action.notifications,
        hasNew: action.notifications.some(notification => notification.viewedAt === null)
          && state.viewedAllAt.isBefore(moment()),
      }
    case constants.MARK_ALL_VIEWED:
      return {
        ...state,
        viewedAllAt: moment().add(5, 'minutes'),
        hasNew: false,
      }
    default:
      return state
  }
}
